// import { generateImagePath } from "api/apiClient";
import React from 'react';
import { saveAs } from 'file-saver'

const ImageModal = (props) => {
    
    // const imagePath = generateImagePath(props.img);

    const downloadImage = () => {
        saveAs(props.img)
    }
    let modelStyle={
        display:'block',
        backgroundColor:'rgba(0,0,0,0.8)'
    }
    return (
        <div>
            
            <div className="modal show fade" style={modelStyle}>
            <div className="model-action">
                    <button type="button" className="download-img" onClick={downloadImage}><i className="fa fa-download" aria-hidden="true"></i></button>
                    <button type="button" className="model-close" onClick={props.hide}><i className="fa fa-times" aria-hidden="true"></i></button>
                </div>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="modal-body align-self-center">
                        <center>
                                <img crossOrigin="anonymous" src={props.img} alt={props.img} style={{ maxWidth: '50rem' }} />
                            </center>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ImageModal
