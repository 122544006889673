import { axiosClient, axiosClientFormData } from "../api/apiClient"
import {
    activeCustomerRoute,
    activeStaffByIdRoute,
    addSubCategoryRoute,
    addToCartRoute,
    assignRightsToRoleRoute,
    authLoginRoute,
    countStaffRoute,
    countUserRightRoute,
    countUserRoleRoute,
    createCategoryRoute,
    createCustomerRoute,
    createProductRoute,
    createStaffRoute,
    createUserRightRoute,
    createUserRoleRoute,
    customerCountRoute,
    deactiveCustomerRoute,
    deactiveStaffByIdRoute,
    deleteSliderImageRoute,
    deleteUserRightsRoute,
    deleteUserRolesRoute,
    getAllStaffRoute,
    getAllUserRightsRoute,
    getAllUserRolesRoute,
    getCartByIdRoute,
    getCategoryByIdRoute,
    getCategoryCountRoute,
    getCategoryListRoute,
    getCustomerByIdRoute,
    getCustomerListRoute,
    getOrderByIdRoute,
    getOrderListRoute,
    getProductListRoute,
    getProductRoute,
    getSliderImageRoute,
    getStaffByIdRoute,
    getUserRightRoute,
    getUserRoleRoute,
    getUserRoleToRightRoute,
    orderCountRoute,
    productCountRoute,
    removeAadharImageRoute,
    removeCategoryByIdRoute,
    removeCategoryImgRoute,
    removePanImageRoute,
    removeProductImageRoute,
    removeProductRoute,
    removeStaffImageRoute,
    removeStaffRoute,
    searchCartRoute,
    searchCategoryRoute,
    searchCustomerRoute,
    searchProductRoute,
    searchStaffsRoute,
    searchUserRightRoute,
    searchUserRoleRoute,
    sliderCountRoute,
    sliderImageStatusRoute,
    todayOrderCountRoute,
    updateCategoryRoute,
    updateCustomerByIdRoute,
    updateCustomerStatusByIdRoute,
    updateProductIsHomeRoute,
    updateProductRoute,
    updateStaffRoute,
    updateUserRightRoute,
    updateUserRoleRoute,
    uploadAadharImageRoute,
    uploadCategoryImgRoute,
    uploadPanImageRoute,
    uploadProductImageRoute,
    uploadSliderImageRoute,
    uploadStaffImageRoute,
    changePasswordRoute,
    removeUserImageRoute,
    uploadUserImageRoute,
    getUserByIdRoute,
    getTopSellingProductsRoute,
    getCustomerOldOrdersRoute,
    updateSubCategoryRoute,
    uploadSubCategoryImageRoute,
    removeSubCategoryImageRoute,
    addSubSubCategoryRoute,
    updateSubSubCategoryRoute,
    removeSubSubCategoryRoute,
    uploadSubSubCategoryImageRoute,
    removeSubSubCategoryImageRoute,
    getSubCategoryByIdRoute,
    removeSubCategoryRoute,
    getSubSubCategoryByIdRoute,
    removeCustomerByIdRoute,
    updateOrderStatusByIdRoute,
    updateStaffRoleIdRoute,
    updateUserRoleIdRoute,
    uploadAadharFrontImageRoute,
    removeAadharFrontImageRoute,
    uploadAadharBackImageRoute,
    removeAadharBackImageRoute,
    searchOrderRoute,
    addProductSubCategoryRoute,
    addProductSubSubCategoryRoute,
    removeProductSubCategoryRoute,
    removeProductSubSubCategoryRoute,
    removeOrderShippingReciptRoute,
    uploadOrderShippingReciptRoute
} from "./backendRoute"


// Authentication
export const authLoginAPI = async (username, password, userGroup) => {
    try {
        const data = {
            username: username,
            password: password,
            userGroup: userGroup
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(authLoginRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//------------Change Password--------------
export const changePasswordAPI = async (userId, old_password, new_password) => {
    try {
        const data = {
            userId: userId,
            old_password: old_password,
            new_password: new_password
        }
        const response = await axiosClient.post(changePasswordRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//------------Get user by ID--------------
export const getUserByIdAPI = async () => {
    try {
        const data = {
            userId: localStorage.getItem("loginUserId"),
        }
        const response = await axiosClient.post(getUserByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//------------ Upload user Image --------------
export const uploadUserImageAPI = async (userId, file_name) => {
    try {
        const formData = new FormData()
        formData.append("userId", userId);
        formData.append("file_name", file_name);
        // Make the axiosClient request with headers
        const response = await axiosClientFormData.post(uploadUserImageRoute, formData)
        return response?.data

    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//------------ Remove user image --------------
export const removeUserImageAPI = async (userId, imagePath) => {
    try {
        const data = {
            userId: userId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeUserImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}



// DASHBOARD MODULE

// ------------------Upload Slider Image--------------------------
export const uploadSliderImageAPI = async (image) => {
    try {
        const formData = new FormData()
        formData.append("image", image)
        // Make the axiosClient request with headers
        const response = await axiosClientFormData.post(uploadSliderImageRoute, formData)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Delete Slider Image--------------------------
export const deleteSliderImageAPI = async (imageId, imagePath) => {
    try {
        const data = {
            imageId: imageId,
            imagePath: imagePath
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(deleteSliderImageRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Slider Image--------------------------
export const getSliderImageAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getSliderImageRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Slider Image--------------------------
export const getSliderCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(sliderCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Slider Image--------------------------
export const updateSliderImageStatusAPI = async (imageId, status) => {
    try {
        const data = {
            imageId: imageId,
            status: status
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(sliderImageStatusRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// CUSTOMER MODULE

// ------------------Create Customer--------------------------
export const createCustomerAPI = async (
    fullName,
    email,
    gst,
    factoryAddress,
    bankACNo,
    adharNo,
    panNo,
    bankName,
    ifscCode,
    contactPersonName,
    contactPersonNumber
) => {
    try {
        const data = {
            fullName: fullName,
            email: email,
            gst: gst,
            factoryAddress: factoryAddress,
            bankACNo: bankACNo,
            adharNo: adharNo,
            panNo: panNo,
            bankName: bankName,
            ifscCode: ifscCode,
            contactPersonName: contactPersonName,
            contactPersonNumber: contactPersonNumber
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(createCustomerRoute, data)
        //     const response = axios.post('http://localhost:3001/Development/Customer/createCustomer', data, {
        //       headers: {
        //           'Content-Type': 'multipart/form-data'
        //       }
        //   });
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Customer List--------------------------
export const getCustomerListAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getCustomerListRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Update Customer--------------------------
export const updateCustomerByIdAPI = async (
    customerId,
    fullName,
    email,
    gst,
    factoryAddress,
    bankACNo,
    adharNo,
    panNo,
    bankName,
    ifscCode,
    contactPersonName,
    contactPersonNumber
) => {
    try {
        const data = {
            customerId: customerId,
            fullName: fullName,
            email: email,
            gst: gst,
            factoryAddress: factoryAddress,
            bankACNo: bankACNo,
            adharNo: adharNo,
            panNo: panNo,
            bankName: bankName,
            ifscCode: ifscCode,
            contactPersonName: contactPersonName,
            contactPersonNumber: contactPersonNumber
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(updateCustomerByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Customer BY Id--------------------------
export const getCustomerByIdAPI = async customerId => {
    try {
        const data = {
            customerId: customerId,
        }
        const response = await axiosClient.post(getCustomerByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Search Customer--------------------------
export const searchCustomerAPI = async (searchString) => {
    try {
        const data = {
            searchString: searchString
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(searchCustomerRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}
// ------------------Count Customer--------------------------
export const customerCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(customerCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload Customer AadharCard Front--------------------------
export const uploadAadharFrontImageAPI = async (customerId, adharPhoto) => {
    try {
        const formData = new FormData()
        formData.append("customerId", customerId)
        formData.append("adharFrontPhoto", adharPhoto)
        const response = await axiosClientFormData.post(uploadAadharFrontImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload Customer AadharCard Back--------------------------
export const uploadAadharBackImageAPI = async (customerId, adharPhoto) => {
    try {
        const formData = new FormData()
        formData.append("customerId", customerId)
        formData.append("adharBackPhoto", adharPhoto)
        const response = await axiosClientFormData.post(uploadAadharBackImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}
// ------------------Remove Customer AadharCard Front--------------------------
export const removeAadharFrontImageAPI = async (customerId, imagePath) => {
    try {
        const data = {
            customerId: customerId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeAadharFrontImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Customer AadharCard Back--------------------------
export const removeAadharBackImageAPI = async (customerId, imagePath) => {
    try {
        const data = {
            customerId: customerId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeAadharBackImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload Customer PanCard--------------------------
export const uploadPanImageAPI = async (customerId, panPhoto) => {
    try {
        const formData = new FormData()
        formData.append("customerId", customerId)
        formData.append("panPhoto", panPhoto)
        const response = await axiosClientFormData.post(uploadPanImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Customer PanCard--------------------------
export const removePanImageAPI = async (customerId, imagePath) => {
    try {
        const data = {
            customerId: customerId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removePanImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Deactive Customer--------------------------
export const deactiveCustomerrAPI = async (customerId) => {
    try {
        const data = {
            customerId: customerId
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(deactiveCustomerRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Active Customer--------------------------
export const activeCustomerrAPI = async (customerId) => {
    try {
        const data = {
            customerId: customerId
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(activeCustomerRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Update Customer Status--------------------------
export const updateCustomerStatusByIdAPI = async (customerId, status) => {
    try {
        const data = {
            customerId: customerId,
            status: status
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(updateCustomerStatusByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Customer--------------------------
export const removeCustomerByIdAPI = async (customerId) => {
    try {
        const data = {
            customerId: customerId,
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(removeCustomerByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// PRODUCT MODULE

// ------------------Get Product List--------------------------
export const getProductListAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getProductListRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Create Product---------------------------
export const createProductAPI = async (
    productName,
    productCode,
    productCategory,
    productCategoryId,
    productSubCategoryId,
    productSubSubCategoryId,
    price,
    qty,
    description,
    minOrder,
    isHome,
    relatedProducts,
    subCategory
) => {
    try {
        const data = {
            productName: productName,
            productCode: productCode,
            productCategory: productCategory,
            productCategoryId: productCategoryId,
            productSubCategoryId: productSubCategoryId,
            productSubSubCategoryId: productSubSubCategoryId,
            price: price,
            qty: qty,
            description: description,
            minOrder: minOrder,
            isHome: isHome,
            relatedProducts: relatedProducts,
            subCategory: subCategory
        }
        // console.log("Service = ", data);
        const response = await axiosClient.post(createProductRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

export const addProductSubCategoryAPI = async (
    categoryId,
    subCategoryId,
    productId
) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            productId: productId
        }
        // console.log("Service = ", data);
        const response = await axiosClient.post(addProductSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

export const addProductSubSubCategoryAPI = async (
    categoryId,
    subCategoryId,
    subSubCategoryId,
    productId
) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId,
            productId: productId
        }
        // console.log("Service = ", data);
        const response = await axiosClient.post(addProductSubSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}



//-------------------Update Product---------------------------
export const updateProductAPI = async (
    productId,
    productName,
    productCode,
    productCategory,
    productCategoryId,
    productSubCategoryId,
    productSubSubCategoryId,
    price,
    qty,
    description,
    minOrder,
    isHome,
    relatedProducts,
    subCategory,
) => {
    try {
        const data = {
            productId: productId,
            productName: productName,
            productCode: productCode,
            productCategory: productCategory,
            productCategoryId: productCategoryId,
            productSubCategoryId: productSubCategoryId,
            productSubSubCategoryId: productSubSubCategoryId,
            price: price,
            qty: qty,
            description: description,
            minOrder: minOrder,
            isHome: isHome,
            relatedProducts: relatedProducts,
            subCategory: subCategory,
        }

        const response = await axiosClient.post(updateProductRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Search Orders---------------------------
export const searchOrderAPI = async (searchString) => {
    try {
        const data = {
            searchString: searchString
        }
        const response = await axiosClient.post(searchOrderRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}
//-------------------Get by id Product---------------------------
export const getProductByIdAPI = async (productId) => {
    try {
        const data = { productId: productId }
        const response = await axiosClient.post(getProductRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------remove by id Product---------------------------
export const removeProductByIdAPI = async (productId) => {
    try {
        const data = { productId: productId, }
        const response = await axiosClient.post(removeProductRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

export const removeProductSubCategoryAPI = async (categoryId, subCategoryId, productId) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            productId: productId,
        }
        const response = await axiosClient.post(removeProductSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

export const removeProductSubSubCategoryAPI = async (categoryId, subCategoryId, subSubCategoryId, productId) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId,
            productId: productId,
        }
        const response = await axiosClient.post(removeProductSubSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Search Product---------------------------
export const searchProductAPI = async (searchString) => {
    try {
        const data = { searchString: searchString, }
        const response = await axiosClient.post(searchProductRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Count Product--------------------------
export const productCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(productCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Update Is Home Product---------------------------
export const updateProductIsHomeAPI = async (productId, isHome) => {
    try {
        const data = { productId: productId, isHome: isHome }
        const response = await axiosClient.post(updateProductIsHomeRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Upload Product Image---------------------------
export const uploadProductImageAPI = async (productId, productImages) => {
    try {
        const formData = new FormData()
        formData.append("productId", productId)
        formData.append("productImages", productImages)
        // const data = { productId: productId, productImages: productImages }
        const response = await axiosClientFormData.post(uploadProductImageRoute, formData)
        console.log(formData);
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Product Image--------------------------
export const removeProductImageAPI = async (productId, imagePath) => {
    try {
        const data = {
            productId: productId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeProductImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// CART MODULE

// ------------------ADD TO CART ITEMS--------------------------
export const addToCartAPI = async (customerId, products) => {
    try {
        const data = {
            customerId: customerId,
            products: products
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(addToCartRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------GET CART ITEMS BY ID--------------------------
export const getCartByIdAPI = async (customerId) => {
    try {
        const data = {
            customerId: customerId
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getCartByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------SERCH CART--------------------------
export const searchCartAPI = async (customerId, searchString) => {
    try {
        const data = {
            customerId: customerId,
            searchString: searchString
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(searchCartRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// Order Module

// ------------------Count Order--------------------------
export const orderCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(orderCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Count Order Today--------------------------
export const todayOrderCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(todayOrderCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------Get Order List-----------------------
export const getOrderListAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getOrderListRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Order BY Id--------------------------
export const getOrderByIdAPI = async orderId => {
    try {
        const data = {
            orderId: orderId,
        }
        const response = await axiosClient.post(getOrderByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//--------------------Get Top Selling Products-----------------------
export const getCustomerOldOrdersAPI = async (customerId) => {
    try {
        const data = { customerId: customerId }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getCustomerOldOrdersRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------Get Top Selling Products-----------------------
export const getTopSellingProductsAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getTopSellingProductsRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------Update order by id-----------------------
export const updateOrderStatusByIdAPI = async (orderId, orderStatus, orderNote) => {
    try {
        const data = { orderId: orderId, orderStatus: orderStatus, orderNote: orderNote }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(updateOrderStatusByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------Delete order by id-----------------------
export const deleteOrderStatusByIdAPI = async (orderId) => {
    try {
        const data = { orderId: orderId }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(updateOrderStatusByIdRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------uploadOrderShippingRecipt order by id-----------------------
export const uploadOrderShippingReciptAPI = async (orderId, shippingRecipt) => {
    try {
        const formData = new FormData()
        formData.append("orderId", orderId)
        formData.append("shippingRecipt", shippingRecipt)
        // Make the axiosClient request with headers
        const response = await axiosClientFormData.post(uploadOrderShippingReciptRoute, formData)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//--------------------removeOrderShippingRecipt order by id-----------------------
export const removeOrderShippingReciptAPI = async (orderId, imagePath) => {
    try {
        const data = {
            orderId: orderId,
            imagePath: imagePath
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(removeOrderShippingReciptRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// Category

//------------------Create category---------------
export const createCategoryAPI = async (categoryName) => {
    try {
        const data = {
            categoryName: categoryName
        }

        const response = await axiosClient.post(createCategoryRoute, data)
        return response?.data
    } catch (error) {

        console.error("Error:", error.message)
    }
    return null
}

// ------------------Get Category List--------------------------
export const getCategoryListAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getCategoryListRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload Category Image--------------------------
export const uploadCategoryImageAPI = async (categoryId, categoryImage) => {
    try {
        const formData = new FormData()
        formData.append("categoryId", categoryId)
        formData.append("categoryImage", categoryImage)
        const response = await axiosClientFormData.post(uploadCategoryImgRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Category Image--------------------------
export const removeCategoryImageAPI = async (categoryId, imagePath) => {
    try {
        const data = {
            categoryId: categoryId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeCategoryImgRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove Category By Id--------------------------
export const removeCategoryByIdAPI = async (categoryId) => {
    try {
        const data = {
            categoryId: categoryId,
        }
        const response = await axiosClient.post(removeCategoryByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Get by id Category---------------------------
export const getCategoryByIdAPI = async (categoryId) => {
    try {
        const data = { categoryId: categoryId }
        const response = await axiosClient.post(getCategoryByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Update Category---------------------------
export const updateCategoryAPI = async (categoryId, categoryName) => {
    try {
        const data = {
            categoryId: categoryId,
            categoryName: categoryName
        }
        const response = await axiosClient.post(updateCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


// ------------------Count Category--------------------------
export const getCategoryCountAPI = async () => {
    try {
        const data = {}
        // Make the axiosClient request with headers
        const response = await axiosClient.post(getCategoryCountRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Search Category---------------------------
export const searchCategoryAPI = async (itemsPerPage, pageNo, searchString) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo,
            searchString: searchString
        }
        const response = await axiosClient.post(searchCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// SUB CATEGORY

//-------------------Update Sub Category---------------------------
export const updateSubCategoryAPI = async (categoryId, subCategogyId, subCategoryName) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategogyId: subCategogyId,
            subCategoryName: subCategoryName
        }
        const response = await axiosClient.post(updateSubCategoryRoute, data)
        console.log("res ", response);
        console.log("data ", data);
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload sub Category Image--------------------------
export const uploadSubCategoryImageAPI = async (categoryId, subCategoryId, subCategoryImage) => {
    try {
        const formData = new FormData()
        formData.append("categoryId", categoryId)
        formData.append("subCategoryId", subCategoryId)
        formData.append("subCategoryImage", subCategoryImage)
        const response = await axiosClientFormData.post(uploadSubCategoryImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove sub Category Image--------------------------
export const removeSubCategoryImageAPI = async (categoryId, subCategoryId, imagePath) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeSubCategoryImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


//-------------------Add Sub Category---------------------------
export const addSubCategoryAPI = async (categoryId, subCategory) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategory: subCategory,
        }
        const response = await axiosClient.post(addSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Remove Sub Category---------------------------
export const removeSubCategoryAPI = async (categoryId, subCategoryId) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        }
        const response = await axiosClient.post(removeSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Get by id Category---------------------------
export const getSubCategoryByIdAPI = async (categoryId, subCategoryId) => {
    try {
        const data = { categoryId: categoryId, subCategoryId: subCategoryId }
        const response = await axiosClient.post(getSubCategoryByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// SUB SUB CATEGORY

//-------------------Add Sub Sub Category---------------------------
export const addSubSubCategoryAPI = async (categoryId, subCategoryId, subSubCategory, status) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategory: subSubCategory,
            status: status
        }
        const response = await axiosClient.post(addSubSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Update Sub Sub Category---------------------------
export const updateSubSubCategoryAPI = async (categoryId, subCategoryId, subSubCategoryId, subSubCategory, status) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId,
            subCategoryName: subSubCategory,
            status: status
        }
        const response = await axiosClient.post(updateSubSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Remove Sub Category---------------------------
export const removeSubSubCategoryAPI = async (categoryId, subCategoryId, subSubCategoryId) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId
        }
        const response = await axiosClient.post(removeSubSubCategoryRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Upload sub Category Image--------------------------
export const uploadSubSubCategoryImageAPI = async (categoryId, subCategoryId, subSubCategoryId, subCategoryImage) => {
    try {
        const formData = new FormData()
        formData.append("categoryId", categoryId)
        formData.append("subCategoryId", subCategoryId)
        formData.append("subSubCategoryId", subSubCategoryId)
        formData.append("subCategoryImage", subCategoryImage)
        const response = await axiosClientFormData.post(uploadSubSubCategoryImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Remove sub Category Image--------------------------
export const removeSubSubCategoryImageAPI = async (categoryId, subCategoryId, subSubCategoryId, imagePath) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeSubSubCategoryImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//-------------------Get by id inner Category---------------------------
export const getSubSubCategoryByIdAPI = async (categoryId, subCategoryId, subSubCategoryId) => {
    try {
        const data = {
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            subSubCategoryId: subSubCategoryId
        }
        const response = await axiosClient.post(getSubSubCategoryByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


// ROLE

//----------Create Role----------------
export const createUserRoleAPI = async (name, status) => {
    try {
        const data = {
            name: name,
            status: status
        }
        const response = await axiosClient.post(createUserRoleRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//---------- update User Role----------------
export const updateUserRoleAPI = async (roleId, name, status) => {
    try {
        const data = {
            roleId: roleId,
            name: name,
            status: status
        }
        const response = await axiosClient.post(updateUserRoleRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Delete Role----------------
export const deleteUserRolesAPI = async (roleId) => {
    try {
        const data = {
            roleId: roleId
        }
        const response = await axiosClient.post(deleteUserRolesRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get user role Role----------------
export const getUserRoleAPI = async (roleId) => {
    try {
        const data = {
            roleId: roleId
        }
        const response = await axiosClient.post(getUserRoleRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Count user Role----------------
export const countUserRoleAPI = async () => {
    try {
        const data = {

        }
        const response = await axiosClient.post(countUserRoleRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get all user Role----------------
export const getAllUserRolesAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        const response = await axiosClient.post(getAllUserRolesRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------search Role----------------
export const searchUserRoleAPI = async (searchString) => {
    try {
        const data = {
            itemsPerPage: 1000,
            pageNo: 1,
            searchString: searchString
        }
        const response = await axiosClient.post(searchUserRoleRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// RIGHTS

//----------Create Rights ----------------
export const createUserRightAPI = async (name, displayName, status) => {
    try {
        const data = {
            name: name,
            displayName: displayName,
            status: status
        }
        const response = await axiosClient.post(createUserRightRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//---------- update User Rights ----------------
export const updateUserRightAPI = async (roleId, name, displayName, status) => {
    try {
        const data = {
            roleId: roleId,
            name: name,
            displayName: displayName,
            status: status
        }
        const response = await axiosClient.post(updateUserRightRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Delete Rights ----------------
export const deleteUserRightsAPI = async (rightId) => {
    try {
        const data = {
            rightId: rightId
        }
        const response = await axiosClient.post(deleteUserRightsRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get user  Rights ----------------
export const getUserRightAPI = async (rightId) => {
    try {
        const data = {
            rightId: rightId
        }
        const response = await axiosClient.post(getUserRightRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Count user Rights ----------------
export const countUserRightAPI = async () => {
    try {
        const data = {

        }
        const response = await axiosClient.post(countUserRightRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get all user Rights ----------------
export const getAllUserRightsAPI = async () => {
    try {
        const data = {
            itemsPerPage: 100,
            pageNo: 1
        }
        const response = await axiosClient.post(getAllUserRightsRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------search Rights ----------------
export const searchUserRightAPI = async (itemsPerPage, pageNo, searchString) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo,
            searchString: searchString
        }
        const response = await axiosClient.post(searchUserRightRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


// ------------------Display Role To Rights--------------------------
export const displayRoleToRightsAPI = async roleId => {
    try {
        const data = {
            roleId: roleId,
        }

        // Make the axiosClient request with headers
        const response = await axiosClient.post(getUserRoleToRightRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}
//----------Update User RoleId ----------------
export const updateUserRoleIdAPI = async (userId, userRoleId) => {
    try {
        const data = {
            userId: userId,
            userRoleId: userRoleId
        }

        const response = await axiosClient.post(updateUserRoleIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

// ------------------Update Role To Rights--------------------------
export const updateRoleToRightsAPI = async (roleId, roleToRight) => {
    try {
        const data = {
            roleId: roleId,
            rights: roleToRight,
        }
        // Make the axiosClient request with headers
        const response = await axiosClient.post(assignRightsToRoleRoute, data)
        return response?.data
    } catch (error) {
        // Handle error
        console.error("Error:", error.message)
    }
    return null
}

//Staff

//----------Create Staff ----------------
export const createStaffAPI = async (firstName, lastName, email, mobile, gender, address, username, password, userRoleId) => {
    try {
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: mobile,
            gender: gender,
            address: address,
            username: username,
            password: password,
            userGroup: "admin_staff",
            userRoleId: userRoleId
        }
        const response = await axiosClient.post(createStaffRoute, data);
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Update Staff ----------------
export const updateStaffAPI = async (staffId, firstName, lastName, email, mobile, image, gender, address, username, password, userRoleId) => {
    try {
        const data = {
            staffId: staffId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: mobile,
            image: image,
            gender: gender,
            address: address,
            username: username,
            password: password,
            userRoleId: userRoleId
        }
        console.log(data);
        const response = await axiosClient.post(updateStaffRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


//----------Update Staff ----------------
export const updateStaffRoleIdAPI = async (staffId, userRoleId) => {
    try {
        const data = {
            staffId: staffId,
            userRoleId: userRoleId
        }

        const response = await axiosClient.post(updateStaffRoleIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}
//----------Count staff ----------------
export const countStaffAPI = async () => {
    try {
        const data = {}
        const response = await axiosClient.post(countStaffRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get all staff ----------------
export const getAllStaffAPI = async (itemsPerPage, pageNo) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo
        }
        const response = await axiosClient.post(getAllStaffRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}


//----------Search Staff ----------------
export const searchStaffsAPI = async (itemsPerPage, pageNo, searchString) => {
    try {
        const data = {
            itemsPerPage: itemsPerPage,
            pageNo: pageNo,
            searchString: searchString
        }
        const response = await axiosClient.post(searchStaffsRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Remove Staff ----------------
export const removeStaffAPI = async (staffId) => {
    try {
        const data = {
            staffId: staffId
        }
        const response = await axiosClient.post(removeStaffRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Get staff by id ----------------
export const getStaffByIdAPI = async (staffId) => {
    try {
        const data = {
            staffId: staffId
        }
        const response = await axiosClient.post(getStaffByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------upload staff image ----------------
export const uploadStaffImageAPI = async (staffId, image) => {
    try {
        const formData = new FormData()
        formData.append("staffId", staffId)
        formData.append("image", image)
        const response = await axiosClientFormData.post(uploadStaffImageRoute, formData)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Remove staff ----------------
export const removeStaffImageAPI = async (staffId, imagePath) => {
    try {
        const data = {
            staffId: staffId,
            imagePath: imagePath
        }
        const response = await axiosClient.post(removeStaffImageRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//----------Active staff ----------------
export const activeStaffByIdAPI = async (staffId) => {
    try {
        const data = {
            staffId: staffId
        }
        const response = await axiosClient.post(activeStaffByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}

//---------- Deactive staff ----------------
export const deactiveStaffByIdAPI = async (staffId) => {
    try {
        const data = {
            staffId: staffId
        }
        const response = await axiosClient.post(deactiveStaffByIdRoute, data)
        return response?.data
    } catch (error) {
        console.error("Error:", error.message)
    }
    return null
}
