import './App.css';
import PropTypes from 'prop-types'
// layouts Format
import VerticalLayout from "./components/Layout/";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

import { Route, Routes } from "react-router-dom"
import { connect } from "react-redux"

import "./assets/scss/theme.scss"
import NonAuthLayout from './components/NonAuthLayout';
import Authmiddleware from './routes/middleware/Authmiddleware';

function App(props) {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const Layout = getLayout();

  return (
    <>
      <Routes>

        {/* Non-authenticated routes */}
        {authRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          />
        ))}

        {/* Authenticated routes */}
        {userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
          />
        ))}
      </Routes>
    </>
  );
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
