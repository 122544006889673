import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import moment from 'moment';
import Swal from "sweetalert2"
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { countUserRightAPI, deleteUserRightsAPI, getAllUserRightsAPI } from '../../service/service';

const UserRightsList = ({ refresh, dataRecived, onChildClick }) => {
    const [rights, setRights] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    const userRights = useSelector((state) => state.Profile.userRights);
    const [count, setRightsCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const handleDisplayRights = async () => {
        try {
            const res = await getAllUserRightsAPI(perPage, pageNo);
            setRights(res.data);
        }
        catch (error) {
            console.log(error);
        }
    };
    // console.log("rights", rights);

    const handlechangepage = (event, newpage) => {
        setPageNo(newpage + 1);
    }

    const handleRowsPerPage = (event) => {
        setPerPage(+event.target.value)
        setPageNo(1);
    }
    const getRentCount = async () => {
        try {
            const res = await countUserRightAPI();
            // console.log("res", res);
            if (res.status === '1' || res.status === 1) {
                setRightsCount(res.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        async function getCounts() {
            await getRentCount();
        }
        getCounts();
    }, [])

    const handleDeleteRights = async (i) => {
        try {
            const id = rights[i].id;
            const res = await deleteUserRightsAPI(id);
            onChildClick(" ", " ")
            setTimeout(() => {
                setIsDelete(!isDelete)
            }, 100);

            if (res.status === 1 || res.status === "1") {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleUpdateRights = async (i) => {
        try {
            dataRecived({ "id": rights[i].id, "isUpdateChild": true });
            const updateRecord = rights[i];
            onChildClick(updateRecord.name, updateRecord.displayName)
            // document.getElementById('userStatus').value = updateRecord.status;
            // updateRecord.status ? document.getElementById('Radio1').checked = updateRecord.status : document.getElementById('Radio2').checked = !updateRecord.status
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleDisplayRights();
        // eslint-disable-next-line
    }, [perPage, pageNo, onChildClick])
    return (
        <>
            <Col xl={9}>
                <Card style={{ boxShadow: 'none' }}>
                    <CardBody>
                        <CardTitle><b>User Rights Lists</b></CardTitle>
                        <div className="table-responsive mt-3">


                            <TableContainer>
                                <Table id='mytable'>
                                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                        <TableRow>
                                           
                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Action</TableCell>
                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Rights</TableCell>
                                        
                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Last Modified</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rights && rights.map((data, index) => (
                                            < TableRow key={index} >
                                                {/* <TableCell>{index + 1}</TableCell> */}

                                                <TableCell>
                                                    <div className="d-flex gap-2">
                                                        {/* {userRights.user_right?.write && */}
                                                            <div> <i className="fas fa-file-signature" onClick={() => handleUpdateRights(index)} style={{ cursor: 'pointer' }} ></i></div>
                                                        {/* } */}
                                                        <div> <i className="fas fa-grip-lines-vertical"></i></div>
                                                        {/* {userRights.user_right?.delete && */}
                                                            <div> <i className="fas fa-trash" onClick={() => handleDeleteRights(index)} style={{ cursor: 'pointer' }}></i></div>
                                                        {/* } */}

                                                    </div></TableCell>
                                              
                                                <TableCell>{data.displayName}</TableCell>
                                                <TableCell>{moment(data.updatedAt).format('DD/MM/YYYY')}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 25]}
                                    rowsPerPage={perPage}
                                    page={pageNo - 1}
                                    count={count}
                                    component="div"
                                    onPageChange={handlechangepage}
                                    onRowsPerPageChange={handleRowsPerPage}
                                />

                            </TableContainer>
                        </div>
                    </CardBody>
                </Card>
            </Col >
        </>
    )
}

export default UserRightsList
