import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Form,
    Input
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import logoLightPng from "../../assets/images/shayam-logo.jpg"
import Swal from "sweetalert2"
import { authLoginAPI } from "../../service/service"

const Login = () => {
    document.title = "Login | Shayam Global"

    const [isVisible, setVisible] = useState(false)
    const [userNameValue, setUserNameValue] = useState("")
    const [passwordValue, setPasswordValue] = useState("")
    const [userGroupValue, setUserGroupValue] = useState("")
    const navigate = useNavigate()

    const toggle = () => {
        setVisible(!isVisible)
    }

    const handleOnchangeEmail = e => {
        setUserNameValue(e.target.value)
    }
    const handleOnchangepwd = e => {
        setPasswordValue(e.target.value)
    }
    const handleOnchangeUserGroup = e => {
        const value = e.target.value
        setUserGroupValue(value)
    }
    const handleSubmit = async () => {
        try {
            // const res = await authLogin("kinjal", "123456", "admin_staff")
            const res = await authLoginAPI(userNameValue, passwordValue, userGroupValue)

            if (res && res?.status === "1") {
                const data = res.data
                localStorage.setItem("isLoggedIn", true)
                localStorage.setItem("loginUserId", data._id)
                localStorage.setItem("accessToken", data.accessToken)
                localStorage.setItem("refreshToken", data.refreshToken)
                localStorage.setItem("username", data.username)
                localStorage.setItem("expiryTime", data.expiryTime)
                localStorage.setItem("secretKey", data.secretKey)
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500,
                })
                navigate("/dashboard")
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-5 mb-4">
                                        <Link to="/" className="d-block auth-logo">
                                            <img
                                                src={logoLightPng}
                                                alt=""
                                                className="auth-logo-dark"
                                                style={{ height: "5rem" }}
                                            />

                                        </Link>
                                    </h3>

                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">
                                            Welcome Back !
                                        </h4>
                                        <p className="text-muted text-center">
                                            Sign in to continue to Shayam Global.
                                        </p>
                                        <Form className="form-horizontal mt-4" onSubmit={e => {
                                            e.preventDefault()
                                            handleSubmit()
                                            return false
                                        }} >
                                            <div className="mb-3">
                                                <Label htmlFor="username">Username</Label>
                                                <Input
                                                    name="userName"
                                                    className="form-control"
                                                    placeholder="Enter User name"
                                                    type="text"
                                                    onChange={handleOnchangeEmail}
                                                    value={userNameValue}
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="userpassword">Password</Label>
                                                <div className="d-flex position-relative">
                                                    <Input
                                                        name="password"
                                                        type={!isVisible ? "password" : "text"}
                                                        placeholder="Enter Password"
                                                        onChange={handleOnchangepwd}
                                                        value={passwordValue}
                                                    />
                                                    <span
                                                        className="icon align-self-center"
                                                        style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={toggle}
                                                    >
                                                        {isVisible ? (
                                                            <i
                                                                className="fas fa-eye"
                                                                style={{ color: "gray" }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fas fa-eye-slash"
                                                                style={{ color: "gray" }}
                                                            ></i>
                                                        )}
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="userGroup">Usergroup</Label>
                                                <select
                                                    className="form-control"
                                                    id="userGroup"
                                                    onChange={handleOnchangeUserGroup}
                                                >
                                                    <option value="">---SELECT---</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="admin_staff">Admin_Staff</option>
                                                </select>
                                            </div>
                                            <Row className="mb-3 mt-4">
                                                <div className="col-12 text-end">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                                        Log In
                                                    </button>
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-5 text-center">
                                © 2024 Shayam Global - All Rights Reserved.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    )
}

export default Login;
