import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getOrderByIdAPI } from '../../service/service';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import moment from 'moment';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"

const OrderDetails = (props) => {
    document.title = "Category Details | Shayam Global";

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Order List", link: "/orderList" },
        { title: "Order Details", link: "#" }
    ]
    useEffect(() => {
        props.setBreadcrumbItems('Order Details', breadcrumbItems)
    },)

    const { oid } = useParams()
    const [ordertDetails, setOrderDetails] = useState([]);
    const [productDetails, setProductDetails] = useState([]);


    const handleDisplayOrder = async id => {
        try {
            const res = await getOrderByIdAPI(id);
            // console.log(res);
            setOrderDetails(res?.data[0]);
            setProductDetails(res?.data[0]?.product)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (oid) {
            handleDisplayOrder(oid)
        }
        //eslint-disable-next-line
    }, [])
    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <div className="goback">
                            <Link to="/orderList" className="">
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <CardTitle className="fs-4">
                                <b>Order Details</b>
                            </CardTitle>
                            <hr />
                            <Row className="fw-bold view-table" style={{}}>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Order Number</h6>
                                    <p>{ordertDetails?.orderNumber}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Order Total Price</h6>
                                    <p>{"₹ " + Intl.NumberFormat('en-IN').format(ordertDetails?.orderTotal)}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Order Status</h6>
                                    {/* <p>{ordertDetails?.orderStatus}</p> */}
                                    {
                                        ordertDetails?.orderStatus === "New Order" ?
                                            <p> <span class={ordertDetails?.orderStatus === "New Order" ? "order order-place" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                    {
                                        ordertDetails?.orderStatus === "In Process" ?
                                            <p> <span class={ordertDetails?.orderStatus === "In Process" ? "order order-inprogrress" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                    {
                                        ordertDetails?.orderStatus === "Pending Order" ?
                                            <p> <span class={ordertDetails?.orderStatus === "Pending Order" ? "order order-pending" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                    {
                                        ordertDetails?.orderStatus === "Dispatch" ?
                                            <p> <span class={ordertDetails?.orderStatus === "Dispatch" ? "order order-dispatch" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                    {
                                        ordertDetails?.orderStatus === "Reject" ?
                                            <p> <span class={ordertDetails?.orderStatus === "Reject" ? "order order-reject" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                    {
                                        ordertDetails?.orderStatus === "Completed" ?
                                            <p> <span class={ordertDetails?.orderStatus === "Completed" ? "order order-complete" : ""}>{ordertDetails?.orderStatus}</span></p>
                                            : ""
                                    }
                                </Col>
                                {
                                    ordertDetails?.orderStatus === "Dispatch" ?
                                        <Col xl={3} lg={4} md={6} sm={12}>
                                            <h6 className="fw-bold">Order Shipping Receipt</h6>
                                            {console.log(ordertDetails?.orderShippingRecipt)}
                                            {/* <p>{ordertDetails?.orderShippingRecipt}</p> */}
                                            {ordertDetails?.orderShippingRecipt ? (
                                                <ImageViewer
                                                    path={ordertDetails?.orderShippingRecipt}
                                                    style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                                >
                                                    {" "}
                                                </ImageViewer>
                                            ) : (
                                                <ImageViewer
                                                    path={dummy}
                                                    style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                                >
                                                    {" "}
                                                </ImageViewer>
                                            )}
                                        </Col>
                                        : ""
                                }

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Order Date</h6>
                                    {/* <p>{moment(ordertDetails?.orderTime).format('DD/MM/YYYY')}</p> */}
                                    <p>{moment(ordertDetails?.orderTime).format("DD-MM-YYYY")}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Order Time</h6>
                                    <p>{moment(ordertDetails?.orderTime).format("hh:mm:ss A")}</p>
                                </Col>

                            </Row>
                            <br />
                            <br />
                            <CardTitle className="fs-4">
                                <b>Customer Details</b>
                            </CardTitle>
                            <hr />
                            <Row className="fw-bold view-table" style={{}}>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Name</h6>
                                    <p>{ordertDetails?.CustomerData?.fullName}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Address</h6>
                                    <p>{ordertDetails?.CustomerData?.factoryAddress}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Contact Person Name</h6>
                                    <p>{ordertDetails?.CustomerData?.contactPersonName}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Contact Person Number</h6>
                                    <p>{ordertDetails?.CustomerData?.contactPersonNumber}</p>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <CardTitle className="fs-4">
                                <b>Order Product Details</b>
                            </CardTitle>
                            <hr />
                            <table class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productDetails && productDetails.length > 0 && productDetails.map((prod) => {
                                        return (
                                            <tr>
                                                <td>{prod?.productData?.productName}</td>
                                                <td>{prod?.qty[0] + " " + prod?.qty[1]}</td>
                                                {/* <td>{"₹ " + prod?.productData?.price}</td> */}
                                                <td>{"₹ " + Intl.NumberFormat('en-IN').format(prod?.productData?.price)}</td>
                                                {/* <td>{"₹ " + prod?.productData?.price * prod.qty[0]}</td> */}
                                                <td>{"₹ " + Intl.NumberFormat('en-IN').format(prod?.productData?.price * prod.qty[0])}</td>
                                            </tr>
                                        )
                                        // console.log(prod.productData.productName);
                                    })}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default connect(null, { setBreadcrumbItems })(OrderDetails); 