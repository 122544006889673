import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import Swal from "sweetalert2"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { countUserRoleAPI, deleteUserRolesAPI, getUserRoleAPI } from '../../service/service';

const UserRoleList = ({ refresh, dataRecived, onChildClick }) => {
    const [roles, setRoles] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    const userRights = useSelector((state) => state.Profile.userRights);
    const [isRefresh, setIsRefresh] = useState(false);
    const [count, setRentCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const handleDisplayRole = async () => {
        try {
            const res = await getUserRoleAPI(perPage, pageNo);
            setRoles(res.data);

        }
        catch (error) {
            console.log(error);
        }
    };

    const handlechangepage = (event, newpage) => {
        setPageNo(newpage + 1);
    }

    const handleRowsPerPage = (event) => {
        setPerPage(+event.target.value)
        setPageNo(1);
    }

    const getRentCount = async () => {
        try {
            const res = await countUserRoleAPI();
            // console.log("res", res);
            if (res.status === '1' || res.status === 1) {
                setRentCount(res.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function getCounts() {
            await getRentCount();
        }
        getCounts();
    }, [isRefresh])

    const handleDeleteRole = async (i) => {
        try {
            const id = roles[i].id;
            const res = await deleteUserRolesAPI(id);
            setTimeout(() => {
                setIsDelete(!isDelete)
            }, 100);
            setIsRefresh(!isRefresh);
            if (res.status === 1 || res.status === '1') {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleUpdateRole = async (i) => {
        try {
            dataRecived({ "id": roles[i].id, "isUpdateChild": true });
            const updateRecord = roles[i];
            onChildClick(updateRecord.name);
            updateRecord.status ? document.getElementById('Radio1').checked = updateRecord.status : document.getElementById('Radio2').checked = !updateRecord.status
            setIsRefresh(!isRefresh);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleDisplayRole();
        // eslint-disable-next-line
    }, [isRefresh, perPage, pageNo, onChildClick])
    return (
        <>
            <Col xl={9}>
                <Card style={{ boxShadow: 'none' }}>
                    <CardBody>
                        <CardTitle><b>User Roles Lists</b></CardTitle>
                        <div className="table-responsive mt-3">


                            <TableContainer>
                                <Table>
                                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                        <TableRow>

                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Action</TableCell>
                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>User Role</TableCell>
                                            <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {roles && roles.map((data, index) => (
                                            <TableRow key={index}>


                                                <TableCell>
                                                    <div className="d-flex gap-2">

                                                        {/* {userRights.user_role?.write && */}
                                                        <div> <i className="fas fa-file-signature" style={{ cursor: 'pointer' }} onClick={() => handleUpdateRole(index)}></i></div>
                                                        {/* } */}
                                                        <div> <i className="fas fa-grip-lines-vertical"></i></div>

                                                        {/* {userRights.user_role?.delete && */}
                                                        <div> <i className="fas fa-trash" onClick={() => handleDeleteRole(index)} style={{ cursor: 'pointer' }}></i></div>
                                                        {/* } */}


                                                    </div></TableCell>
                                                <TableCell>{data.name}</TableCell>
                                                <TableCell>{data.status ? "Active" : "Inactive"}</TableCell>


                                            </TableRow>

                                        ))}
                                    </TableBody>

                                </Table>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 25]}
                                    rowsPerPage={perPage}
                                    page={pageNo - 1}
                                    count={count}
                                    component="div"
                                    onPageChange={handlechangepage}
                                    onRowsPerPageChange={handleRowsPerPage}
                                />

                            </TableContainer>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default UserRoleList
