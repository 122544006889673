import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Card, CardBody } from "reactstrap";
import { getCategoryByIdAPI, getSubCategoryByIdAPI, removeSubSubCategoryAPI } from '../../service/service';

const InnerSubCategoryList = (props) => {
    const history = useNavigate();
    const { isid } = useParams();
    const [categoryId, setCategoryId] = useState();
    document.title = "Inner SubCategory List | Shayam Global";

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/" },
        { title: "Category List", link: "/categoryList" },
        { title: "Subcategory List", link: `/subCategoryList/${categoryId}` },
        { title: "Inner SubCategory List", link: "#" }
    ]
    const [subCategory, setSubCategory] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);


    const getCategoryList = async () => {
        try {
            const response = await getSubCategoryByIdAPI(categoryId, isid);
            setSubCategory(response[0].subCatData.subCategory);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdate = id => {
        try {
            history(`/updateInnerCategory/${id}`)
        } catch (error) {
            console.log(error);
        }
    }

    const removeCategory = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async result => {
            if (result.isConfirmed) {
                const res = await removeSubSubCategoryAPI(localStorage.getItem('categoryId'), isid, id)
                if (res.status === "1" || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                    setIsRefresh(!isRefresh)
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
                // getCategoryList()
            }
            setIsRefresh(!isRefresh)
        })
    }

    const handleSubcategory = async (i) => {
        try {
            history(`/createInnerCategory`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCategoryList();
    }, [categoryId, isRefresh]);

    useEffect(() => {
        getCategoryList();
    }, []);
    useEffect(() => {
        setCategoryId(localStorage.getItem("categoryId"));
    }, [])


    useEffect(() => {
        props.setBreadcrumbItems('Inner SubCategory List', breadcrumbItems)
    },)

    return (
        <div>
            <div className="d-flex justify-content-end">

                <div className="add">
                    <button onClick={() => handleSubcategory(isid)}>
                        +
                    </button>
                </div>
            </div>
            <Card>
                <CardBody>
                    <TableContainer>
                        <Table>
                            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                <TableRow className='tableHeadlabel'>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subCategory && subCategory.map((subCat, index) => (
                                    <TableRow className="tablerow" key={"subCat" + index}>
                                        <TableCell>{subCat.name}</TableCell>
                                        <TableCell>
                                            {
                                                subCat ? <ImageViewer path={subCat.image}
                                                    style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}></ImageViewer> : <img
                                                    src={dummy}
                                                    alt="aadhar-img"
                                                    style={{ width: "6rem", height: "5rem" }}
                                                />
                                            }
                                        </TableCell>

                                        <TableCell>
                                            <div style={{ display: 'flex', gap: '5px' }}>

                                                <button className="btn btn-primary"
                                                    onClick={() => handleUpdate(subCat.id)}>Edit</button>

                                                <button className="btn btn-danger" onClick={() => removeCategory(subCat.id)}>Delete</button>
                                            </div>
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                </CardBody>
            </Card>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(InnerSubCategoryList);
