import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row, Label, Form } from "reactstrap";
import Swal from "sweetalert2"
import { useSelector } from "react-redux";
import { createUserRightAPI, updateUserRightAPI } from "../../service/service";
import UserRightsList from "./UserRightsList";

const Rights = (props) => {
    document.title = "Rights | Shayam Global";

    const breadcrumbItems = [
        { title: "Shyam Global", link: "/dashboard" },
        { title: "Rights", link: "#" }
    ]
    const re = /^[A-Za-z\s]+$/;
    const [rightsName, setRightsName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [userStatus, setUserStatus] = useState(true);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [childId, setChildId] = useState('');

    const userRights = useSelector((state) => state.Profile.userRights);


    const handleName = (e) => {
        const { value } = e.target;
        if (value === "" || re.test(value)) {
            setRightsName(value);
        }

    }

    const handleDisplayName = (e) => {
        const { value } = e.target;
        if (value === "" || re.test(value)) {
            setDisplayName(value);
        }

    }


    const handleSubmit = async () => {

        try {
            const res = await createUserRightAPI(rightsName, displayName, userStatus);
            localStorage.setItem('createRights', res.status);
            handleClean();
            if (res.status === '1' || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",

                })
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",

                })
            }
        }
        catch (error) {
            throw (error)
        }
    };
    const handleClean = () => {
        handleChild();
        handleClear();
    }
    const handleClear = () => {
        setRightsName('')
        setDisplayName('')
        setIsUpdate(false);
    };
    const handleChild = () => {
        setTimeout(() => {
            setIsRefresh(!isRefresh);
        }, 100);
    }

    const handleUpdate = async () => {
        try {
            if (rightsName.length > 0 && displayName.length > 0) {
                const res = await updateUserRightAPI(childId, rightsName, displayName, userStatus);
                res.status === '1' ? setIsUpdate(false) : setIsUpdate(true)
                handleClean();
                if (res.status === '1' || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    })
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
            }
            else {
                return false;
            }

        }
        catch (error) {
            throw (error)
        }
    };
    const handleUpdateChild = (data) => {
        setChildId(data.id);
        setIsUpdate(data.isUpdateChild);
    };

    const handleChildClick = (name, displayName) => {
        setRightsName(name)
        setDisplayName(displayName)
    };


    useEffect(() => {
        props.setBreadcrumbItems('Rights', breadcrumbItems)
    },)
    return (
        <div>
            <div className="mt-3">
                <div>
                    <Card>
                        <CardBody>
                            <Row>
                                {/* {userRights.user_right?.write &&  */}
                                <Col xl={3}>
                                    <Card style={{ boxShadow: 'none' }}>
                                        <CardBody >
                                            <CardTitle><b>UserRight Details</b> </CardTitle>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            >
                                                <div>
                                                    <Label htmlFor="example-text-input" className=" col-form-label" >
                                                        Right Name*
                                                    </Label>
                                                    <div>
                                                        <input className="form-control" type="text" name="name" placeholder="Enter Right Name" id="userName" value={rightsName} onChange={handleName} required />
                                                    </div>

                                                </div>
                                                <div>
                                                    <Label htmlFor="example-text-input" className=" col-form-label" >
                                                        Display Name*
                                                    </Label>
                                                    <div>
                                                        <input className="form-control" type="text" name="displayName" placeholder="Enter Display Name" id="displayName" value={displayName} onChange={handleDisplayName} required />
                                                    </div>

                                                </div>
                                                <div className="mt-3">
                                                    <button className="role-save custom-btn" onClick={isUpdate ? handleUpdate : handleSubmit}>{!isUpdate ? "Save" : "Update"}</button>
                                                    <button className="role-clear custom-btn" onClick={handleClear}>Clear</button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                                 {/* } */}

                                <UserRightsList refresh={isRefresh} dataRecived={handleUpdateChild} onChildClick={handleChildClick} />
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(Rights); 
