import React, { useEffect } from "react";
import Swal from 'sweetalert2'

//redux
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const history = useNavigate();

    useEffect(() => {
        localStorage.clear();
        Swal.fire({
            title: "Logout SuccessFully",
            icon: "success",
            timer: 1500
        })
        history("/login")
    }, [history]);

    return <></>;
};

export default Logout;