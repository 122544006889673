import React, { useEffect, useState } from 'react';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { addToCartAPI, getCustomerListAPI, getProductListAPI } from '../../service/service';
import { Card, CardBody, CardTitle, Col, Label, Row } from 'reactstrap';
import Select from "react-select"
const AddtoCart = (props) => {
    document.title = "Add to cart | Shayam Global";

    const breadcrumbItems = [
        { title: "Shayam Global", link: "#" },
        { title: "Add to cart", link: "#" }
    ]

    const [customer, SetCustomer] = useState([]);
    const [cartProducts, setCartProducts] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [productId, setProductId] = useState("");
    const [counter, setCounter] = useState(0);
    const [product, SetProduct] = useState([]);


    const handleSubmitSubHolder = async (e) => {
        e.preventDefault();
        let cartItems = {
            "id": productId,
            "qty": counter,
        }
        setCartProducts(cartItems);
        console.log(cartItems);
    }

    const handleSelectCustomer = e => {
        const value = e.value
        setCustomerId(value)
    }

    const handleSelectProduct = e => {
        const value = e.value
        setProductId(value)
    }
    const handleSubmit = async () => {
        // console.log("create customer");
        try {
            const res = await addToCartAPI(
                customerId, cartProducts
            )
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }

    const getCustomerList = async () => {
        try {
            const response = await getCustomerListAPI()
            SetCustomer(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getProductList = async () => {
        try {
            const response = await getProductListAPI()
            SetProduct(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const incrementCounter = () => {
        setCounter(counter + 1);
    };

    const decrementCounter = () => {
        if (counter !== 0) {
            setCounter(counter - 1);
        }
    };

    useEffect(() => {
        getCustomerList();
        getProductList();
    }, [])

    useEffect(() => {
        props.setBreadcrumbItems('Add to cart', breadcrumbItems)
    },)

    const customeroptions = [
        customer &&
        customer.map(data => {
            return { value: data.id, label: data.fullName }
        }),
    ]

    const productoptions = [
        product &&
        product.map(data => {
            return { value: data.id, label: data.productName }
        }),
    ]

    return (
        <div>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="text-center fs-4">
                                <b>Add to cart</b>{" "}
                            </CardTitle>
                            <div className="py-1">
                                <Label className="col-form-label">
                                    Select customer
                                </Label>
                                <div>
                                    {customeroptions &&
                                        customeroptions.map((data, i) => (
                                            <Select key={"cus" + i} options={data} onChange={handleSelectCustomer} placeholder="Select Customer" />
                                        ))}
                                </div>
                            </div>
                            <Row className='py-1'>
                                <Col lg={6}>
                                    <div className="py-1">
                                        <Label className="col-form-label">
                                            Select Product
                                        </Label>
                                        <div>
                                            {productoptions &&
                                                productoptions.map((data, i) => (
                                                    <Select key={"pro" + i} options={data} onChange={handleSelectProduct} placeholder="Select Product" />
                                                ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="py-1">
                                        <Label className="col-form-label">
                                            Quantity
                                        </Label>
                                        <div>
                                            <div className="">
                                                <button onClick={incrementCounter} className='Itembtn'>
                                                    +
                                                </button>
                                                <span className="number">
                                                    {counter}
                                                </span>
                                                <button onClick={decrementCounter} className='Itembtn'>
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="py-1 d-flex align-items-center h-100" >

                                        <button className='px-4 py-2 itemAddBtn' onClick={handleSubmitSubHolder}>Add to Cart</button>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <button type="submit" className="btn btn-primary w-md" onClick={handleSubmit}>Submit</button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(AddtoCart);
