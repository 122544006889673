import React, { useEffect, useState } from 'react'
import { Card, CardBody } from "reactstrap"
import Swal from "sweetalert2";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCategoryByIdAPI, removeSubCategoryAPI } from '../../service/service';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"

const SubCategoryList = (props) => {

    const { sid } = useParams();
    const history = useNavigate();

    document.title = "Subcategory List | Shayam Global"

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Category List", link: "/categoryList" },
        { title: "Subcategory List", link: "#" }
    ]

    // const [rowperpage, rowperpagechange] = useState(5);
    // const [pageNo, setPageNo] = useState(1);
    // const [count, setCategoryCount] = useState(0);

    // const handlechangepage = (event, newpage) => {
    //     setPageNo(newpage + 1);
    // }

    // const handleRowsPerPage = (event) => {
    //     rowperpagechange(+event.target.value)
    //     setPageNo(1);
    // }

    const [subCategory, setSubCategory] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);


    const getCategoryList = async () => {
        try {
            const response = await getCategoryByIdAPI(sid)
            setSubCategory(response.data.subCategory)
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdate = id => {
        try {
            history(`/updateSubCategory/${id}`)
        } catch (error) {
            console.log(error);
        }
    }

    const removeCategory = async (name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async result => {
            if (result.isConfirmed) {
                const res = await removeSubCategoryAPI(sid, name)
                if (res.status === "1" || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                    setIsRefresh(!isRefresh)
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
            }
            // setIsRefresh(!isRefresh)
        })
    }

    const handleSubcategory = async (i) => {
        try {
            history(`/createSubCategory`)
        } catch (error) {
            console.log(error)
        }
    }

    const handleInnercategoryList = async (i) => {
        try {
            history(`/innerSubCategoryList/${i}`)
            localStorage.setItem("subCategoryId", i)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        props.setBreadcrumbItems("Subcategory List", breadcrumbItems);
        //eslint-disable-next-line
    }, [window.location.href])

    useEffect(() => {
        getCategoryList()
    }, [isRefresh])

    return (
        <>
            {/* <div className="d-flex justify-content-end position-relative">
                <div className="input-group w-25 searchBox">
                    <span
                        className="fa fa-search mb-3 input-group-text"
                        id="basic-addon1"
                        style={{ height: "auto" }}
                    ></span>
                    <Input
                        type="search"
                        className="mb-3 d-flex form-control"
                    // onChange={handleSearch}
                    />
                </div>
            </div> */}
            <div className="d-flex justify-content-end">
                {/* <div className="goback">
                    <Link to="/categoryList" className="">
                        Go Back
                    </Link>
                </div> */}
                <div className="add">
                    <button onClick={() => handleSubcategory(sid)}>
                        +
                    </button>
                </div>
            </div>
            <Card>
                <CardBody>
                    <TableContainer>
                        <Table>
                            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                <TableRow className='tableHeadlabel'>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Image</TableCell>
                                    {/* <TableCell>Status</TableCell> */}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subCategory && subCategory.map((subCat, index) => (
                                    <TableRow className="tablerow" key={"subCat" + index}>
                                        <TableCell onClick={() => handleInnercategoryList(subCat.id)}>{subCat.name}</TableCell>
                                        <TableCell>
                                            {
                                                subCat.subCategoryImage ? <ImageViewer path={subCat.subCategoryImage}
                                                    style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}></ImageViewer> : <img
                                                    src={dummy}
                                                    alt="aadhar-img"
                                                    style={{ width: "6rem", height: "5rem" }}
                                                />
                                            }
                                        </TableCell>
                                        {/* <TableCell>
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                {subCat.status ? (
                                                    <div
                                                        className="active-btn"
                                                    // onClick={() => handleDeactiveSlider(slide._id)}
                                                    >
                                                        Active
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="deactive-btn"
                                                    // onClick={() => handleActiveSlider(slide._id)}
                                                    >
                                                        Inactive
                                                    </div>
                                                )}
                                            </div>

                                        </TableCell> */}

                                        <TableCell>
                                            <div style={{ display: 'flex', gap: '5px' }}>

                                                <button className="btn btn-primary"
                                                    onClick={() => handleUpdate(subCat.id)}>Edit</button>

                                                <button className="btn btn-danger" onClick={() => removeCategory(subCat.id)}>Delete</button>
                                            </div>
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>

                        {/* <TablePagination
                            rowsPerPageOptions={[2, 5, 10, 20]}
                            rowsPerPage={rowperpage}
                            page={pageNo - 1}
                            count={count}
                            component="div"
                            onPageChange={handlechangepage}
                            onRowsPerPageChange={handleRowsPerPage}
                        >
                        </TablePagination> */}

                    </TableContainer>
                </CardBody>
            </Card>
        </>
    )
}

export default connect(null, { setBreadcrumbItems })(SubCategoryList);  
