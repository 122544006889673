import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ImageViewer from '../../imageViewer';
import Swal from "sweetalert2";
import { deleteSliderImageAPI, getSliderCountAPI, getSliderImageAPI, updateSliderImageStatusAPI, uploadSliderImageAPI } from '../../service/service'
import dummy from "../../assets/images/dummy.jpg"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

const SliderGallery = (props) => {
  document.title = "Slider Gallery | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "#" },
    { title: "Slider Gallery", link: "#" }
  ]
  useEffect(() => {
    props.setBreadcrumbItems('Slider Gallery', breadcrumbItems)
  },)

  const [file, setFile] = useState("");
  const [fileView, setFileView] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [rowperpage, rowperpagechange] = useState(5);
  const [slider, setSlider] = useState([]);
  const [count, setSliderCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  function handleChange(e) {
    // console.log(e.target.files);
    setFile(e.target.files[0]);
    setFileView(URL.createObjectURL(e.target.files[0]));
    document.getElementById("sliderImg").value = null
  }

  const uploadImage = async () => {
    try {
      const res = await uploadSliderImageAPI(file)
      if (res.status === "1" || res.status === 1) {
        setFileView("");
        setFile("");
        document.getElementById('sliderImg').value = null
        getSlideList()
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })

      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })

      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }

  const getSliderCount = async () => {
    try {
      const response = await getSliderCountAPI()
      setSliderCount(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getSlideList = async () => {
    try {
      const response = await getSliderImageAPI(rowperpage, pageNo)
      setSlider(response.data)
    } catch (error) {
      console.log(error);
    }
  }


  const handleActiveSlider = async i => {
    let status = true
    try {
      const res = await updateSliderImageStatusAPI(i, status)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeactiveSlider = async i => {
    let status = false
    try {
      const res = await updateSliderImageStatusAPI(i, status)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeImage = async (imageId, imagePath) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
      
        deleteSliderImageAPI(imageId, imagePath)
        Swal.fire({
          title: "File Removed Successfully!..",
          icon: "success",
          timer: 1500
        })
        getSlideList()
        setIsRefresh(!isRefresh)
      }
    })
  }

  useEffect(() => {
    getSlideList();
  }, [isRefresh, rowperpage, pageNo])

  useEffect(() => {
    async function getCounts() {
      await getSliderCount();
    }
    getCounts();
  }, [isRefresh])


  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {/* <h4 className="card-title mb-4"></h4> */}
              <CardTitle className="text-center fs-4">
                <b>Upload Image</b>{" "}
              </CardTitle>
              <hr />

              <Row >
                <Col lg={6} >
                  <input type='file' id="sliderImg" className='form-control' onChange={handleChange} accept='image/*'></input>
                  <div >
                    {fileView ? (
                      <img src={fileView} style={{ maxWidth: "15rem", height: "13rem" }} alt='' className='py-2' />
                    ) : (
                      <img src={dummy} alt="" style={{ maxWidth: "20rem", height: "13rem", minWidth: "15rem" }} className='py-2' />
                    )}
                  </div>

                  <div className="mt-4 ">
                    <button type="submit" className="btn btn-primary w-md" onClick={uploadImage}>Upload Image</button>
                  </div>
                </Col>
              </Row>
              <hr />
              {/* <Row className='mt-4'>
            {images && images.map((data) => {

              return (
                <Col xl="3" lg="4" md="6" key={data.id}>
                  <div className="position-relative py-2" style={{ width: "fit-content" }}>
                    <ImageViewer path={data.image} style={{ height: "10rem", width: "100%", cursor: "pointer" }} />
                    <div className='position-absolute delete-img' style={{ right: '5px', top: "8px" }} onClick={() => removeImage(data.id, data.image)}>
                      <i className="fa fa-trash fs-5 " aria-hidden="true" title="Delete" > </i>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row> */}


              <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                    <TableRow className='tableHeadlabel'>
                      <TableCell className='text-center'>Image</TableCell>
                      <TableCell className='text-center'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slider && slider
                      .map((slide, index) => (

                        <TableRow key={slide._id} className="tablerow " >
                          <TableCell className='d-flex justify-content-center'> {
                            slide.image ? <ImageViewer path={slide.image}
                              style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}></ImageViewer>
                              :
                              <img
                                src={dummy}
                                alt="slider-img"
                                style={{ width: "6rem", height: "5rem" }}
                              />
                          }</TableCell>
                          <TableCell>
                            <div className='d-flex gap-2 justify-content-center'>
                              {slide.status ? (
                                <div
                                  className="active-btn"
                                  onClick={() => handleDeactiveSlider(slide._id)}
                                >
                                  Active
                                </div>
                              ) : (
                                <div
                                  className="deactive-btn"
                                  onClick={() => handleActiveSlider(slide._id)}
                                >
                                  Inactive
                                </div>
                              )}
                              <div
                                className="deactive-btn"
                                onClick={() => removeImage(slide._id, slide.image)}
                              >
                                Delete
                              </div>
                            </div>

                          </TableCell>
                        </TableRow>

                      ))}
                  </TableBody>

                </Table>

                <TablePagination
                  rowsPerPageOptions={[2, 5, 10, 20]}
                  rowsPerPage={rowperpage}
                  page={pageNo - 1}
                  count={count}
                  component="div"
                  onPageChange={handlechangepage}
                  onRowsPerPageChange={handleRowsPerPage}
                >

                </TablePagination>

              </TableContainer>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </>

  )
}

export default connect(null, { setBreadcrumbItems })(SliderGallery); 