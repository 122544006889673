import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
} from "reactstrap"

import Swal from "sweetalert2"
import { changePasswordAPI } from "../../service/service"
// import Loading from "components/Common/Loading"

const ChangePassword = props => {
  document.title = "Change Password | Shayam Global"
  const breadcrumbItems = [
    { title: "Shayam Global", link: "/dashboard" },
    { title: "Change Password", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Change Password", breadcrumbItems)
  })
  const [isVisible, setVisible] = useState(false);
  const [isVisiblenew, setVisiblenew] = useState(false);
  const [isVisibleConnew, setVisibleConnew] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const toggleNew = () => {
    setVisiblenew(!isVisiblenew);
  };

  const toggleConNew = () => {
    setVisibleConnew(!isVisibleConnew);
  };

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [conPassword, setConPassword] = useState("")


  const user_id = localStorage.getItem("loginUserId")
  const onSubmit = async e => {

    try {
      const res = await changePasswordAPI(user_id, oldPassword, newPassword)
      // setStatus(res.status);
      if (newPassword === conPassword) {
        localStorage.setItem("createRole", res.status)
        if (res.status === 1 || res.status === '1') {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1000
          })
        } else if (res.status === 2 || res.status === '2') {
          Swal.fire({
            title: res.message,
            icon: "warning",
            dangerMode: true,
          })
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
            dangerMode: true,
          })
        }
        handleClear()
      } else {
        Swal.fire({
          title: "New password & Confirm password are not matched",
          icon: "error",
          dangerMode: true,
        })
      }
    } catch (error) { console.log(error); }
  }

  const handleClear = () => {
    document.getElementById("oldpassword").value = ""
    document.getElementById("newpassword").value = ""
    document.getElementById("conPassword").value = ""
  }

  return (

    <div className="mt-3">
      <div>
        <Row>
          <Col xl={6} lg={8}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    return false
                  }}
                >
                  <div>
                    <label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Old Password
                    </label>
                    <div className="d-flex position-relative">
                      <input
                        className="form-control"
                        type={!isVisible ? "password" : "text"}
                        id="oldpassword"
                        placeholder="Enter Your Old Password"
                        onChange={e => { setOldPassword(e.target.value) }}
                      />
                      <span
                        className="icon align-self-center"
                        style={{
                          position: "absolute",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={toggle}
                      >
                        {isVisible ? (
                          <i
                            className="fas fa-eye"
                            style={{ color: "gray" }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            style={{ color: "gray" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      New Password
                    </label>
                    <div className="d-flex position-relative">
                      <input
                        className="form-control"
                        type={!isVisiblenew ? "password" : "text"}
                        id="newpassword"
                        placeholder="Enter Your New Password"
                        onChange={e => { setNewPassword(e.target.value) }}
                      />
                      <span
                        className="icon align-self-center"
                        style={{
                          position: "absolute",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={toggleNew}
                      >
                        {isVisiblenew ? (
                          <i
                            className="fas fa-eye"
                            style={{ color: "gray" }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            style={{ color: "gray" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Confirm Password
                    </label>
                    <div className="d-flex position-relative">
                      <input
                        className="form-control"
                        type={!isVisibleConnew ? "password" : "text"}
                        id="conPassword"
                        placeholder="Enter Your New Password"
                        onChange={e => { setConPassword(e.target.value) }}
                      />
                      <span
                        className="icon align-self-center"
                        style={{
                          position: "absolute",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={toggleConNew}
                      >
                        {isVisibleConnew ? (
                          <i
                            className="fas fa-eye"
                            style={{ color: "gray" }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            style={{ color: "gray" }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                    onClick={onSubmit}
                    >
                      Change Password
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>

  )
}

export default connect(null, { setBreadcrumbItems })(ChangePassword)
