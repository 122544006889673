import React, { useEffect, useState } from 'react'
import { Table, Card, CardBody, CardTitle } from "reactstrap"
import { getCustomerListAPI } from '../../service/service'
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

const CustomerList = () => {

  const [customer, SetCustomer] = useState([]);

  const getCustomerList = async () => {
    try {
      const response = await getCustomerListAPI(5, 1)
      SetCustomer(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCustomerList();
  }, [])



  return (
    <>
  
      <Card>
        <CardBody>
          <CardTitle className="text-center fs-4">
            <b>Customer List</b>{" "}
          </CardTitle>
          <hr />
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className="fw-bold text-black">
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>GST No</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer && customer.map((cust, index) => (
                    <TableRow key={cust._id} className="tablerow text-black" >
                      <TableCell >{cust.fullName}</TableCell>
                      <TableCell >{cust.email}</TableCell>
                      <TableCell >{cust.gst}</TableCell>
                      <TableCell >{cust.factoryAddress}</TableCell>
                    </TableRow>

                  ))}
              </TableBody>
            </Table>

            {/* <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={page}
              count={customer.length}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination> */}
          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default CustomerList