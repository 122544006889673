import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { Card, CardBody, Input } from "reactstrap"
import { getProductListAPI, productCountAPI, removeProductByIdAPI, removeProductSubCategoryAPI, removeProductSubSubCategoryAPI, searchProductAPI, updateProductIsHomeAPI } from '../../service/service';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactSwitch from 'react-switch';

const ProductList = (props) => {
  document.title = "Product List | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "#" },
    { title: "Product List", link: "#" }
  ]

  const history = useNavigate();
  const [checked, setChecked] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [rowperpage, rowperpagechange] = useState(5);
  const [isRefreash, SetIsRefreash] = useState(false);
  const [product, setProduct] = useState([]);
  const [count, setProductCount] = useState(0);

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }

  const getCustomerCount = async () => {
    try {
      const response = await productCountAPI()
      setProductCount(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getCustomerList = async () => {
    try {
      const response = await getProductListAPI(rowperpage, pageNo)
      setProduct(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  const removeProduct = async (id, details) => {
    // console.log(id);
    // console.log(details.productCategoryId);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await removeProductByIdAPI(id)
        if (res.status === "1" || res.status === 1) {
          await removeProductSubCategoryAPI(details?.productCategoryId, details?.productSubCategoryId, id)
          await removeProductSubSubCategoryAPI(details?.productCategoryId, details?.productSubCategoryId, details?.productSubSubCategoryId, id)
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          SetIsRefreash(!isRefreash);
        } else {
          Swal.fire({
            title: res.message,
            icon: "error"
          })
        }
      }
    })
  }

  const handleUpdateProduct = async i => {
    try {
      history(`/updateProduct/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewProduct = async i => {
    try {
      history(`/productDetails/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchProductAPI(value)
    setProduct(res.data)
  }

  useEffect(() => {
    getCustomerList();
  }, [isRefreash, rowperpage, pageNo])

  useEffect(() => {
    async function getCounts() {
      await getCustomerCount();
    }
    getCounts();
  }, [isRefreash])

  useEffect(() => {
    props.setBreadcrumbItems('Product List', breadcrumbItems)
  },)

  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-2 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-2 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>

      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className='tableHeadlabel'>
                  <TableCell>Product Category</TableCell>
                  <TableCell>Product Sub Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Product Code</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  {/* <TableCell>Seller Name</TableCell> */}
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  {/* <TableCell>Is Home</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product && product
                  .map((prow, index) => (

                    <TableRow key={prow._id} className="tablerow" >
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.productCategory}</TableCell>
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.subCategory}</TableCell>
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.productName}</TableCell>
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.productCode}</TableCell>
                      {/* <TableCell >{prow.description}</TableCell> */}
                      {/* <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.sellerName}</TableCell> */}
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{"₹ " + Intl.NumberFormat('en-IN').format(prow.price)}</TableCell>
                      <TableCell onClick={() => handleViewProduct(prow._id)}>{prow.qty[0] + " " + prow.qty[1]}</TableCell>

                      <TableCell style={{ display: 'flex', gap: '5px' }}>
                        <button className="btn btn-primary" onClick={() => handleUpdateProduct(prow._id)}>Edit</button>
                        <button className="btn btn-danger" onClick={() => removeProduct(prow._id, prow)}>Delete</button>

                      </TableCell>
                    </TableRow>

                  ))}
              </TableBody>

            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >

            </TablePagination>

          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(ProductList); 