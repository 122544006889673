import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Label, Input } from "reactstrap"
import { addProductSubCategoryAPI, addProductSubSubCategoryAPI, createProductAPI, getCategoryByIdAPI, getProductByIdAPI, getSubCategoryByIdAPI, removeProductImageAPI, searchCategoryAPI, searchProductAPI, updateProductAPI, uploadProductImageAPI } from '../../service/service';
import Swal from "sweetalert2";
import { editProfile, setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"
import Select from "react-select"
import { axiosClientFormData } from '../../api/apiClient';
import { uploadProductImageRoute } from '../../service/backendRoute';
import Multiselect from 'multiselect-react-dropdown';

const CreateProduct = (props) => {
  const history = useNavigate();
  const { pid } = useParams();
  document.title = pid ? "Update Product | Shayam Global" : "Create Product | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "/dashboard" },
    { title: pid ? "Update Product" : "Create Product", link: "#" }
  ]

  const [productName, setProductName] = useState();
  const [productCode, setProductCode] = useState();
  const [price, setPrice] = useState();
  const [description, setDescription] = useState();
  const [updateProduct, setUpdateProduct] = useState([]);
  const [image, setImage] = useState([]);
  const [product, setProduct] = useState([]);
  const [productId, setProductId] = useState([]);
  const [isHome, setisHome] = useState(false)
  const [multipleFile, setMultipleFile] = useState([]);

  const [minOr, setMinOr] = useState([]);
  const [minOrder, setMinOrder] = useState();
  const [orderweight, setOrderWeight] = useState("");

  const [qty, setQty] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [weight, setWeight] = useState("");

  const [category, setCategory] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryName, setSubCategoryName] = useState("");
  const [subCategory, setSubCategory] = useState();
  const [innerSubCategory, setInnerSubCategory] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productSubCategory, setProductSubCategory] = useState();
  const [productInnerSubCategory, setProductInnerSubCategory] = useState();


  const [imageFile, setImageFile] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false)

  const handleImage = async (e) => {
    setImageFile(e.target.files)
    // document.getElementById('productImage').value = null;
  }

  const removeMultiImage = (index) => {
    console.log("removeMultiImage - ", index);
    setImageFile(
      Array.from(imageFile).filter(function (item) {
        return item !== index;
      })
    );
  };

  let multipleFileObj = [];
  let multipleFileArray = [];

  const handleisHome = e => {
    setisHome(!isHome)
  }

  const [relatedProducts, setRelatedProducts] = useState("")

  const onSelect = async (selectedItem) => {
    setRelatedProducts(selectedItem)
  }
  const onRemove = async (selectedItem) => {
    console.log(selectedItem);
    setRelatedProducts(selectedItem)
  }

  const uploadMultipleFiles = (e) => {
    multipleFileObj.push(e.target.files);
    for (let i = 0; i < multipleFileObj[0].length; i++) {
      multipleFileArray.push(multipleFileObj[0][i]);
    }
    setMultipleFile(multipleFileArray);
    document.getElementById("productImage").value = null
  };

  const uploadFiles = async (e) => {
    // try {
    //   let imgs = [...image].concat(e.target.files);
    //   console.log("imgsss ====== ", imgs);
    //   let formData = new FormData();
    //   formData.append('productId', pid);
    //   Array.from(imgs).map(item => {
    //     console.log("itemitemitem ==== ", item);
    //     formData.append('productImages', item);
    //   })
    //   console.log("formData === ", formData);
    //   // let mimage = imgs.concat(formData)
    //   const response = await axiosClientFormData.post(uploadProductImageRoute, formData)
    //   if (response.data.status === "1" || response.data.status === 1) {
    //     // var data = response.data;
    //     // console.log("imgs", imgs)
    //     // if (data) {
    //     //   response.data.productImages.concat(imgs)
    //     //   imgs = await imgs.concat(data);
    //     //   setImage(imgs);
    //     // }
    //     Swal.fire({
    //       title: response.data.message,
    //       icon: "success",
    //       timer: 1500,
    //     })
    //     setImage(response.data.productImages)
    //   } else {
    //     Swal.fire({
    //       title: response.data.message,
    //       icon: "error",
    //     })
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      let formData = new FormData();
      formData.append('productId', pid);
      Array.from(e.target.files).map(item => {
        formData.append('productImages', item);
      })
      const response = await axiosClientFormData.post(uploadProductImageRoute, formData)
      document.getElementById("productImage").value = null
      if (response.data.status === "1" || response.data.status === 1) {
        setIsRefresh(!isRefresh)
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 1500,
        })
        setImage(response.data.productImages)
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = e => {
    const value = e.target.value
    setWeight(value)
    let temp = []
    if (value) {
      temp.push(Number(quantity))
      temp.push(value)
    }
    setQty(temp)
  }

  const handleOrderSelect = e => {
    const value = e.target.value
    setOrderWeight(value)
    let temp = []
    if (value) {
      temp.push(Number(minOrder))
      temp.push(value)
    }
    setMinOr(temp)
  }

  const handleDisplayProductUpdate = async id => {
    try {
      const res = await getProductByIdAPI(id);
      setUpdateProduct(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetFildName = () => {
    if (pid) {
      setProductName(updateProduct.productName);
      setProductCode(updateProduct.productCode);
      setCategoryName(updateProduct.productCategory);
      setProductCategory(updateProduct.productCategoryId);
      setProductSubCategory(updateProduct.productSubCategoryId);
      setProductInnerSubCategory(updateProduct.productSubSubCategoryId)
      setPrice(updateProduct.price);
      setDescription(updateProduct.description);
      setMinOrder(updateProduct.minOrder && updateProduct?.minOrder[0]);
      setOrderWeight(updateProduct.minOrder && updateProduct?.minOrder[1]);
      setMinOr(updateProduct.minOrder);
      setQuantity(updateProduct.qty && updateProduct?.qty[0]);
      setWeight(updateProduct.qty && updateProduct?.qty[1]);
      setQty(updateProduct.qty)
      setImage(updateProduct.productImages);
      setisHome(updateProduct.isHome);
      setProductId(updateProduct.relatedProducts);
      setSubCategoryName(updateProduct.subCategory);
      // console.log(updateProduct.relatedProducts);
    }
  }

  const handleSubmit = async () => {
    if (pid) {
      try {

        let productName = document.getElementById("productName").value
        let productCode = document.getElementById("productCode").value
        let price = document.getElementById("price").value
        let minOrder = document.getElementById("minOrder").value
        let description = document.getElementById("description").value
        let qty = [quantity, weight]
        let orderMin = [minOrder, orderweight]

        const res = await updateProductAPI(
          pid,
          productName,
          productCode,
          categoryName,
          productCategory,
          productSubCategory,
          productInnerSubCategory,
          price,
          qty,
          description,
          orderMin,
          isHome,
          relatedProducts,
          productSubCategory
        )

        if (res.status === "1" || res.status === 1) {
          handleClear()
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/productList");
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log("Create === ", productName,
        productCode,
        categoryName,
        productCategory,
        productSubCategory,
        productInnerSubCategory,
        price,
        qty,
        description,
        minOr,
        isHome,
        relatedProducts,
        subcategoryName);
      try {
        const res = await createProductAPI(
          productName,
          productCode,
          categoryName,
          productCategory,
          productSubCategory,
          productInnerSubCategory,
          price,
          qty,
          description,
          minOr,
          isHome,
          relatedProducts,
          subcategoryName
        )

        if (res.status === "1" || res.status === 1) {
          let formData = new FormData();
          formData.append('productId', res.data.id);
          Array.from(imageFile).map(item => {
            formData.append('productImages', item);
          })
          await axiosClientFormData.post(uploadProductImageRoute, formData)
          if (res.status === "1" || res.status === 1) {
            await addProductSubCategoryAPI(res?.data?.productCategoryId, res?.data?.productSubCategoryId, res?.data?.id)
            await addProductSubSubCategoryAPI(res?.data?.productCategoryId, res?.data?.productSubCategoryId, res?.data?.productSubSubCategoryId, res?.data?.id)
            Swal.fire({
              title: res.message,
              icon: "success",
              timer: 1500
            })
            history("/productList");
          }
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleCancle = async () => {
    history("/productList");
  }

  const handleClear = async () => {
    setProductName("");
    setProductCode("");
    setCategory("");
    setSubCategory("");
    setPrice("");
    setMinOrder("");
    setDescription("")
    setProductId();
  }

  const removeProductimg = async (index) => {
    try {
      const response = await removeProductImageAPI(pid, index)
      if (response.status === "1" || response.status === 1) {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500,
        })
        setIsRefresh(!isRefresh)
        // handleSetFildName()
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }

    } catch (error) {
      console.log(error);
    }
  }

  const getProductList = async () => {
    try {
      const response = await searchProductAPI()
      setProduct(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  const [selectedProduct, setSelectedProduct] = useState();

  // console.log("selectedProduct = ", temp);

  const handleSelectproduct = e => {
    const value = e;
    const productid = [
      value &&
      value.map(data =>
        data.value
      ),
    ]
    setProductId(productid[0]);
  }

  // const productoptions = [
  //   product &&
  //   product.map(data => {
  //     return { value: data.id, label: data.productName }
  //   }),
  // ]

  const productoptions = [
    product &&
    product.map(data => {
      // setPoptions([{ name: data.productName, id: data.id }])
      return { label: data.productName, value: data.id }
    }),
  ]
  const productoptionsMulti = [
    product &&
    product.map(data => {
      // setPoptions([{ name: data.productName, id: data.id }])
      return { name: data.productName, id: data.id }
    }),
  ]

  const getCategoryList = async () => {
    try {
      const response = await searchCategoryAPI()
      setCategory(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getsubCategoryList = async () => {
    try {
      const response = await getCategoryByIdAPI(productCategory);
      setSubCategory(response.data.subCategory);
      setCategoryName(response.data.categoryName)
    } catch (error) {
      console.log(error);
    }
  }

  const getInnersubCategoryList = async () => {
    try {
      const response = await getSubCategoryByIdAPI(productCategory, productSubCategory);
      setInnerSubCategory(response[0].subCatData.subCategory);
      console.log(response[0].subCatData.name);
      setSubCategoryName(response[0].subCatData.name);
    } catch (error) {
      console.log(error);
    }
  }

  const categoryOptions = [
    category &&
    category.map(data => {
      return { value: data._id, label: data.categoryName }
    }),
  ]

  const subCategoryOptions = [
    subCategory &&
    subCategory.map(data => {
      return { value: data.id, label: data.name }
    }),
  ]

  const innerSubCategoryOptions = [
    innerSubCategory &&
    innerSubCategory.map(data => {
      return { value: data.id, label: data.name }
    }),
  ]

  const handleSelectCategory = e => {
    const value = e.value;
    setProductCategory(value)
  }

  const handleSelectSubCategory = e => {
    const value = e.value;
    setProductSubCategory(value)
  }

  const handleSelectInnerSubCategory = e => {
    const value = e.value;
    setProductInnerSubCategory(value)
  }

  const selectedCategory = async () => {
    let temp = []
    productId && productId.map((mainProd) => {
      productoptions && productoptions.map((main) => {
        main && main.map((subid) => {
          if (mainProd === subid.value) {
            temp.push({ value: subid.id, label: subid.label });
          }
          setSelectedProduct(temp)
        })
      })
    })
  }

  useEffect(() => {
    selectedCategory();
  }, [pid])

  useEffect(() => {
    getProductList();
  }, [])

  useEffect(() => {
    getCategoryList();
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getsubCategoryList();
    //eslint-disable-next-line
  }, [productCategory])

  useEffect(() => {
    getInnersubCategoryList();
    //eslint-disable-next-line
  }, [productSubCategory])

  useEffect(() => {
    if (pid) {
      handleDisplayProductUpdate(pid)
    }
    //eslint-disable-next-line
  }, [isRefresh])

  useEffect(() => {
    handleSetFildName();
    //eslint-disable-next-line
  }, [updateProduct])

  useEffect(() => {
    props.setBreadcrumbItems(pid ? "Update Product" : 'Create Product', breadcrumbItems)
  },)




  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <button
          type="button"
          className={pid ? "btn btn-primary w-md ms-3" : "d-none"}
          onClick={handleCancle}
        >
          Go Back
        </button>
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col xl={6}>

              <div className="py-1">
                <Label className="col-form-label">
                  Select Product Category
                </Label>
                <div>
                  {categoryOptions &&
                    categoryOptions.map((data, i) => (
                      <Select key={"prod" + i}
                        options={data}
                        onChange={handleSelectCategory}
                        placeholder="Select Product Category"
                        value={data?.find(function (option) {
                          return option.value === productCategory;
                        })}
                      />
                    ))}
                </div>
              </div>

              <div className="py-1">
                <Label className="col-form-label">
                  Select Product Sub Category
                </Label>
                <div>
                  {subCategoryOptions &&
                    subCategoryOptions.map((data, i) => (
                      <Select key={"prod" + i}
                        options={data}
                        onChange={handleSelectSubCategory}
                        placeholder="Select Product Sub Category"
                        value={data?.find(function (option) {
                          return option.value === productSubCategory;
                        })}
                      />
                    ))}
                </div>
              </div>

              <div className="py-1">
                <Label className="col-form-label">
                  Select Product Inner Subcategory
                </Label>
                <div>
                  {innerSubCategoryOptions &&
                    innerSubCategoryOptions.map((data, i) => (
                      <Select key={"prod" + i}
                        options={data}
                        onChange={handleSelectInnerSubCategory}
                        placeholder="Select Product Inner Subcategory"
                        value={data?.find(function (option) {
                          return option.value === productInnerSubCategory;
                        })}
                      />
                    ))}
                </div>
              </div>

              <div className="py-1">
                <Label className="col-form-label">Product Name </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="productName"
                    name="productName"
                    placeholder="Enter Name"
                    value={productName}
                    onChange={e => { setProductName(e.target.value) }}
                    required
                  />
                </div>
              </div>

              <Row>
                <Col className='py-1'>
                  <Label className="col-form-label">Product Code </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="text"
                      id="productCode"
                      name="productCode"
                      placeholder="Enter Code"
                      value={productCode}
                      onChange={e => { setProductCode(e.target.value) }}
                      required
                    />
                  </div>
                </Col>
                <Col className='py-1'>
                  <Label className="col-form-label">
                    Product Price (₹)
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="number"
                      id="price"
                      name="price"
                      onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                      placeholder="Enter Price"
                      value={price}
                      onChange={e => { setPrice(e.target.value) }}
                      required
                    />
                  </div>
                </Col>

              </Row>

              <Row>
                <Col className='py-1'>
                  <Label className="col-form-label">
                    Minimum Order
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="number"
                      id="minOrder"
                      name="minOrder"
                      placeholder="Enter Minimum Order"
                      onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                      value={minOrder}
                      onChange={e => { setMinOrder(e.target.value) }}
                      required
                    />
                  </div>
                </Col>
                <Col className='py-1'>
                  <Label className="col-form-label"> Order Weight </Label>
                  <div>
                    <select
                      id="qtyType"
                      name='qtyType'
                      className="form-control"
                      value={orderweight}
                      onChange={handleOrderSelect}
                    >
                      <option>--Select--</option>
                      <option value="g">g</option>
                      <option value="kg">Kg</option>
                      <option value="liter">Liter</option>
                      <option value="pices">Pices</option>
                    </select>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className='py-1'>
                  <Label className="col-form-label">
                    Product Quantity
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="number"
                      id="qty"
                      name="qty"
                      placeholder="Enter Quantity"
                      onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                      value={quantity}
                      onChange={e => { setQuantity(e.target.value) }}
                      required
                    />
                  </div>
                </Col>
                <Col className='py-1'>
                  <Label className="col-form-label"> Product Weight </Label>
                  <div>
                    <select
                      id="qtyType"
                      name='qtyType'
                      className="form-control"
                      value={weight}
                      onChange={handleSelect}
                    >
                      <option>--Select--</option>
                      <option value="g">g</option>
                      <option value="kg">Kg</option>
                      <option value="liter">Liter</option>
                      <option value="pices">Pices</option>
                      {/* <option value="ml">ML</option> */}
                    </select>
                  </div>
                </Col>
              </Row>

              <div className="py-1">
                <Label className="col-form-label">Product Description</Label>
                <textarea
                  className="form-control"
                  name='description'
                  placeholder="Enter Description"
                  id="description"
                  value={description}
                  rows="3"
                  onChange={e => { setDescription(e.target.value) }}
                  required
                ></textarea>

              </div>

            </Col>

            <Col xl={6}>

              <div className="py-1">
                <Label className="col-form-label">
                  Related Products
                </Label>


                {/* <div>
                  {productoptions &&
                    productoptions.map((data, i) => (
                      <Select
                        isMulti
                        key={"cus" + i}
                        options={data}
                        value={selectedProduct}
                        onChange={handleSelectproduct}
                        placeholder="Select product"
                        noOptionsMessage={() => "No Product Found.."}

                      />
                    ))}
                </div> */}

                <div>
                  {/* <Multiselect options={options} displayValue="Country" /> */}
                  {/* {productoptions &&
                    productoptions.map((data, i) => ( */}
                  <Multiselect
                    options={productoptionsMulti[0]} // Options to display in the dropdown
                    selectedValues={productId} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name"// Property name to display in the dropdown options
                  />
                  {/* ))} */}
                </div>
              </div>
              {/* // {value: option.id === productId, label: data.productName } */}
              <div className="py-1">
                <Label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Is Home
                </Label>
                <div className='d-flex gap-3'>
                  <span>
                    <Input
                      type="radio"
                      name="isHome"
                      id="yes"
                      checked={isHome === true}
                      value="true"
                      onChange={e => { }}
                      onClick={() => handleisHome()}
                      style={{ transform: "scale(1.3)" }}
                    />&nbsp;
                    Yes
                  </span>
                  <span>
                    <Input
                      type="radio"
                      name="isHome"
                      id="no"
                      checked={isHome === false}
                      value="false"
                      onChange={e => { }}
                      onClick={() => handleisHome()}
                      style={{ transform: "scale(1.3)" }}
                    />&nbsp;
                    No
                  </span>
                </div>
              </div>

              <div className="py-1">
                <Label className=" col-form-label" >
                  Upload Product Images
                </Label>
                <div className="mt-1 ">
                  {
                    !pid ?
                      (
                        <>
                          <Input
                            type="file"
                            name="productImage"
                            id="productImage"
                            required
                            multiple
                            accept='image/*'
                            onChange={handleImage}
                          />
                          <div>
                            {
                              Array.from(imageFile).map((item, index) => {
                                return (
                                  <span>
                                    <img src={item ? URL.createObjectURL(item) : null} width={150} height={100} style={{ padding: '10px' }} />
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        color: "red",
                                        fontSize: "larger"
                                      }}
                                      onClick={() => { removeMultiImage(item) }}
                                    // onClick={() => setImageFile(imageFile.filter((e) => e !== item))}
                                    ></i>
                                  </span>
                                )
                              })
                            }
                          </div>
                        </>
                      )
                      : (
                        !image ? <>
                          <Input
                            type="file"
                            name="productImage"
                            id="productImage"
                            required
                            accept='image/*'
                            multiple
                            onChange={handleImage}
                          />
                          <div>
                            {
                              Array.from(imageFile).map((item, index) => {
                                return (
                                  <span>
                                    <img src={item ? URL.createObjectURL(item) : null} width={150} height={100} style={{ padding: '10px' }} />
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        color: "red",
                                        fontSize: "larger"
                                      }}
                                      onClick={() => { removeMultiImage(item) }}
                                    // onClick={() => setImageFile(imageFile.filter((e) => e !== item))}
                                    ></i>
                                  </span>
                                )
                              })
                            }
                          </div>
                        </> :
                          <>
                            <Input
                              type="file"
                              name="productImage"
                              id="productImage"
                              required
                              multiple
                              onChange={uploadFiles}
                            />
                            <div className='d-flex' style={{ gap: "1rem" }}>
                              <div className='row'>
                                {image && image.length > 0 && image.map((item) => {
                                  // { console.log(item) }
                                  return (
                                    <>
                                      <div className='col-md-4 position-relative col-6'>

                                        <ImageViewer path={item} style={{ width: "150px", height: "100px", padding: '10px', cursor: "pointer" }}></ImageViewer>
                                        <i
                                          className="fas fa-trash-alt"
                                          style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            // marginLeft: "0px",
                                            top: "15px",
                                            color: "red",
                                            fontSize: "larger"
                                          }}
                                          onClick={() => { removeProductimg(item) }}
                                        ></i>
                                      </div>

                                    </>
                                  )
                                })}
                              </div >
                            </div>
                          </>
                      )

                    // <div>
                    //   {
                    //     Array.from(image).map((item, index) => {
                    //       return (
                    //         <span>
                    //           <img src={item ? URL.createObjectURL(item) : null} width={150} height={100} style={{ padding: '10px' }} />
                    //           <i
                    //             className="fas fa-trash-alt"
                    //             style={{
                    //               position: "absolute",
                    //               cursor: "pointer",
                    //               marginLeft: "-25px",
                    //               marginTop: "8px",
                    //               color: "red",
                    //               fontSize: "larger"
                    //             }}
                    //           // onClick={() => { removeMultiImage(item) }}
                    //           // onClick={() => setImageFile(imageFile.filter((e) => e !== item))}
                    //           ></i>
                    //         </span>
                    //       )
                    //     })
                    //   }
                    // </div>
                  }

                  {/* {!pid ? (
                    <div>
                      <Input
                        type="file"
                        name="productImage"
                        id="productImage"
                        required
                        multiple
                        onChange={uploadMultipleFiles}
                      />
                      <div className="d-flex">
                        {multipleFile.map((file, key) => {
                          return (
                            <div key={key} className="mt-2" style={{ marginRight: "0.5rem" }}>
                              <i onClick={() => { removeImage(file) }} className="mdi mdi-close" style={{ position: "absolute", cursor: "pointer" }}></i>
                              <img alt="dummy" className="" src={file ? URL.createObjectURL(file) : dummy} style={{ width: "8rem", height: "5rem", cursor: 'pointer' }} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {image && image.length <= 0 ? <div>
                          <Input
                            type="file"
                            name="productImage"
                            id="productImage"
                            required
                            multiple
                            onChange={uploadMultipleFiles}
                          />
                          <div className="d-flex">
                            {multipleFile.map((file, key) => {
                              return (
                                <div key={key} className="mt-2" style={{ marginRight: "0.5rem" }}>
                                  <i onClick={() => { removeImage(file) }} className="mdi mdi-close" style={{ position: "absolute", cursor: "pointer" }}></i>
                                  <img alt="dummy" className="" src={file ? URL.createObjectURL(file) : dummy} style={{ width: "8rem", height: "5rem", cursor: 'pointer' }} />
                                </div>
                              )
                            })}
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger w-md ms-3 mt-3"
                              onClick={uploadFiles}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                          :
                          <div className='d-flex' style={{ gap: "1rem" }}>
                            {image && image.length > 0 && image.map((item) => {
                              // { console.log(item) }
                              return (
                                <>
                                  <ImageViewer path={item} style={{ width: "8rem", height: "7rem", cursor: "pointer" }}></ImageViewer>
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{
                                      // position: "absolute",
                                      cursor: "pointer",
                                      // marginLeft: "10px",
                                      marginTop: "8px",
                                      color: "red",
                                      fontSize: "larger"
                                    }}
                                    onClick={() => { removeProductimg(item) }}
                                  ></i>
                                </>
                              )
                            })}
                          </div>
                        }
                      </div>


                    </>
                  )} */}

                </div>

              </div>
            </Col>


            <div className="mt-4" style={{ textAlign: "center" }}>
              <button type="submit"
                className="btn btn-primary w-md"
                onClick={handleSubmit}>
                {pid ? "Update Product" : "Create Product"}
              </button>

              <button
                type="button"
                className="btn btn-danger w-md ms-3"
                onClick={pid ? handleCancle : handleClear}
              >
                {pid ? "Cancel" : "Clear"}
              </button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div >
  )
}
export default connect(null, { setBreadcrumbItems })(CreateProduct);