import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Label, Input } from "reactstrap"
import Swal from "sweetalert2";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addSubCategoryAPI, getSubCategoryByIdAPI, removeSubCategoryImageAPI, searchCategoryAPI, updateSubCategoryAPI, uploadSubCategoryImageAPI } from '../../service/service';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg";
import Select from "react-select"

const CreateSubCategory = (props) => {

    const { scid } = useParams();

    document.title = scid ? "Update Subcategory | Shayam Global" : "Create Subcategory | Shayam Global"
    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "SubCategory List", link: `/subCategoryList/${localStorage.getItem("categoryId")}` },
        { title: scid ? "Update Subcategory" : "Create Subcategory", link: "#" }
    ]
    const navigate = useNavigate();
    const [perentId, setPerentId] = useState();
    const [subCategoryName, setsubcategoryName] = useState();
    const [updateSubCategory, setUpdateSubCategory] = useState([]);
    const [filePath, setFilePath] = useState();
    const [image, setImage] = useState();
    const [category, setCategory] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);
    const [categoryId, setCategoryId] = useState([]);

    const handleValue = () => {
        if (scid) {
            setCategoryId(updateSubCategory?.categoryId)
            setsubcategoryName(updateSubCategory?.subCatData?.name);
            setImage(updateSubCategory.subCatData?.subCategoryImage)
        }
    }

    const handleDisplayCategoryUpdate = async (id) => {
        try {
            const res = await getSubCategoryByIdAPI(localStorage.getItem('categoryId'), id);
            console.log(res[0]);
            setUpdateSubCategory(res[0]);
        } catch (error) {
            console.log(error);
        }
    };

    const uploadCategoryImg = async e => {
        e.preventDefault()
        setFilePath(URL.createObjectURL(e.target.files[0]))
        const file = e.target.files[0]
        const res = await uploadSubCategoryImageAPI(localStorage.getItem('categoryId'), scid, file)
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
            setImage(res.data.categoryImage)
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }

    }

    const handleSubmitSubcategory = async e => {
        if (scid) {
            let subCatName = document.getElementById('subCategoryName').value;
            try {
                const res = await updateSubCategoryAPI(localStorage.getItem('categoryId'), scid, subCatName);

                if (res.status === '1' || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                    navigate(`/subCategoryList/${localStorage.getItem("categoryId")}`);
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                        timer: 1500
                    })
                }
            }
            catch (error) {
                console.log(error);
            }

        } else {
            // console.log(categoryId, subCategoryName);
            try {
                const res = await addSubCategoryAPI(categoryId, subCategoryName);
                console.log("subcategory res = ", res.status);
                if (res.status === '1' || res.status === 1) {

                    const resImg = await uploadSubCategoryImageAPI(categoryId, res.data[0].subId, image)
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    })
                    navigate(`/subCategoryList/${localStorage.getItem("categoryId")}`);
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })

                }
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const removeCategoryImg = async e => {
        try {
            const res = await removeSubCategoryImageAPI(localStorage.getItem("categoryId"), scid, image)
            if (res.status === "1" || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
                setImage("")
                setFilePath("")
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = async e => {
        setImage(e.target.files[0])
        setFilePath(URL.createObjectURL(e.target.files[0]))
        document.getElementById("categoryImage").value = null
    }

    const removeImage = async e => {
        setImage("")
        setFilePath("")
    }

    const getCategoryList = async () => {
        try {
            const response = await searchCategoryAPI()
            setCategory(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectCategory = e => {
        const value = e.value
        console.log(value);
        setCategoryId(value)
    }

    const productoptions = [
        category &&
        category.map(data => {
            return { value: data._id, label: data.categoryName }
        }),
    ]

    useEffect(() => {
        if (scid) {
            handleDisplayCategoryUpdate(scid)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        handleValue();
        //eslint-disable-next-line
    }, [updateSubCategory])

    useEffect(() => {
        getCategoryList();
        //eslint-disable-next-line
    }, [isRefresh])

    useEffect(() => {
        setPerentId()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        props.setBreadcrumbItems(scid ? "Update Subcategory" : "Create Subcategory", breadcrumbItems);
        //eslint-disable-next-line
    }, [window.location.href])

    return (
        <div>
            <Row>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>

                            <div className="py-2">
                                <Label className="col-form-label">
                                    Parent Category
                                </Label>
                                <div>
                                    <div>
                                        {productoptions &&
                                            productoptions.map((data, i) => (
                                                <Select key={"cus" + i} options={data}
                                                    onChange={handleSelectCategory}
                                                    placeholder="Select product"
                                                    value={data?.find(function (option) {
                                                        return option.value === categoryId;
                                                    })}
                                                />
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="py-2">
                                <Label> Sub Category Name</Label>
                                <div>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="subCategoryName"
                                        name="subCategoryName"
                                        placeholder="Enter Sub Category Name"
                                        value={subCategoryName}
                                        onChange={e => { setsubcategoryName(e.target.value) }}
                                        required
                                    />
                                </div>
                            </div>

                            {
                                !scid ?
                                    <div>
                                        <Label className="py-2">
                                            Upload Category Image
                                        </Label>
                                        <div>
                                            <Input
                                                type="file"
                                                name="categoryImage"
                                                id="categoryImage"
                                                required
                                                onChange={handleChange}
                                            />
                                            <img
                                                src={filePath ? filePath : dummy}
                                                alt="categoryImage"
                                                className='pt-2'
                                                style={{ width: "8rem", height: "7rem" }}
                                            />
                                            {!image ? "" :
                                                <i
                                                    className="fas fa-trash-alt"
                                                    style={{
                                                        position: "absolute",
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                        marginTop: "8px",
                                                        color: "red",
                                                        fontSize: "larger"
                                                    }}
                                                    onClick={removeImage}
                                                ></i>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="mt-2">
                                        <Label className=" col-form-label">
                                            Upload Category Image
                                        </Label>
                                        <div className="mt-1 ">
                                            {!image ? (
                                                <div>
                                                    <Input
                                                        type="file"
                                                        name="categoryImage"
                                                        id="categoryImage"
                                                        required
                                                        onChange={uploadCategoryImg}
                                                    />
                                                    <img
                                                        src={filePath ? filePath : dummy}
                                                        alt="categoryImage"
                                                        style={{ width: "8rem", height: "7rem" }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <ImageViewer
                                                        path={image}
                                                        style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                                                    >
                                                        {" "}
                                                    </ImageViewer>
                                                    <i
                                                        className="fas fa-trash-alt"
                                                        style={{
                                                            position: "absolute",
                                                            cursor: "pointer",
                                                            marginLeft: "10px",
                                                            marginTop: "8px",
                                                            color: "red",
                                                            fontSize: "larger"
                                                        }}
                                                        onClick={removeCategoryImg}
                                                    ></i>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            }

                            <div className="mt-4">
                                <button type="submit"
                                    className="btn btn-primary w-md" onClick={handleSubmitSubcategory}>
                                    {scid ? "Update Subcategory" : "Create Subcategory"}
                                </button>

                                <Link to={`/subCategoryList/${localStorage.getItem("categoryId")}`}
                                    type="button"
                                    className="btn btn-danger w-md ms-3"
                                >Cancel
                                </Link>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row >
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(CreateSubCategory);  
