import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, Input, Label } from "reactstrap"
import { getCartByIdAPI, getCustomerListAPI, searchCartAPI, searchCustomerAPI } from '../../service/service'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import Select from "react-select"

const CartList = (props) => {

    const [customer, setCustomer] = useState([]);
    const [page, pagechange] = useState(0);
    const [rowperpage, rowperpagechange] = useState(5);
    const [customerId, setCustomerId] = useState("");
    const [cartList, setCartList] = useState([]);

    document.title = "Cart List | Shayam Global";

    const breadcrumbItems = [
        { title: "Shyam Global", link: "/dashboard" },
        { title: "Cart List", link: "#" }
    ]

    const handlechangepage = (event, newpage) => {
        pagechange(newpage)
    }

    const handleRowsPerPage = (event) => {
        rowperpagechange(+event.target.value)
        pagechange(0);
    }

    const getCustomerList = async () => {
        try {
            const response = await searchCustomerAPI()
            setCustomer(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCartList = async () => {
        try {
            const response = await getCartByIdAPI(customerId)
            console.log("cart", response.data.products);
            setCartList(response.data.products)
        } catch (error) {
            console.log(error);
        }
    }

    const customeroptions = [
        customer &&
        customer.map(data => {
            return { value: data.id, label: data.fullName }
        }),
    ]

    const handleSelectCustomer = e => {
        const value = e.value
        console.log(value);
        setCustomerId(value)
    }

    const handleSearch = async e => {
        let value = e.target.value
        const res = await searchCartAPI(customerId, value)
        setCartList(res.data)
    }

    useEffect(() => {
        props.setBreadcrumbItems('Cart List', breadcrumbItems)
    },)

    useEffect(() => {
        getCustomerList();
    }, [])

    useEffect(() => {
        if (customerId) {
            getCartList();
        }
    }, [customerId])


    return (
        <>
            <Card style={{ height: customerId ? "" : "20rem" }}>
                <CardBody>
                    <div className="py-1">
                        <Label className="col-form-label">
                            Select customer
                        </Label>
                        <div>
                            {customeroptions &&
                                customeroptions.map((data, i) => (
                                    <Select key={"cus" + i} options={data} onChange={handleSelectCustomer} placeholder="Select Customer" />
                                ))}
                        </div>
                    </div>
                </CardBody>
            </Card>
            {
                customerId ? (
                    <>
                        <div className="d-flex justify-content-end position-relative">
                            <div className="input-group w-25 searchBox">
                                <span
                                    className="fa fa-search mb-3 input-group-text"
                                    id="basic-addon1"
                                    style={{ height: "auto" }}
                                ></span>
                                <Input
                                    type="search"
                                    className="mb-3 d-flex form-control"
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>

                        <Card>
                            <CardBody>
                                <TableContainer>
                                    <Table>
                                        <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                            <TableRow>
                                                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Product</TableCell>
                                                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cartList && cartList.map((cust, index) => (
                                                // console.log("lis = ", cust)
                                                <TableRow key={cust._id} className="tablerow" >
                                                    <TableCell >{cust.fullName}</TableCell>
                                                    <TableCell >{cust.qty}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    {/* <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    rowsPerPage={rowperpage}
                                    page={page}
                                    count={cartList.length > 0}
                                    component="div"
                                    onPageChange={handlechangepage}
                                    onRowsPerPageChange={handleRowsPerPage}
                                >
                                </TablePagination> */}
                                </TableContainer>
                            </CardBody>
                        </Card>
                    </>
                ) : ""
            }

        </>
    )
}

export default connect(null, { setBreadcrumbItems })(CartList);
