import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import user1 from "../../assets/images/user-1.jpg"
import Swal from "sweetalert2"
import ImageViewer from "../../imageViewer"



const CompanyProfile = props => {
  const re = /^[A-Za-z\s]+$/;
  const [comDetail, setComDetail] = useState([])
  const [brandName, setBrandName] = useState(
    comDetail.brandName ? comDetail.brandName : ""
  )
  const [lat, setlat] = useState(comDetail.lat ? comDetail.lat : "")
  const [long, setlong] = useState(comDetail.long ? comDetail.long : "")

  const [contactEmail, setcontactEmail] = useState(
    comDetail.contactEmail ? comDetail.contactEmail : ""
  )
  const [contactNumber, setcontactNumber] = useState(
    comDetail.contactNumber ? comDetail.contactNumber : ""
  )
  const [facebookLink, setfacebookLink] = useState(
    comDetail.facebookLink ? comDetail.facebookLink : ""
  )
  const [googlePlusLink, setgooglePlusLink] = useState(
    comDetail.googlePlusLink ? comDetail.googlePlusLink : ""
  )
  const [instagramLink, setinstagramLink] = useState(
    comDetail.instagramLink ? comDetail.instagramLink : ""
  )
  const [officeAddress, setofficeAddress] = useState(
    comDetail.officeAddress ? comDetail.officeAddress : ""
  )
  const [pinterestLink, setpinterestLink] = useState(
    comDetail.pinterestLink ? comDetail.pinterestLink : ""
  )
  const [salesEmail, setsalesEmail] = useState(
    comDetail.salesEmail ? comDetail.salesEmail : ""
  )
  const [supportEmail, setsupportEmail] = useState(
    comDetail.supportEmail ? comDetail.supportEmail : ""
  )
  const [twitterLink, settwitterLink] = useState(
    comDetail.twitterLink ? comDetail.twitterLink : ""
  )
  const [youtubeLink, setyoutubeLink] = useState(
    comDetail.youtubeLink ? comDetail.youtubeLink : ""
  )
  const [linkedinLink, setlinkedinLink] = useState(
    comDetail.linkedinLink ? comDetail.linkedinLink : ""
  )
  const [brandLogo, setbrandLogo] = useState(
    comDetail.brandLogo ? comDetail.brandLogo : ""
  )

  document.title = "Company Profile | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Shayam Global", link: "/dashboard" },
    { title: "Company Profile", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Company Profile", breadcrumbItems)
  })




  return (

    <div className="mt-3">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <CardTitle className="fs-4 pb-3">
                        <b>Company Basic Details</b>
                      </CardTitle>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Company Name
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            name="brandName"
                            id="brandName"
                            required
                            value={brandName}
                            // onChange={handleChangeName}
                            onChange={e => {
                              setBrandName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className=" col-form-label fw-bold">
                          Office Address
                        </Label>
                        <div>
                          <textarea
                            className="form-control"
                            type="text"
                            rows={4}
                            name="officeAddress"
                            required
                            value={officeAddress}
                            onChange={e => {
                              setofficeAddress(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Latitude
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="number"
                            name="lat"
                            required
                            value={lat}
                            onChange={e => {
                              setlat(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Longitude
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="number"
                            name="long"
                            required
                            value={long}
                            onChange={e => {
                              setlong(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Company Logo
                        </Label>
                        <div>
                          {!brandLogo ? (
                            <div>
                              <Input
                                type="file"
                                name="doc"
                                id="doc"
                                required

                              />
                              <ImageViewer
                                path={user1}
                                style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                              >
                                {" "}
                              </ImageViewer>
                            </div>
                          ) : (
                            <div className="mt-1 ">
                              <ImageViewer

                                path={brandLogo}
                                style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                              >
                                {" "}
                              </ImageViewer>
                              <i
                                className="fas fa-trash-alt"
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}

                              ></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <CardTitle className="fs-4 pb-3">
                        <b>Company Contact Details</b>
                      </CardTitle>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Contact Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="contactEmail"
                            required
                            value={contactEmail}
                            onChange={e => {
                              setcontactEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Support Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="supportEmail"
                            required
                            value={supportEmail}
                            onChange={e => {
                              setsupportEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Sales Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="salesEmail"
                            required
                            value={salesEmail}
                            onChange={e => {
                              setsalesEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Contact Number
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            name="contactNumber"
                            value={contactNumber}
                            onChange={e => {
                              setcontactNumber(e.target.value)
                            }}
                            // onChange={handleNumChange}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <div>
                      <Button
                        type="button"
                        className="btn btn-primary w-md mx-2"
                      // onClick={updateCompanyDetail}
                      >
                        Update
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>

  )
}

export default connect(null, { setBreadcrumbItems })(CompanyProfile)
