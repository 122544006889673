import React, { useEffect, useState } from 'react'
import { Card, CardBody, Input } from "reactstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { getAllStaffAPI, countStaffAPI, removeStaffAPI, activeStaffByIdAPI, deactiveStaffByIdAPI } from '../../service/service'
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const StaffList = (props) => {

  const history = useNavigate()
  document.title = "Staff List | Shayam Global";

  const breadcrumbItems = [
    { title: "Shyam Global", link: "/dashboard" },
    { title: "Staff List", link: "#" }
  ]
  useEffect(() => {
    props.setBreadcrumbItems('Staff List', breadcrumbItems)
  },)

  const [staff, setStaff] = useState([]);
  // const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [count, setStaffCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false)

  const getStaffList = async () => {
    try {
      const response = await getAllStaffAPI(rowperpage, pageNo)
      setStaff(response.data)

    } catch (error) {
      console.log(error);
    }
  }
  const getStaffCount = async () => {
    try {
      const response = await countStaffAPI()
      setStaffCount(response.data);

    } catch (error) {
      console.log(error);
    }
  }

  const handleViewStaff = async i => {
    try {
      history(`/staffDetails/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateStaff = async i => {
    try {
      history(`/updateStaff/${i}`)
    } catch (error) {
      console.log(error)
    }
  }


  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }
  const handleActiveStaff = async i => {
    try {
      const res = await activeStaffByIdAPI(i)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeactiveStaff = async i => {
    try {
      const res = await deactiveStaffByIdAPI(i)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeStaff = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {

      if (result.isConfirmed) {
        const res = await removeStaffAPI(id);
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          });
          setIsRefresh(!isRefresh);
        }
      }
    })
    // try {
    //   const res = await removeStaffAPI(id)
    //   if (res.status === "1" || res.status === 1) {
    //     setIsRefresh(true);
    //     Swal.fire({
    //       title: res.message,
    //       icon: "success",
    //       timer: 1500,
    //     })
    //   } else {
    //     Swal.fire({
    //       title: res.message,
    //       icon: "error",
    //     })
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }


  useEffect(() => {
    getStaffList();
  }, [isRefresh, rowperpage, pageNo])


  useEffect(() => {
    async function getCounts() {
      await getStaffCount();
    }
    getCounts();
  }, [isRefresh])

  return (
    <div>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
          // onChange={handleSearch}

          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>First Name</TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Last Name</TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Email</TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Mobile No</TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Status</TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff && staff.map((sta, index) => (
                  <TableRow key={sta._id} className="tablerow" >
                    <TableCell onClick={() => handleViewStaff(sta._id)}>{sta.firstName}</TableCell>
                    <TableCell onClick={() => handleViewStaff(sta._id)}>{sta.lastName}</TableCell>
                    <TableCell onClick={() => handleViewStaff(sta._id)}>{sta.email}</TableCell>
                    <TableCell onClick={() => handleViewStaff(sta._id)}>{sta.mobile}</TableCell>
                    <TableCell>
                      {sta.status ? (
                        <div
                          className="active-btn"
                          onClick={() => handleDeactiveStaff(sta._id)}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className="deactive-btn"
                          onClick={() => handleActiveStaff(sta._id)}
                        >
                          Inactive
                        </div>
                      )}</TableCell>
                    <TableCell>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                      }}>
                        <div
                          className="btn btn-primary"
                          onClick={() => handleUpdateStaff(sta._id)}
                        >
                          Edit
                        </div>
                        <div>
                          <button className="btn btn-danger" onClick={() => removeStaff(sta._id)}>Delete</button>
                        </div>
                      </div>


                    </TableCell>
                  </TableRow>

                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination>

          </TableContainer>
        </CardBody>
      </Card>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(StaffList); 
