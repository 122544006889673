import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Label, Input } from "reactstrap"
import Swal from "sweetalert2";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { createCategoryAPI, getCategoryByIdAPI, removeCategoryImageAPI, updateCategoryAPI, uploadCategoryImageAPI, getCategoryListAPI, searchCategoryAPI, addSubCategoryAPI } from '../../service/service';
import dummy from "../../assets/images/dummy.jpg"
import { useNavigate, useParams } from 'react-router-dom';
import ImageViewer from '../../imageViewer';
// import { Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
// import Select from "react-select"

const CreateCategory = (props) => {
  const { cid } = useParams()
  // const { scid } = useParams()
  // const smallScreen = useMediaQuery("(max-width: 600px)")
  // const [value, setValue] = useState(0);

  document.title = cid ? "Update Category | Shayam Global" : "Create Category | Shayam Global"

  const breadcrumbItems = [
    { title: "Shayam Global", link: "/dashboard" },
    { title: cid ? "Update Category" : "Create Category", link: "#" }
  ]

  const navigate = useNavigate();
  // const [subCategoryName, setsubcategoryName] = useState();
  const [categoryName, setcategoryName] = useState();
  const [updateCategory, setUpdateCategory] = useState([]);
  const [filePath, setFilePath] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState([]);

  // const [categoryId, setCategoryId] = useState([]);

  const handleValue = () => {
    if (cid) {
      setcategoryName(updateCategory.categoryName);
      setImage(updateCategory.categoryImage)
    }
  }

  const getCategoryList = async () => {
    try {
      const response = await searchCategoryAPI()
      setCategory(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async e => {
    if (cid) {
      let categoryName = document.getElementById('categoryName').value;
      try {
        const res = await updateCategoryAPI(cid, categoryName);

        if (res.status === '1' || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          navigate("/categoryList");
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
            timer: 1500
          })
        }
      }
      catch (error) {
        console.log(error);
      }

    } else {
      try {
        const res = await createCategoryAPI(categoryName);
        if (res.status === '1' || res.status === 1) {
          await uploadCategoryImageAPI(res.data.id, image)
          Swal.fire({
            title: res.message,
            icon: "success",
          })
          navigate("/categoryList");
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })

        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  };


  const uploadCategoryImg = async e => {
    e.preventDefault()
    setFilePath(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const res = await uploadCategoryImageAPI(cid, file)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      setImage(res.data.categoryImage)
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }

  }

  const handleDisplayCategoryUpdate = async (id) => {
    try {
      const res = await getCategoryByIdAPI(id);
      setUpdateCategory(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const removeCategoryImg = async e => {
    try {
      const res = await removeCategoryImageAPI(cid, image)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        setImage("")
        setFilePath("")
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = async e => {
    setImage(e.target.files[0])
    setFilePath(URL.createObjectURL(e.target.files[0]))
    document.getElementById("categoryImage").value = null
  }

  const removeImage = async e => {
    setImage("")
    setFilePath("")
  }

  const handleClear = async () => {
    setcategoryName("");
    setFilePath("");
    setImage("");
  }

  const handleCancel = async () => {
    navigate("/categoryList")
  }

  useEffect(() => {
    if (cid) {
      handleDisplayCategoryUpdate(cid)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleValue();
    //eslint-disable-next-line
  }, [updateCategory])

  useEffect(() => {
    props.setBreadcrumbItems(cid ? "Update Category" : "Create Category", breadcrumbItems);
    //eslint-disable-next-line
  }, [window.location.href])

  useEffect(() => {
    handleClear();
    //eslint-disable-next-line
  }, [window.location.href])

  useEffect(() => {
    getCategoryList();
    //eslint-disable-next-line
  }, [])

  return (

    <div>
      <Row>
        <Col xl={6} lg={8}>
          <Card>
            <CardBody>
              {/* <CardTitle>Create Customer</CardTitle> */}
              <div className="py-2">
                <Label className="col-form-label"> Category Name </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="categoryName"
                    name="categoryName"
                    placeholder="Enter Name"
                    value={categoryName}
                    onChange={e => { setcategoryName(e.target.value) }}
                    required
                  />
                </div>
              </div>

              {
                !cid ?
                  <div className='py-2'>
                    <Label className="col-form-label">
                      Upload Category Image
                    </Label>
                    <div>
                      <Input
                        type="file"
                        name="categoryImage"
                        id="categoryImage"
                        required
                        accept='image/*'
                        onChange={handleChange}
                      />
                      <img
                        src={filePath ? filePath : dummy}
                        alt="categoryImage"
                        className='pt-2'
                        style={{ width: "8rem", height: "7rem" }}
                      />
                      {!image ? "" :
                        <i
                          className="fas fa-trash-alt"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            marginLeft: "10px",
                            marginTop: "8px",
                            color: "red",
                            fontSize: "larger"
                          }}
                          onClick={removeImage}
                        ></i>
                      }
                    </div>
                  </div> : <div className="py-2">
                    <Label
                     
                      className=" col-form-label"
                    >
                      Upload Category Image
                    </Label>
                    <div className="mt-1 ">
                      {!image ? (
                        <div>
                          <Input
                            type="file"
                            name="categoryImage"
                            id="categoryImage"
                            required
                            accept='image/*'
                            onChange={uploadCategoryImg}
                          />
                          <img
                            src={filePath ? filePath : dummy}
                            alt="categoryImage"
                            style={{ width: "8rem", height: "7rem" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <ImageViewer
                            path={image}
                            style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removeCategoryImg}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
              }

              <div className="mt-4">
                <button type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit}>
                  {cid ? "Update Category" : "Create Category"}
                </button>

                <button
                  type="button"
                  className="btn btn-danger w-md ms-3"
                  onClick={cid ? handleCancel : handleClear}
                >
                  {cid ? "Cancel" : "Clear"}
                </button>
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row >

    </div >
  )
}

export default connect(null, { setBreadcrumbItems })(CreateCategory); 