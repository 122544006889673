import React, { useEffect, useState } from 'react'
import { Table, Card, CardBody, CardTitle } from "reactstrap"
import { getProductListAPI, getTopSellingProductsAPI } from '../../service/service';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ProductList = () => {

  const [product, setProduct] = useState("");

  const getCustomerList = async () => {
    let temp = []
    try {
      // const response = await getProductListAPI(5, 1)
      const response = await getTopSellingProductsAPI()
      response?.data && response?.data.map((data) => {
        if (data?.productData !== null) {
          temp.push(data.productData);
        }
        setProduct(temp)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCustomerList();
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <h4 className="card-title mb-4"></h4> */}
          <CardTitle className="text-center fs-4">
            <b>Top Selling Product List</b>{" "}
          </CardTitle>
          <hr />

          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className="fw-bold text-black">
                  <TableCell>Product Category</TableCell>
                  <TableCell>Product Sub Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Product Code</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product && product.map((prod, index) => (
                  <TableRow key={prod._id} className="tablerow text-black" >
                    <TableCell>{prod.productCategory}</TableCell>
                    <TableCell>{prod.productCategory}</TableCell>
                    <TableCell >{prod.productName}</TableCell>
                    <TableCell >{prod.productCode}</TableCell>
                    <TableCell >{"₹ " + Intl.NumberFormat('en-IN').format(prod.price)}</TableCell>
                    <TableCell >{prod.qty[0] + " " + prod.qty[1]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={page}
              count={customer.length}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination> */}
          </TableContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProductList