import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, Input } from "reactstrap"
import { activeCustomerrAPI, customerCountAPI, deactiveCustomerrAPI, getCustomerListAPI, removeCustomerByIdAPI, searchCustomerAPI, updateCustomerStatusByIdAPI } from '../../service/service'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CustomerList = (props) => {

  const history = useNavigate()
  document.title = "Customer List | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "#" },
    { title: "Customer List", link: "#" }
  ]

  const [customer, setCustomer] = useState([]);
  // const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCustomerCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false)

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }

  const getCustomerList = async () => {
    try {
      const response = await getCustomerListAPI(rowperpage, pageNo)
      setCustomer(response.data)

    } catch (error) {
      console.log(error);
    }
  }

  const getCustomerCount = async () => {
    try {
      const response = await customerCountAPI()
      setCustomerCount(response.data);

    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdate = index => {
    try {
      history(`/updateCustomer/${index}`)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchCustomerAPI(value)
    setCustomer(res.data)
  }

  // const handleActiveCustomer = async i => {
  //   try {
  //     const res = await activeCustomerrAPI(i)
  //     setIsRefresh(!isRefresh)
  //     if (res.status === "1" || res.status === 1) {
  //       Swal.fire({
  //         title: res.message,
  //         icon: "success",
  //         timer: 1500
  //       })
  //     } else {
  //       Swal.fire({
  //         title: res.message,
  //         icon: "error",
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleDeactiveCustomer = async i => {
  //   try {
  //     const res = await deactiveCustomerrAPI(i)
  //     setIsRefresh(!isRefresh)
  //     if (res.status === "1" || res.status === 1) {
  //       Swal.fire({
  //         title: res.message,
  //         icon: "success",
  //         timer: 1500
  //       })
  //     } else {
  //       Swal.fire({
  //         title: res.message,
  //         icon: "error",
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleViewCustomer = async i => {
    try {
      history(`/customerDetails/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatus = async (e) => {
    // console.log("value",e.target.value);
    let status = e.target.value
    const index = e.target.selectedIndex;
    const el = e.target[index];
    console.log("status =", status);
    console.log("index =", index);
    console.log("el = ", el);

    try {
      const response = await updateCustomerStatusByIdAPI(el.id, status)
      setIsRefresh(!isRefresh)
      if (response.status === "1" || response.status === 1) {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }


  const removeCustomer = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        removeCustomerByIdAPI(id)
        Swal.fire({
          title: "Customer Removed successfully",
          icon: "success",
          timer: 1500
        })
      }
      setIsRefresh(!isRefresh)
    })
  }

  useEffect(() => {
    getCustomerList();
  }, [isRefresh, rowperpage, pageNo])


  useEffect(() => {
    async function getCounts() {
      await getCustomerCount();
    }
    getCounts();
  }, [isRefresh])

  useEffect(() => {
    props.setBreadcrumbItems('Customer List', breadcrumbItems)
  },)

  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className='tableHeadlabel'>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>GST No</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer && customer.map((cust, index) => (
                  <TableRow key={cust._id} className="tablerow" >
                    <TableCell onClick={() => handleViewCustomer(cust._id)}>{cust.fullName}</TableCell>
                    <TableCell onClick={() => handleViewCustomer(cust._id)}>{cust.email}</TableCell>
                    <TableCell onClick={() => handleViewCustomer(cust._id)}>{cust.gst}</TableCell>
                    <TableCell>{/* <select onClick={() => handleChangeStatus(cust._id)}> */}
                      {/* <select onChange={handleChangeStatus} style={{ borderRadius: "5px", backgroundColor: "rgb(90 94 102)", color: "white" }}> */}
                      <select onChange={handleChangeStatus} style={{ borderRadius: "5px", padding: "5px", background: cust?.status === "Active" ? "green" : cust?.status === "Pending Activation" ? "#936f2f" : cust?.status === "In Active" ? "#fb6262" : cust?.status === "Deactivated" ? "rgb(219 15 15)" : "green", color: "white" }}>
                        {cust?.status}
                        <option
                          id={cust?.id}
                          selected={
                            cust?.status === "Active"
                              ? true
                              : false
                          }
                          value="Active"
                          style={{ background: "white", color: "black" }}
                        >
                          Active
                        </option>
                        <option
                          id={cust?.id}
                          selected={
                            cust?.status === "In Active"
                              ? true
                              : false
                          }
                          value="In Active"
                        >
                          In Active
                        </option>
                        <option
                          id={cust?.id}
                          selected={
                            cust?.status === "Pending Activation"
                              ? true
                              : false
                          }
                          value="Pending Activation"
                        >
                          Pending Activation
                        </option>
                        <option
                          id={cust?.id}
                          selected={
                            cust?.status === "Deactivated"
                              ? true
                              : false
                          }
                          value="Deactivated"
                        >
                          Deactivated
                        </option>
                      </select>
                    </TableCell>

                    <TableCell>
                      {/* <div
                        className="edit-btn"
                        onClick={() => handleUpdate(cust._id)}
                      >
                        Edit
                      </div> */}
                      <div style={{ display: 'flex', gap: '5px' }}>

                        <button className="btn btn-primary"
                          onClick={() => handleUpdate(cust._id)}>Edit</button>

                        <button className="btn btn-danger" onClick={() => removeCustomer(cust._id)}>Delete</button>
                      </div>
                    </TableCell>
                  </TableRow>

                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination>
          </TableContainer>
        </CardBody>
      </Card >
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerList);
