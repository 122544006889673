import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import withRouter from "./Common/withRouter"

// users
import user1 from "../assets/images/user-1.jpg"
import { displayRoleToRightsAPI, getUserByIdAPI } from "../service/service"
import { setProfile, setUserRights } from "../store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState({})

  // const mainurl = "http://143.110.252.47:3001/";
  const mainurl = "https://app.ssglobal.co.in:443/";

  const handleDisplayUserDetail = async () => {
    try {
      const res = await getUserByIdAPI()
      setUserDetail(res?.data)
      dispatch(setProfile(res?.data))

      if (res?.data) {
        const resUserRights = await displayRoleToRightsAPI(res.data.userRoleId);
        dispatch(setUserRights(resUserRights))
      }
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    handleDisplayUserDetail();
    //eslint-disable-next-line
  }, [])

  const userRights = useSelector(state => state.Profile.userRights)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            crossOrigin="anonymous"
            src={
              userDetail && userDetail.avatar
                ? mainurl + userDetail.avatar
                : user1
            }
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link to="/userProfile" style={{ color: "black" }}>
              {" "}
              <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
              {props.t("Profile")}{" "}
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/changePassword" style={{ color: "black" }}>
              <i className="mdi mdi-wallet font-size-17 text-muted align-middle me-1" />
              {props.t("Change Password")}
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/companyProfile" style={{ color: "black" }}>
              <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
              {props.t("Company Profile")}
            </Link>
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)