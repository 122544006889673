import React, { useEffect, useState } from "react"
import { connect } from "react-redux";

// Pages Components
import Miniwidget from "./Miniwidget"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import CustomerList from "./CustomerList";
import ProductList from "./ProductList";
import { customerCountAPI, orderCountAPI, productCountAPI, todayOrderCountAPI } from "../../service/service";
import OrderList from "./OrderList";
import PendingOrderList from "./PendingOrderList";

const Dashboard = (props) => {

  document.title = "Dashboard | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems)
  },)
  const [customerCount, setCustomerCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [todayOrder, setTodayOrder] = useState(0);

  const getCustomerCount = async () => {
    try {
      const response = await customerCountAPI()
      setCustomerCount(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getProductCount = async () => {
    try {
      const response = await productCountAPI()
      setProductCount(response?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getOrderCount = async () => {
    try {
      const response = await orderCountAPI()
      setOrderCount(response?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getTodayOrderCount = async () => {
    try {
      const response = await todayOrderCountAPI()
      setTodayOrder(response?.data?.length);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getCounts() {
      await getOrderCount();
      await getProductCount();
      await getCustomerCount();
      await getTodayOrderCount();
    }
    getCounts();
  }, [])
  const reports = [
    { title: "Today order", iconClass: "briefcase-check", total: todayOrder ? todayOrder : 0 },
    { title: "Orders", iconClass: "briefcase-check", total: orderCount ? orderCount : 0 },
    { title: "Products", iconClass: "package-variant", total: productCount ? productCount : 0 },
    { title: "Customers", iconClass: "account", total: customerCount },
  ]

  return (
    <React.Fragment>

      {/*mimi widgets */}
      <Miniwidget reports={reports} />
      {/* <SliderGallery/> */}
      <CustomerList />
      <PendingOrderList />
      <ProductList />
      {/* <OrderList /> */}

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);