import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import ImageViewer from '../../imageViewer';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { addSubSubCategoryAPI, getCategoryByIdAPI, getSubSubCategoryByIdAPI, removeSubSubCategoryImageAPI, searchCategoryAPI, updateSubSubCategoryAPI, uploadSubCategoryImageAPI, uploadSubSubCategoryImageAPI } from '../../service/service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dummy from "../../assets/images/dummy.jpg";
import Select from "react-select";
import Swal from "sweetalert2";

const InnerSubCategory = (props) => {
    const { isid } = useParams();

    document.title = isid ? "Update Inner Subcategory | Shayam Global" : "Create Inner Subcategory  | Shayam Global"

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Inner SubCategory List", link: `/innerSubCategoryList/${localStorage.getItem("subCategoryId")}` },
        { title: isid ? "Update Inner Subcategory" : "Create Inner Subcategory", link: "#" }
    ]

    const navigate = useNavigate();
    const [filePath, setFilePath] = useState();
    const [image, setImage] = useState();
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [isRefresh, SetIsRefresh] = useState(false);
    const [categoryId, setCategoryId] = useState([]);
    const [subcategoryId, setsubCategoryId] = useState();
    const [subSubCategory, setSubSubCategory] = useState("");

    // const [subCategoryName, setsubcategoryName] = useState();
    const [updateSubCategory, setUpdateSubCategory] = useState([]);

    const handleSubmitSubcategory = async e => {
        if (isid) {
            let subSubCategory = document.getElementById('subSubCategory').value;
            try {
                const res = await updateSubSubCategoryAPI(categoryId, subcategoryId, isid, subSubCategory);
                if (res.status === '1' || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                    navigate(`/innerSubCategoryList/${localStorage.getItem("subCategoryId")}`);
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                        timer: 1500
                    })
                }
            }
            catch (error) {
                console.log(error);
            }

        } else {
            try {
                const res = await addSubSubCategoryAPI(categoryId, subcategoryId, subSubCategory);
                if (res.status === '1' || res.status === 1) {

                    await uploadSubSubCategoryImageAPI(categoryId, subcategoryId, res.data[0].subSubId, image)
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    })
                    navigate(`/innerSubCategoryList/${localStorage.getItem("subCategoryId")}`);
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })

                }
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const uploadCategoryImg = async e => {
        e.preventDefault()

        setFilePath(URL.createObjectURL(e.target.files[0]));
        const file = e.target.files[0]
        const res = await uploadSubSubCategoryImageAPI(categoryId, subcategoryId, isid, file)
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
            setImage(res.data.categoryImage)
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }

    }

    const removeCategoryImg = async e => {
        try {
            const res = await removeSubSubCategoryImageAPI(categoryId, subcategoryId, isid, image)
            if (res.status === "1" || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
                setImage("")
                setFilePath("")
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getCategoryList = async () => {
        try {
            const response = await searchCategoryAPI()
            setCategory(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getsubCategoryList = async () => {
        try {
            const response = await getCategoryByIdAPI(categoryId);
            setSubCategory(response.data.subCategory)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectCategory = e => {
        const value = e.value
        setCategoryId(value)
    }

    const handleSelectSubCategory = e => {
        const value = e.value
        setsubCategoryId(value)
    }

    const productoptions = [
        category &&
        category.map(data => {
            return { value: data._id, label: data.categoryName }
        }),
    ]
    const subCategoryOptions = [

        subCategory &&
        subCategory.map(data => {
            return { value: data.id, label: data.name }
        }),
    ]

    const handleValue = () => {
        if (isid) {
            setCategoryId(updateSubCategory?.categoryId);
            setsubCategoryId(updateSubCategory?.subCategoryId);
            setSubSubCategory(updateSubCategory?.subSubCatData?.name);
            setImage(updateSubCategory?.subSubCatData?.image);
        }
    }

    const handleDisplayInnerCategoryUpdate = async (id) => {
        try {
            const res = await getSubSubCategoryByIdAPI(localStorage.getItem('categoryId'), localStorage.getItem('subCategoryId'), id);
            console.log(res[0]);
            setUpdateSubCategory(res[0]);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = async e => {
        setImage(e.target.files[0])
        setFilePath(URL.createObjectURL(e.target.files[0]))
        document.getElementById("categoryImage").value = null
    }
    const removeImage = async e => {
        setImage("")
        setFilePath("")
    }

    useEffect(() => {
        if (isid) {
            handleDisplayInnerCategoryUpdate(isid)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        handleValue();
        //eslint-disable-next-line
    }, [updateSubCategory])

    // console.log(subCategory);
    useEffect(() => {
        getCategoryList();
        //eslint-disable-next-line
    }, [isRefresh])

    useEffect(() => {
        getsubCategoryList();
        //eslint-disable-next-line
    }, [categoryId, isid])

    useEffect(() => {
        props.setBreadcrumbItems(isid ? "Update Inner Subcategory" : "Create Inner Subcategory", breadcrumbItems);
        //eslint-disable-next-line
    }, [window.location.href])

    return (
        <div>
            <Row>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>

                            <div className="py-1">
                                <Label className="col-form-label">
                                    Parent Category
                                </Label>
                                <div>
                                    <div>
                                        {productoptions &&
                                            productoptions.map((data, i) => (
                                                <Select key={"cus" + i} options={data}
                                                    onChange={handleSelectCategory}
                                                    placeholder="Select Category"
                                                    value={data?.find(function (option) {
                                                        return option.value === categoryId;
                                                    })}
                                                />
                                            ))}
                                    </div>

                                </div>
                            </div>

                            <div className="py-1">
                                <Label className="col-form-label">
                                    Sub Category
                                </Label>
                                <div>
                                    <div>
                                        {subCategoryOptions &&
                                            subCategoryOptions.map((data, i) => (
                                                <Select key={"Innercus" + i}
                                                    options={data}
                                                    onChange={handleSelectSubCategory}
                                                    placeholder="Select Sub Category"
                                                    noOptionsMessage={() => "No SubCategory Found.."}
                                                    value={data?.find(function (option) {
                                                        return option.value === subcategoryId;
                                                    })}
                                                />
                                            ))}
                                    </div>

                                </div>
                            </div>

                            <div className="py-1">
                                <Label className="col-form-label"> Inner Category Name</Label>
                                <div>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="subSubCategory"
                                        name="subSubCategory"
                                        placeholder="Enter Sub Category Name"
                                        value={subSubCategory}
                                        onChange={e => { setSubSubCategory(e.target.value) }}
                                        required
                                    />
                                </div>
                            </div>

                            {
                                !isid ?
                                    <div className="py-1">
                                        <Label className="col-form-label">
                                            Upload Image
                                        </Label>
                                        <div>
                                            <Input
                                                type="file"
                                                name="categoryImage"
                                                id="categoryImage"
                                                required
                                                accept='image/*'
                                                onChange={handleChange}
                                            />
                                            <img
                                                src={filePath ? filePath : dummy}
                                                alt="categoryImage"
                                                className='pt-2'
                                                style={{ width: "8rem", height: "7rem" }}
                                            />
                                            {!image ? "" :
                                                <i
                                                    className="fas fa-trash-alt"
                                                    style={{
                                                        position: "absolute",
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                        marginTop: "8px",
                                                        color: "red",
                                                        fontSize: "larger"
                                                    }}
                                                    onClick={removeImage}
                                                ></i>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="mt-2">
                                        <Label className=" col-form-label">
                                            Upload Category Image
                                        </Label>
                                        <div className="mt-1 ">
                                            {!image ? (
                                                <div>
                                                    <Input
                                                        type="file"
                                                        name="categoryImage"
                                                        id="categoryImage"
                                                        required
                                                        accept='image/*'
                                                        onChange={uploadCategoryImg}
                                                    />
                                                    <img
                                                        src={filePath ? filePath : dummy}
                                                        alt="categoryImage"
                                                        style={{ width: "8rem", height: "7rem" }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <ImageViewer
                                                        path={image}
                                                        style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                                                    >
                                                        {" "}
                                                    </ImageViewer>
                                                    <i
                                                        className="fas fa-trash-alt"
                                                        style={{
                                                            position: "absolute",
                                                            cursor: "pointer",
                                                            marginLeft: "10px",
                                                            marginTop: "8px",
                                                            color: "red",
                                                            fontSize: "larger"
                                                        }}
                                                        onClick={removeCategoryImg}
                                                    ></i>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            }

                            <div className="mt-4">
                                <button type="submit"
                                    className="btn btn-primary w-md"
                                    onClick={handleSubmitSubcategory}
                                >
                                    {isid ? "Update Inner Subcategory" : "Create Inner Subcategory"}
                                </button>

                                <Link to={`/innerSubCategoryList/${localStorage.getItem("subCategoryId")}`}
                                    type="button"
                                    className="btn btn-danger w-md ms-3"
                                >Cancel
                                </Link>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row >
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(InnerSubCategory);   
