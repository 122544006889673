import React, { Suspense, useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap"

import user1 from "../../assets/images/user-1.jpg"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"
import { getUserByIdAPI, removeUserImageAPI, uploadUserImageAPI } from "../../service/service"
import ImageViewer from "../../imageViewer"

const UserProfile = (props) => {
    const [userDetail, setUserDetail] = useState([])
    const [filepath, setFilepath] = useState("")
    const [isRefreash, setIsRefreash] = useState(false)
    const [fileView, setFileView] = useState("")
    const profileData = useSelector(state => state.Profile.profileData)

    document.title = "User Profile | Shreesh Securities"
    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "User Profile", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems("User Profile", breadcrumbItems)
        //eslint-disable-next-line
    }, [])
    // const mainurl = "http://64.227.143.181:3000/"

    const setfile = async e => {
        setFileView(e.target.files[0])
        setFilepath(URL.createObjectURL(e.target.files[0]))
        document.getElementById("avatar").value = null
    }

    const uploadUserImage = async e => {
        const userId = userDetail._id
        const res = await uploadUserImageAPI(userId, fileView)
        setIsRefreash(true);
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
    }

    const removeImage = async () => {
        const userId = userDetail._id
        const imagePath = userDetail.avatar
        const res = await removeUserImageAPI(userId, imagePath)
        setIsRefreash(true);
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
            setFileView("");
            setFilepath("");
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
    }

    const handleDisplayUserDetail = async () => {
        try {
            const res = await getUserByIdAPI()
            setUserDetail(res?.data)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleDisplayUserDetail();
    }, [isRefreash, profileData])

    return (
        <>
            <div className="mt-3">
                <Row>
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                                <CardTitle></CardTitle>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label className="col-form-label fw-bold">
                                                First Name
                                            </Label>
                                            <div>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    disabled
                                                    defaultValue={userDetail?.firstName}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label className="col-form-label fw-bold">
                                                Last Name
                                            </Label>
                                            <div>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    name="lastName"
                                                    disabled
                                                    defaultValue={userDetail?.lastName}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mb-3">
                                    <Label className="col-form-label fw-bold">User Name</Label>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="username"
                                            disabled
                                            defaultValue={userDetail?.username}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label className="col-form-label fw-bold">Email</Label>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            disabled
                                            defaultValue={userDetail?.email}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label className="col-form-label fw-bold">Mobile-No</Label>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            name="mobile"
                                            disabled
                                            defaultValue={userDetail?.mobile}
                                        />
                                    </div>
                                </div>
                                <div className="py-1">
                                    <Label
                                        htmlFor="example-text-input"
                                        className=" col-form-label"
                                    >
                                        Profile Image
                                    </Label>

                                    {userDetail.avatar ? (
                                        <div className="mt-1" style={{ position: "relative" }}>
                                            <ImageViewer
                                                path={userDetail?.avatar}
                                                style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                            <i
                                                className="fas fa-trash-alt"
                                                style={{
                                                    position: "absolute",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                }}
                                                onClick={() => removeImage()}
                                            ></i>
                                        </div>
                                    ) : (
                                        <div>
                                            <Input
                                                type="file"
                                                name="file"
                                                id="avatar"
                                                required
                                                onChange={setfile}
                                            />
                                            <img
                                                src={filepath ? filepath : user1}
                                                alt=""
                                                style={{ width: "8rem", height: "7rem" }}
                                            />
                                        </div>
                                    )}
                                </div>
                                {
                                    !userDetail.avatar ?
                                        <div className="mt-3">
                                            <Button
                                                type="button"
                                                className="btn btn-primary w-md mx-2"
                                                onClick={uploadUserImage}
                                            >
                                                Update Image
                                            </Button>
                                        </div> : ""
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default connect(null, { setBreadcrumbItems })(UserProfile) 
