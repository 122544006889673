import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCustomerByIdAPI, getCustomerOldOrdersAPI, getOrderByIdAPI, getProductByIdAPI } from '../../service/service';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"
import { IconButton, Tab, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
// import * as ReactDOMServer from 'react-dom/server';
// import html2pdf from "html2pdf.js/dist/html2pdf.min";
// import logo from "../../assets/images/shayam-logo.jpg"
import moment from 'moment/moment';


const CustomerDetails = (props) => {
    document.title = "Customer Details | Shayam Global";

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Customer List", link: "/customerList" },
        { title: "Customer Details", link: "#" }
    ]

    const { cid } = useParams()
    const history = useNavigate()
    const [customerDetails, setCustomerDetails] = useState([]);
    const [orderProduct, setOrderProduct] = useState();
    const smallScreen = useMediaQuery("(max-width: 600px)")
    const [value, setValue] = React.useState(0);
    const handleDisplayProduct = async id => {
        try {
            const res = await getCustomerByIdAPI(id);
            setCustomerDetails(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDisplayOrderList = async () => {
        try {
            const res = await getCustomerOldOrdersAPI(cid);
            setOrderProduct(res.data[0].orderDetails)
        } catch (error) {
            console.log(error);
        }
    }

    // const handleGeneratePdf = async (i) => {
    //     const response = await getOrderByIdAPI(i);
    //     setIsRefresh(!isRefresh)
    //     // console.log(response?.data?.resData[0], response?.data?.resData[0]?.CustomerData, response?.data?.resData[0]?.product);
    //     await handleGenerateInvoice(response?.data?.resData[0], response?.data?.resData[0]?.CustomerData, response?.data?.resData[0]?.product);
    // }

    // function generateReceiptNum() {
    //     var date = Date.now();
    //     // let date = (dd + Math.random()).substring(1, 4)
    //     let date22 = Math.floor(Math.random() * date)
    //     let dd = date22.toString()
    //     // console.log(dd)
    //     // console.log(dd.substr(1, 4));
    //     let dateFormate = dd.substr(1, 4)

    //     return { dateFormate }
    // }

    // let date = new Date()

    // const handleGenerateInvoice = async (order, customer, product) => {
    //     let { dateFormate } = generateReceiptNum()
    //     const printElement = (<div id="htmlElementId">
    //         <div className="container pt-5" style={{ padding: "30px" }}>
    //             <div class="row" style={{ padding: "30px" }}>
    //                 <div class="col-lg-12">
    //                     <div class="invoice-title">
    //                         <h1 class="float-end font-size-15">Invoice</h1>
    //                         <div class="mb-4">
    //                             <img alt="Logo" src={logo} style={{ maxHeight: "5rem" }} />
    //                         </div>
    //                     </div>

    //                     <hr class="my-4" />

    //                     <div class="row">
    //                         <div class="col-sm-6">
    //                             <div class="text-muted">
    //                                 <h5 class="font-size-16 mb-3">Billed To:</h5>
    //                                 <h5 class="font-size-15 mb-2">{customer?.fullName}</h5>
    //                                 <p class="mb-1">{customer?.factoryAddress}</p>
    //                             </div>
    //                         </div>
    //                         <div class="col-sm-6">
    //                             <div class="text-muted text-sm-end">
    //                                 <div>
    //                                     <h5 class="font-size-15 mb-1">Invoice No:</h5>
    //                                     <p>Invoice_{dateFormate}</p>
    //                                 </div>
    //                                 <div class="mt-4">
    //                                     <h5 class="font-size-15 mb-1">Invoice Date:</h5>
    //                                     <p>{moment(date).format('DD-MM-YYYY')}</p>
    //                                 </div>
    //                                 <div class="mt-4">
    //                                     <h5 class="font-size-15 mb-1">Order No:</h5>
    //                                     <p>#1123456</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div class="py-2">
    //                         <h5 class="font-size-15">Order Summary</h5>

    //                         <div class="table-responsive">
    //                             <table class="table align-middle table-nowrap table-centered mb-0">
    //                                 <thead>
    //                                     <tr>
    //                                         <th style={{ width: "70px" }}>No.</th>
    //                                         <th>Item</th>
    //                                         <th>Quantity</th>
    //                                         <th>Price</th>
    //                                         <th class="text-end" style={{ width: "120px" }}>Total</th>
    //                                     </tr>
    //                                 </thead>
    //                                 <tbody>
    //                                     {
    //                                         product && product.length > 0 && product.map((ord_item) => {
    //                                             // console.log("ord_item", ord_item);
    //                                             return (
    //                                                 <>
    //                                                     <tr>
    //                                                         <th scope='row'>{srno++}</th>
    //                                                         <td>{ord_item?.productData?.productName}</td>
    //                                                         <td>{ord_item?.qty}</td>
    //                                                         <td>{"₹ " + ord_item?.productData?.price}</td>
    //                                                         <td className="text-end">{"₹ " + ord_item?.productData?.price * ord_item?.qty[0]}</td>
    //                                                     </tr>
    //                                                 </>
    //                                             )
    //                                         })
    //                                     }
    //                                     <tr>
    //                                         <th scope="row" colspan="4" class="text-end">Sub Total</th>
    //                                         <td class="text-end">{"₹ " + order?.orderTotal}</td>
    //                                     </tr>
    //                                     <tr>
    //                                     </tr>
    //                                     <tr>
    //                                         <th scope="row" colspan="4" class="border-0 text-end">Total</th>
    //                                         <td class="border-0 text-end"><h5 class="m-0">{"₹ " + order?.orderTotal}</h5></td>
    //                                     </tr>

    //                                 </tbody>
    //                             </table>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div >)
    //     html2pdf().from(ReactDOMServer.renderToStaticMarkup(printElement)).set({ filename: 'Invoice.pdf' }).save();
    // }

    // let srno = 1;

    const handleViewOrder = async i => {
        console.log(i);
        try {
            history(`/orderDetails/${i}`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        props.setBreadcrumbItems('Customer Details', breadcrumbItems)
    },)

    useEffect(() => {
        if (cid) {
            handleDisplayProduct(cid)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <div className="goback">
                            <Link to="/customerList" className="">
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <Tabs
                        value={value}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                        style={{ borderBottom: "1px solid gray" }}
                        orientation={smallScreen ? "vertical" : "horizontal"}
                        classes={{
                            flexContainer: "flex-wrap sm:flex-nowrap",
                        }}
                        className="tabs"
                    >
                        <Tab className="top-tab" label="Customer Details" style={{ fontWeight: "600" }} />
                        <Tab className="top-tab" label="Customer Order List" style={{ fontWeight: "600" }} onClick={handleDisplayOrderList} />

                    </Tabs>


                    {value === 0 && (
                        <Typography className="tabs-content">
                            <Row className='mt-3'>
                                {/* Customer Details */}
                                {/* <Card className='mt-3'>
                                    <CardBody> */}
                                <Row className="fw-bold view-table" style={{}}>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold ">Name</h6>
                                        <p>{customerDetails.fullName}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold ">Email</h6>
                                        <p>{customerDetails.email}</p>
                                    </Col>

                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">GST No</h6>
                                        <p>{customerDetails.gst}</p>
                                    </Col>

                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Factory Address</h6>
                                        <p>{customerDetails.factoryAddress}</p>
                                    </Col>

                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Contact Person Name</h6>
                                        <p>{customerDetails.contactPersonName}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Contact Person Number</h6>
                                        <p>{customerDetails.contactPersonNumber}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Bank Name</h6>
                                        <p>{customerDetails.bankName}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Bank AC. No</h6>
                                        <p>{customerDetails.bankACNo}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">IFSC Code</h6>
                                        <p>{customerDetails.ifscCode}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Aadhar Card No</h6>
                                        <p>{customerDetails.adharNo}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Pan Card No</h6>
                                        <p>{customerDetails.panNo}</p>
                                    </Col>

                                </Row>

                                <Row className="fw-bold view-table">
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Pan Card Image</h6>
                                        {customerDetails.panPhoto ? (
                                            <ImageViewer
                                                path={customerDetails?.panPhoto}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        ) : (
                                            <ImageViewer
                                                path={dummy}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        )}
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Aadhar Card Front Image</h6>
                                        {customerDetails.adharFrontPhoto ? (
                                            <ImageViewer
                                                path={customerDetails?.adharFrontPhoto}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        ) : (
                                            <ImageViewer
                                                path={dummy}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        )}
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Aadhar Card Back Image</h6>
                                        {customerDetails.adharBackPhoto ? (
                                            <ImageViewer
                                                path={customerDetails?.adharBackPhoto}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        ) : (
                                            <ImageViewer
                                                path={dummy}
                                                style={{ width: "auto", maxHeight: "4rem", cursor: 'pointer' }}
                                            >
                                                {" "}
                                            </ImageViewer>
                                        )}
                                    </Col>



                                </Row>
                                {/* </CardBody>
                                </Card> */}

                            </Row>
                        </Typography>
                    )}

                    {value === 1 && (
                        <Typography className="tabs-content">
                            <Row className='mt-3'>
                                {/* Customer Order Details */}
                                {orderProduct && orderProduct?.length > 0 ?
                                    // <Card className='mt-3'>
                                    //     <CardBody>
                                    <TableContainer>
                                        <Table>
                                            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                                <TableRow className='tableHeadlabel'>
                                                    <TableCell>Order Number</TableCell>
                                                    <TableCell>PO Number</TableCell>
                                                    <TableCell>Order Date</TableCell>
                                                    {/* <TableCell>Product Name</TableCell> */}
                                                    <TableCell>Customer Name</TableCell>
                                                    <TableCell>Order Total</TableCell>
                                                    <TableCell>Order Status</TableCell>
                                                    {/* <TableCell>Invoice</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderProduct && orderProduct?.length > 0 && orderProduct?.map((ord_prod) => (
                                                    // console.log(orderProduct) &&
                                                    <TableRow className="tablerow" >
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}>{ord_prod?.orderNumber}</TableCell>
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}>{ord_prod?.poNumber}</TableCell>
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}>{moment(ord_prod?.order_date).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}> {customerDetails?.fullName}</TableCell>
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}>{"₹ " + Intl.NumberFormat('en-IN').format(ord_prod?.orderTotal)}</TableCell>
                                                        <TableCell onClick={() => handleViewOrder(ord_prod?.orderId)}>{ord_prod?.orderStatus}</TableCell>
                                                        {/* <TableCell>
                                                                <Tooltip title="Download">
                                                                    <IconButton
                                                                        onClick={() => handleGeneratePdf(ord_prod?.order_id)}
                                                                    >
                                                                        <i className="fas fa-file" style={{ fontSize: "large" }}></i>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    //     </CardBody>
                                    // </Card>
                                    :
                                    <h2 className='fw-bold text-center'>No Order Found</h2>
                                }
                            </Row>
                        </Typography>
                    )
                    }
                </Col >
            </Row >
        </div >
    )
}

export default connect(null, { setBreadcrumbItems })(CustomerDetails);  
