import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, Input } from "reactstrap"
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import { getOrderByIdAPI, getOrderListAPI, orderCountAPI, removeOrderShippingReciptAPI, searchOrderAPI, updateOrderStatusByIdAPI, uploadOrderShippingReciptAPI } from '../../service/service'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import moment from 'moment';
import logo from "../../assets/images/shayam-logo.jpg"
import * as ReactDOMServer from 'react-dom/server';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"

const OrderList = (props) => {

  const history = useNavigate();
  const [rowperpage, rowperpagechange] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [isRefreash, setIsRefreash] = useState(false);
  const [order, setOrder] = useState([]);
  const [count, setOrderCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState();

  document.title = "Order List | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "#" },
    { title: "Order List", link: "#" }
  ]

  const [isRefresh, setIsRefresh] = useState(false)

  const inputFile = useRef(null);

  const onButtonClick = (ord_status) => {
    if (ord_status === "Dispatch") {
      inputFile.current.click();
    } else {
      Swal.fire({
        title: "If order dispatch then you can add image!",
        icon: "error"
      })
    }
  };

  const getOrderCount = async () => {
    try {
      const response = await orderCountAPI()
      setOrderCount(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getOrderList = async () => {
    try {
      const response = await getOrderListAPI(rowperpage, pageNo)
      setOrder(response.data)
    } catch (error) {
      console.log(error);
    }
  }
  const handleViewOrder = async i => {
    try {
      history(`/orderDetails/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    // console.log("value",e.target.value);
    let status = e.target.value
    const index = e.target.selectedIndex;
    const el = e.target[index];

    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Note",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to add note!";
        }
      }
    })

    if (reasons) {
      const response = await updateOrderStatusByIdAPI(el.id, status, reasons)
      // console.log(response);
      if (response.status === 1 || response.status === "1") {
        setIsRefreash(!isRefreash)
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    } else if (!reasons) {
      setIsRefreash(!isRefreash)
    }
  }

  const uploadReceipt = async (e, ord_id) => {
    // const { files } = e.target;
    const images = e.target.files[0];
    // console.log("image ---------> ", images);
    // console.log("ord_id ---------> ", ord_id);
    try {
      const res = await uploadOrderShippingReciptAPI(ord_id, images);
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        });
        setIsRefresh(!isRefresh);
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const removeReceipt = async (ord_id, image) => {
    // console.log(ord_id, image);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {

      if (result.isConfirmed) {
        const res = await removeOrderShippingReciptAPI(ord_id, image);
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          });
          setIsRefresh(!isRefresh);
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          });
        }
      }
    })
  }

  const handleChangeFilter = async (e) => {
    setSelectedOption(e.target.value);
    let temp = [];
    const response = await getOrderListAPI(rowperpage, pageNo)
    const orederTemp = response.data;
    orederTemp && orederTemp.length > 0 &&
      orederTemp.map((data) => {
        data.orderData.orderStatus === e.target.value &&
          temp.push(data)
      })
    if (e.target.value === "All Orders") {
      temp = orederTemp
    }
    setOrder(temp)
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchOrderAPI(value)
    setOrder(res.data)
  }


  // const handleGeneratePdf = async (i) => {
  //   const response = await getOrderByIdAPI(i);
  //   setIsRefresh(!isRefresh)
  //   await handleGenerateInvoice(response?.data?.resData[0], response?.data?.resData[0]?.CustomerData, response?.data?.resData[0]?.product);
  // }


  // function generateReceiptNum() {
  //   var date = Date.now();
  //   let date22 = Math.floor(Math.random() * date)
  //   let dd = date22.toString()
  //   let dateFormate = dd.substr(1, 4)

  //   return { dateFormate }
  // }

  // let date = new Date()

  // const handleGenerateInvoice = async (order, customer, product) => {
  //   let { dateFormate } = generateReceiptNum()
  //   const printElement = (<div id="htmlElementId">
  //     <div className="container pt-5" style={{ padding: "30px" }}>
  //       <div class="row" style={{ padding: "30px" }}>
  //         <div class="col-lg-12">
  //           <div class="invoice-title">
  //             <h1 class="float-end font-size-15">Invoice</h1>
  //             <div class="mb-4">
  //               <img alt="Logo" src={logo} style={{ maxHeight: "5rem" }} />
  //             </div>
  //           </div>

  //           <hr class="my-4" />

  //           <div class="row">
  //             <div class="col-sm-6">
  //               <div class="text-muted">
  //                 <h5 class="font-size-16 mb-3">Billed To:</h5>
  //                 <h5 class="font-size-15 mb-2">{customer?.fullName}</h5>
  //                 <p class="mb-1">{customer?.factoryAddress}</p>
  //               </div>
  //             </div>
  //             <div class="col-sm-6">
  //               <div class="text-muted text-sm-end">
  //                 <div>
  //                   <h5 class="font-size-15 mb-1">Invoice No:</h5>
  //                   <p>Invoice_{dateFormate}</p>
  //                 </div>
  //                 <div class="mt-4">
  //                   <h5 class="font-size-15 mb-1">Invoice Date:</h5>
  //                   <p>{moment(date).format('DD-MM-YYYY')}</p>
  //                 </div>
  //                 <div class="mt-4">
  //                   <h5 class="font-size-15 mb-1">Order No:</h5>
  //                   <p>#1123456</p>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <div class="py-2">
  //             <h5 class="font-size-15">Order Summary</h5>

  //             <div class="table-responsive">
  //               <table class="table align-middle table-nowrap table-centered mb-0">
  //                 <thead>
  //                   <tr>
  //                     <th style={{ width: "70px" }}>No.</th>
  //                     <th>Item</th>
  //                     <th>Quantity</th>
  //                     <th>Price</th>
  //                     <th class="text-end" style={{ width: "120px" }}>Total</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {
  //                     product && product.length > 0 && product.map((ord_item) => {
  //                       // console.log("ord_item", ord_item);
  //                       return (
  //                         <>
  //                           <tr>
  //                             <th scope='row'>{srno++}</th>
  //                             <td>{ord_item?.productData?.productName}</td>
  //                             <td>{ord_item?.qty}</td>
  //                             <td>{"₹ " + ord_item?.productData?.price}</td>
  //                             <td className="text-end">{"₹ " + ord_item?.productData?.price * ord_item?.qty[0]}</td>
  //                           </tr>
  //                         </>
  //                       )
  //                     })
  //                   }
  //                   <tr>
  //                     <th scope="row" colspan="4" class="text-end">Sub Total</th>
  //                     <td class="text-end">{"₹ " + order?.orderTotal}</td>
  //                   </tr>

  //                   <tr>
  //                     <th scope="row" colspan="4" class="border-0 text-end">Total</th>
  //                     <td class="border-0 text-end"><h5 class="m-0">{"₹ " + order?.orderTotal}</h5></td>
  //                   </tr>

  //                 </tbody>
  //               </table>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div >)
  //   html2pdf().from(ReactDOMServer.renderToStaticMarkup(printElement)).set({ filename: 'Invoice.pdf' }).save();
  // }

  let srno = 1;

  useEffect(() => {
    async function getCounts() {
      await getOrderCount();
    }
    getCounts();
  }, [])

  useEffect(() => {
    getOrderList();
  }, [isRefreash, rowperpage, pageNo])

  useEffect(() => {
    props.setBreadcrumbItems('Order List', breadcrumbItems)
  },)

  return (
    <>
      <div className="d-flex justify-content-md-between position-relative">
        <div>
          <select onChange={handleChangeFilter} value={selectedOption} className="form-control"
          // style={{ borderRadius: "5px", marginRight:"auto", padding:"5px", width: "143px", height: "33px", color: "Black" }}
          >
            {/* {odr.orderData?.orderStatus} */}
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "All Orders"
                  ? true
                  : false
              }
              value="All Orders"
              style={{ background: "white", color: "black" }}
            >
              All Orders
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "New Order"
                  ? true
                  : false
              }
              value="New Order"
              style={{ background: "white", color: "black" }}
            >
              New Order
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "Pending Order"
                  ? true
                  : false
              }
              value="Pending Order"
              style={{ background: "white", color: "black" }}
            >
              Pending Order
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "Reject"
                  ? true
                  : false
              }
              value="Reject"
            >
              Reject
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "In Process"
                  ? true
                  : false
              }
              value="In Process"
            >
              In Process
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "Completed"
                  ? true
                  : false
              }
              value="Completed"
            >
              Completed
            </option>
            <option
              // id={odr.orderData?.id}
              selected={
                selectedOption === "Dispatch"
                  ? true
                  : false
              }
              value="Dispatch"
            >
              Dispatch
            </option>
          </select>
        </div>

        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className='tableHeadlabel'>
                  <TableCell>Order Number</TableCell>
                  <TableCell>PO Number</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Order Total</TableCell>
                  <TableCell>Order Status</TableCell>
                  <TableCell>Shipping Reciept</TableCell>
                  {/* <TableCell>Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.map((odr, index) => (
                  // console.log(odr.orderData?.orderStatus)
                  <TableRow key={odr._id} className="tablerow" >
                    <TableCell onClick={() => handleViewOrder(odr.orderData?._id)}>{odr?.orderData?.orderNumber}</TableCell>
                    <TableCell onClick={() => handleViewOrder(odr.orderData?._id)}>{odr?.orderData?.poNumber ? odr?.orderData?.poNumber : "-----"}</TableCell>
                    <TableCell onClick={() => handleViewOrder(odr.orderData?._id)}>{moment(odr?.orderData?.orderTime).format('DD-MM-YYYY')}</TableCell>
                    <TableCell onClick={() => handleViewOrder(odr.orderData?._id)}>{odr?.customerData?.fullName}</TableCell>
                    <TableCell onClick={() => handleViewOrder(odr.orderData?._id)}>{"₹ " + Intl.NumberFormat('en-IN').format(odr?.orderTotal)}</TableCell>
                    <TableCell>
                      <select onChange={handleChangeStatus} style={{ borderRadius: "5px", padding: "5px", background: odr.orderData?.orderStatus === "New Order" ? "green" : odr.orderData?.orderStatus === "Reject" ? "red" : odr.orderData?.orderStatus === "Pending Order" ? "#cf393e" : odr.orderData?.orderStatus === "In Process" ? "rgb(175 73 50)" : odr.orderData?.orderStatus === "Dispatch" ? "#398352" : odr.orderData?.orderStatus === "Completed" ? "#4767d0" : "green", color: "white" }}>
                        {odr.orderData?.orderStatus}
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "New Order"
                              ? true
                              : false
                          }
                          value="New Order"
                          style={{ background: "white", color: "black" }}
                        >
                          New Order
                        </option>
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "Pending Order"
                              ? true
                              : false
                          }
                          value="Pending Order"
                          style={{ background: "white", color: "black" }}
                        >
                          Pending Order
                        </option>
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "Reject"
                              ? true
                              : false
                          }
                          value="Reject"
                        >
                          Reject
                        </option>
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "In Process"
                              ? true
                              : false
                          }
                          value="In Process"
                        >
                          In Process
                        </option>
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "Completed"
                              ? true
                              : false
                          }
                          value="Completed"
                        >
                          Completed
                        </option>
                        <option
                          id={odr.orderData?.id}
                          selected={
                            odr.orderData?.orderStatus === "Dispatch"
                              ? true
                              : false
                          }
                          value="Dispatch"
                        >
                          Dispatch
                        </option>
                      </select>
                    </TableCell>
                    <TableCell>{
                      odr.orderData?.orderShippingRecipt ? (<>
                        <ImageViewer path={odr.orderData?.orderShippingRecipt}
                          style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}></ImageViewer><i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={() => removeReceipt(odr.orderData?._id, odr.orderData?.orderShippingRecipt)}
                          ></i>
                      </>)
                        : (<>
                          <div>
                            {/* <input
                              style={{ display: "none" }}
                              // accept=".zip,.rar"
                              ref={inputFile}
                              // onChange={handleFileUpload}
                              type="file"
                            />
                            <div className="button" onClick={onButtonClick}>
                              Upload
                            </div> */}
                            <input
                              style={{ display: "none" }}
                              ref={inputFile}
                              onChange={(e) => uploadReceipt(e, odr.orderData?._id)}
                              type="file"
                              accept='image/*'
                            />
                            <img
                              src={dummy}
                              alt="aadhar-img"
                              style={{ width: "6rem", height: "5rem" }}
                              onClick={() => onButtonClick(odr.orderData?.orderStatus)}
                            />
                          </div>
                        </>)
                    }</TableCell>
                    {/* <TableCell onClick={() => handleViewOrder(odr.orderData?.orderShippingRecipt)}>{odr?.orderData?.orderShippingRecipt}</TableCell> */}
                  </TableRow>

                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination>

          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}
export default connect(null, { setBreadcrumbItems })(OrderList);
