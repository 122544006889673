import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getProductByIdAPI } from '../../service/service';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"
import { maxHeight } from '@mui/system';

const ProductDetails = (props) => {
    document.title = "Product Details | Shayam Global";

    const breadcrumbItems = [
        { title: "Create Category", link: "/dashboard" },
        { title: "Product List", link: "/productList" },
        { title: "Product Details", link: "#" }
    ]
    useEffect(() => {
        props.setBreadcrumbItems('Product Details', breadcrumbItems)
    },)

    const { pid } = useParams()
    const [productDetails, setProductDetails] = useState([]);

    const handleDisplayProduct = async id => {
        try {
            const res = await getProductByIdAPI(id);
            setProductDetails(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (pid) {
            handleDisplayProduct(pid)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <div className="goback">
                            <Link to="/productList" className="">
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <CardTitle className="fs-4">
                                <b>View Product Details</b>
                            </CardTitle>
                            <hr />
                            {/* {console.log(productDetails?.qty[0]?.quantity)} */}
                            <Row className="fw-bold view-table" style={{}}>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Product Category</h6>
                                    <p>{productDetails.productCategory}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Product Sub Category</h6>
                                    <p>{productDetails.subCategory}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Product Name</h6>
                                    <p>{productDetails.productName}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Product Code</h6>
                                    <p>{productDetails.productCode}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Price</h6>
                                    <p>{"₹ " + Intl.NumberFormat('en-IN').format(productDetails.price)}</p>
                                </Col>

                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Minium Order</h6>
                                    <p>{productDetails.minOrder && productDetails.minOrder[0] + " " + productDetails.minOrder[1]}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Quantity</h6>
                                    <p>{productDetails.qty && productDetails.qty[0] + " " + productDetails.qty[1]}</p>
                                </Col>

                                {productDetails.description &&
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Description</h6>
                                        <p>{productDetails.description}</p>
                                    </Col>
                                }
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <h6 className="fw-bold">Product Images</h6>
                                    {productDetails.productImages !== null ? (
                                        productDetails.productImages?.length > 0 && productDetails.productImages.map((item) => {
                                            return (
                                                <ImageViewer
                                                    path={item}
                                                    style={{ width: "5rem", maxHeight: "4rem", cursor: 'pointer', marginRight: "1rem" }}
                                                >
                                                    {" "}
                                                </ImageViewer>
                                            )
                                        })
                                    ) : (
                                        <ImageViewer
                                            path={dummy}
                                            style={{ width: "5rem", maxHeight: "4rem", cursor: 'pointer' }}
                                        >
                                            {" "}
                                        </ImageViewer>
                                    )}

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(ProductDetails);  
