import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import logo from "../../assets/images/shayam-logo.jpg"

const PurchaseOrder = (props) => {
    document.title = "Purchase Order | Shreesh Securities"
    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Purchase Order", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems("Purchase Order", breadcrumbItems)
        //eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="card">

                <div className="card-body">

                    <div className="p-lg-5 p-2 w-100" >

                        <div className="d-flex justify-content-between flex-column flex-sm-row ">
                            <h4 className="fw-bolder" style={{ fontSize: "1.8rem", color: "#252F4A" }}>PURCHASE ORDER</h4>

                            <div className="text-sm-end ">
                                <a href="#" className="d-block mw-150px ms-sm-auto" alt="">
                                    <img alt="Logo" src={logo} style={{ maxHeight: "5rem" }} />
                                </a>
                                <div className="text-sm-end fw-semibold mt-3 fs-5 text-muted">
                                    <p style={{ fontSize: "small" }}>Cecilia Chapman, 711-2880 Nulla St, Mankato</p>
                                    <p style={{ fontSize: "small" }}>Mississippi 96522</p>
                                </div>
                            </div>
                        </div>

                        <div className="pb-3">

                            <div className="d-flex flex-column gap-2 gap-md-5">

                                <div className="fw-bold fs-2">
                                    Dear Mikaela Collins <span className="fs-6">(mik@pex.com)</span>,<br />
                                    <span className="text-muted" style={{ fontSize: "small" }}>Here are your order details. We thank you for your purchase.</span>
                                </div>

                                {/* <div className="separator"></div> */}

                                {/* <div className="row fw-bold">
                                    <div className="col-md-3">
                                        <div className="text-muted">Order ID</div>
                                        <div className="fs-5">#14534</div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="text-muted">Date</div>
                                        <div className="fs-5">07 June, 2024</div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="text-muted">Invoice ID</div>
                                        <div className="fs-5">#INV-000414</div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="text-muted">Shipment ID</div>
                                        <div className="fs-5">#SHP-0025410</div>
                                    </div>
                                </div> */}

                                <div className="row fw-bold">
                                    <div className="col-6">
                                        <div className="text-muted">Billing Address</div>
                                        <div className="fs-6">
                                            Unit 1/23 Hastings Road,<br />
                                            Melbourne 3000,<br />
                                            Victoria,<br />
                                            Australia.
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="text-muted">Shipping Address</div>
                                        <div className="fs-6">
                                            Unit 1/23 Hastings Road,<br />
                                            Melbourne 3000,<br />
                                            Victoria,<br />
                                            Australia.
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between flex-column">

                                    <div className="table-responsive border-bottom mb-9">
                                        <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                            <thead>
                                                <tr className="border-bottom fs-6 fw-bold text-muted">
                                                    <th className="min-w-175px pb-2">Products</th>
                                                    <th className="min-w-70px text-end pb-2"></th>
                                                    <th className="min-w-80px text-end pb-2">QTY</th>
                                                    <th className="min-w-100px text-end pb-2">Total</th>
                                                </tr>
                                            </thead>

                                            <tbody className="fw-semibold text-gray-600">
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">

                                                            <a href="/keen/demo1/apps/ecommerce/catalog/edit-product.html" className="symbol symbol-50px">
                                                                <span className="symbol-label" style={{ backgroundImage: "url(/keen/demo1/assets/media//stock/ecommerce/1.png);" }}></span>
                                                            </a>

                                                            <div className="ms-5">
                                                                <div className="fw-bold">Product 1</div>
                                                                <div className="fs-7 text-muted">Delivery Date: 07/06/2024</div>
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                    </td>
                                                    <td className="text-end">
                                                        2
                                                    </td>
                                                    <td className="text-end">
                                                        $240.00
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">

                                                            <a href="/keen/demo1/apps/ecommerce/catalog/edit-product.html" className="symbol symbol-50px">
                                                                <span className="symbol-label" style={{ backgroundImage: "url(/keen/demo1/assets/media//stock/ecommerce/100.png);" }}></span>
                                                            </a>

                                                            <div className="ms-5">
                                                                <div className="fw-bold">Footwear</div>
                                                                <div className="fs-7 text-muted">Delivery Date: 07/06/2024</div>
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                    </td>
                                                    <td className="text-end">
                                                        1
                                                    </td>
                                                    <td className="text-end">
                                                        $24.00
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="text-end">
                                                        Subtotal
                                                    </td>
                                                    <td className="text-end">
                                                        $264.00
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="text-end">
                                                        VAT (0%)
                                                    </td>
                                                    <td className="text-end">
                                                        $0.00
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="text-end">
                                                        Shipping Rate
                                                    </td>
                                                    <td className="text-end">
                                                        $5.00
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="fs-3 text-gray-900 fw-bold text-end">
                                                        Grand Total
                                                    </td>
                                                    <td className="text-gray-900 fs-3 fw-bolder text-end">
                                                        $269.00
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>



                        <div className="d-flex flex-stack flex-wrap mt-lg-20 pt-13" style={{ justifyContent: "space-between" }}>

                            <div className="">
                                <button type="button" className="btn btn-primary my-1 me-3" onclick="window.print();">Print PO</button>
                                <button type="button" className="btn btn-primary my-1">Download</button>
                            </div>
                            <div>

                                <button type="button" className="btn btn-primary my-1">Create PO</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default connect(null, { setBreadcrumbItems })(PurchaseOrder)  
