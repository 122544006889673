// Authentication
export const authLoginRoute = "Admin/getAuthTokens"
export const changePasswordRoute = "Admin/changePassword"
export const getUserByIdRoute = "Admin/getUserById"
export const uploadUserImageRoute = "Admin/uploadUserImage"
export const removeUserImageRoute = "Admin/removeUserImage"

// Dashboard
export const uploadSliderImageRoute = "Slider/createSlider"
export const getSliderImageRoute = "Slider/getAllImages"
export const deleteSliderImageRoute = "Slider/removeSlideImage"
export const sliderCountRoute = "Slider/getSliderCount"
export const sliderImageStatusRoute = "Slider/updateSliderImageStatus"

// Customer
export const createCustomerRoute = "Customer/createCustomer"
export const updateCustomerByIdRoute = "Customer/updateCustomer"
export const getCustomerListRoute = "Customer/getAllCustomer"
export const getCustomerByIdRoute = "Customer/getCustomerbyId"
export const searchCustomerRoute = "Customer/searchCustomer"
export const customerCountRoute = "Customer/getCustomerCount"
export const uploadAadharFrontImageRoute = "Customer/uploadCustomerAdharFrontImage"
export const uploadAadharBackImageRoute = "Customer/uploadCustomerAdharBackImage"
export const removeAadharFrontImageRoute = "Customer/removeCustomerAdharFrontImage"
export const removeAadharBackImageRoute = "Customer/removeCustomerAdharBackImage"
export const uploadPanImageRoute = "Customer/uploadCustomerPanImage"
export const removePanImageRoute = "Customer/removeCustomerPanImage"
export const deactiveCustomerRoute = "Customer/deActivateCustomerById"
export const activeCustomerRoute = "Customer/activateCustomerById"
export const updateCustomerStatusByIdRoute = "Customer/updateCustomerStatusById"
export const removeCustomerByIdRoute = "Customer/removeCustomer"

// Product
export const getProductListRoute = "Product/getAllProduct"
export const createProductRoute = "Product/createProduct"
export const updateProductRoute = "Product/updateProductById"
export const getProductRoute = "Product/getProductById"
export const removeProductRoute = "Product/removeProduct"
export const searchProductRoute = "Product/searchProduct"
export const productCountRoute = "Product/getProductCount"
export const updateProductIsHomeRoute = "Product/updateProductIsHome"
export const uploadProductImageRoute = "Product/updateProductImages"
export const removeProductImageRoute = "Product/removeProductImage"

// Cart
export const addToCartRoute = "Cart/addToCart"
export const getCartByIdRoute = "Cart/getCustomerCartById"
export const searchCartRoute = "Cart/searchCartProduct"

//Order
export const orderCountRoute = "Order/getOrderCount"
export const getOrderListRoute = "Order/getAllOrders"
export const getOrderByIdRoute = "Order/getOrderById"
export const todayOrderCountRoute = "Order/getTodaysOrder"
export const getTopSellingProductsRoute = "Order/getTopSellingProducts"
export const getCustomerOldOrdersRoute = "Order/getOldOrders"
export const updateOrderStatusByIdRoute = "Order/updateOrderStatus"
export const searchOrderRoute = "Order/searchOrder"
export const removeOrderShippingReciptRoute = "Order/removeOrderShippingRecipt"
export const uploadOrderShippingReciptRoute = "Order/uploadOrderShippingRecipt"

//Category
export const createCategoryRoute = "Category/createCategory"
export const getCategoryListRoute = "Category/getAllCategories"
export const getCategoryByIdRoute = "Category/getCategoryById"
export const uploadCategoryImgRoute = "Category/uploadCategoryImage"
export const removeCategoryImgRoute = "Category/removeCategoryImage"
export const removeCategoryByIdRoute = "Category/removeCategoryById"
export const updateCategoryRoute = "Category/updateCategory"
export const getCategoryCountRoute = "Category/getCategoryCount"
export const searchCategoryRoute = "Category/searchCategory"

export const addProductSubCategoryRoute = "Category/addProductSubCategory"
export const addProductSubSubCategoryRoute = "Category/addProductSubSubCategory"
export const removeProductSubCategoryRoute = "Category/removeProductSubCategory"
export const removeProductSubSubCategoryRoute = "Category/removeProductSubSubCategory"

//Subcategory
export const addSubCategoryRoute = "Category/addSubCategory"
export const removeSubCategoryRoute = "Category/removeSubCategory"
export const updateSubCategoryRoute = "Category/updateSubCategory"
export const uploadSubCategoryImageRoute = "Category/uploadSubCategoryImage"
export const removeSubCategoryImageRoute = "Category/removeSubCategoryImage"
export const getSubCategoryByIdRoute = "Category/getSubCategoryById"

//Sub-Sub-Category
export const addSubSubCategoryRoute = "Category/addSubSubCategory"
export const updateSubSubCategoryRoute = "Category/updateSubSubCategory"
export const uploadSubSubCategoryImageRoute = "Category/uploadSubSubCategoryImage"
export const removeSubSubCategoryImageRoute = "Category/removeSubSubCategoryImage"
export const removeSubSubCategoryRoute = "Category/removeSubSubCategory"
export const getSubSubCategoryByIdRoute = "Category/getSubSubCategoryById"

//Staff
export const createStaffRoute = "Staff/createStaff"
export const updateStaffRoute = "Staff/updateStaff"
export const updateStaffRoleIdRoute = "Staff/updateStaffRoleId"
export const countStaffRoute = "Staff/countStaff"
export const getAllStaffRoute = "Staff/getAllStaff"
export const searchStaffsRoute = "Staff/searchStaffs"
export const removeStaffRoute = "Staff/removeStaff"
export const getStaffByIdRoute = "Staff/getStaffById"
export const uploadStaffImageRoute = "Staff/uploadStaffImage"
export const removeStaffImageRoute = "Staff/removeStaffImage"
export const activeStaffByIdRoute = "Staff/activeStaffById"
export const deactiveStaffByIdRoute = "Staff/deactiveStaffById"

//Role
export const createUserRoleRoute = "Role/createUserRole"
export const updateUserRoleRoute = "Role/updateUserRole"
export const deleteUserRolesRoute = "Role/deleteUserRoles"
export const getUserRoleRoute = "Role/getUserRole"
export const countUserRoleRoute = "Role/countUserRole"
export const getAllUserRolesRoute = "Role/getAllUserRoles"
export const searchUserRoleRoute = "Role/searchUserRole"

//Rights
export const createUserRightRoute = "Right/createUserRight"
export const updateUserRightRoute = "Right/updateUserRight"
export const deleteUserRightsRoute = "Right/deleteUserRights"
export const getUserRightRoute = "Right/getUserRight"
export const countUserRightRoute = "Right/countUserRight"
export const getAllUserRightsRoute = "Right/getAllUserRight"
export const searchUserRightRoute = "Right/searchUserRight"

//Role-to-Right
export const assignRightsToRoleRoute = "RoleToRight/assignRightsToRole"
export const getUserRoleToRightRoute = "RoleToRight/getUserRoleToRight"
export const updateUserRoleIdRoute = "RoleToRight/updateUserRoleId"