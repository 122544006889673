import React, { useEffect, useState } from 'react'
import { Card, CardBody, Input } from "reactstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { getCategoryCountAPI, getCategoryListAPI, removeCategoryByIdAPI, searchCategoryAPI } from '../../service/service'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"

const CategoryList = (props) => {

  const history = useNavigate();

  document.title = "Category List | Shayam Global";

  const breadcrumbItems = [
    { title: "Shayam Global", link: "/" },
    { title: "Category List", link: "#" }
  ]
  const [category, setCategory] = useState([]);

  // const [page, pagechange] = useState(0);
  const [rowperpage, rowperpagechange] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCategoryCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    rowperpagechange(+event.target.value)
    setPageNo(1);
  }

  const getCategoryList = async () => {
    try {
      const response = await getCategoryListAPI(rowperpage, pageNo)
      setCategory(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  const removeCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await removeCategoryByIdAPI(id);
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          setIsRefresh(!isRefresh)
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      }
    })
  }

  const getCategoryCount = async () => {
    try {
      const response = await getCategoryCountAPI()
      setCategoryCount(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdate = id => {
    try {
      history(`/updateCategory/${id}`)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchCategoryAPI(rowperpage, pageNo, value)
    setCategory(res.data)
  }

  const handleSubcategoryList = async (i) => {
    try {
      history(`/subCategoryList/${i}`);
      localStorage.setItem("categoryId", i);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function getCounts() {
      await getCategoryCount();
    }
    getCounts();
  }, [isRefresh])

  useEffect(() => {
    getCategoryList();
  }, [isRefresh, rowperpage, pageNo])

  useEffect(() => {
    props.setBreadcrumbItems('Category List', breadcrumbItems)
  },)

  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow className='tableHeadlabel'>
                  <TableCell>Name</TableCell>
                  <TableCell>Image</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category && category.map((cat, index) => (
                  <TableRow key={cat._id} className="tablerow" >
                    <TableCell onClick={() => handleSubcategoryList(cat._id)}>{cat.categoryName}</TableCell>
                    <TableCell>
                      {
                        cat.categoryImage ? <ImageViewer path={cat.categoryImage}
                          style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}></ImageViewer> : <img
                          src={dummy}
                          alt="aadhar-img"
                          style={{ width: "6rem", height: "5rem" }}
                        />
                      }
                    </TableCell>


                    {/* <TableCell>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        {cat.status ? (
                          <div
                            className="active-btn"
                          // onClick={() => handleDeactiveSlider(slide._id)}
                          >
                            Active
                          </div>
                        ) : (
                          <div
                            className="deactive-btn"
                          // onClick={() => handleActiveSlider(slide._id)}
                          >
                            Inactive
                          </div>
                        )}
                      </div>
                    </TableCell> */}

                    <TableCell>
                      <div style={{ display: 'flex', gap: '5px' }}>

                        <button className="btn btn-primary"
                          onClick={() => handleUpdate(cat._id)}>Edit</button>

                        <button className="btn btn-danger" onClick={() => removeCategory(cat._id)}>Delete</button>
                      </div>
                    </TableCell>
                  </TableRow>

                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              rowsPerPage={rowperpage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination>

          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}


export default connect(null, { setBreadcrumbItems })(CategoryList);