import React, { useEffect, useState } from 'react'
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getStaffByIdAPI } from '../../service/service';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import ImageViewer from '../../imageViewer';
import dummy from "../../assets/images/dummy.jpg"

const StaffDetails = (props) => {
    document.title = "Staff Details | Shayam Global";

    const breadcrumbItems = [
        { title: "Shayam Global", link: "/dashboard" },
        { title: "Staff List", link: "/staffList" },
        { title: "Staff Details", link: "#" }
    ]
    useEffect(() => {
        props.setBreadcrumbItems('Staff Details', breadcrumbItems)
    },)

    const { sid } = useParams()
    const [staffDetails, setStaffDetails] = useState([]);

    const handleDisplayOrder = async id => {
        try {
            const res = await getStaffByIdAPI(id);
            setStaffDetails(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (sid) {
            handleDisplayOrder(sid)
        }
        //eslint-disable-next-line
    }, [])
    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <div className="goback">
                            <Link to="/staffList" className="">
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <CardTitle className="fs-4">
                                <b>View Staff Details</b>
                            </CardTitle>
                            <hr />
                            <Row className="fw-bold view-table" style={{}}>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">First Name</h6>
                                    <p>{staffDetails.firstName}</p>
                                    <p></p>
                                </Col>


                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Last Name</h6>
                                    <p>{staffDetails.lastName}</p>
                                    <p></p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Email</h6>
                                    <p>{staffDetails.email}</p>
                                    <p></p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Mobile</h6>
                                    <p>{staffDetails.mobile}</p>
                                    <p></p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Gender</h6>
                                    <p>{staffDetails.gender ? "Male" : "Female"}</p>
                                    <p></p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Image</h6>
                                    {staffDetails.image ? (
                                        <ImageViewer
                                            path={staffDetails?.image}
                                            style={{ width: "5rem", height: "3.5rem", cursor: 'pointer' }}
                                        >
                                            {" "}
                                        </ImageViewer>
                                    ) : (
                                        <ImageViewer
                                            path={dummy}
                                            style={{ width: "5rem", height: "3.5rem", cursor: 'pointer' }}
                                        >
                                            {" "}
                                        </ImageViewer>
                                    )}
                                </Col>



                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Address</h6>
                                    <p>{staffDetails.address}</p>
                                    <p></p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(StaffDetails); 