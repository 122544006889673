import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import CreateCustomer from "../pages/CustomerManagement/CreateCustomer"
import CustomerList from "../pages/CustomerManagement/CustomerList"
import CreateProduct from "../pages/ProductManagement/CreateProduct"
import AddtoCart from "../pages/CartManagement/AddtoCart"
import SliderGallery from "../pages/SliderManagement/SliderGallery"
import ProductList from "../pages/ProductManagement/ProductList"
import ProductDetails from "../pages/ProductManagement/ProductDetails"
import CartList from "../pages/CartManagement/CartList"
import CreateCategory from "../pages/CategoryManagement/CreateCategory"
import CategoryList from "../pages/CategoryManagement/CategoryList"
import CustomerDetail from "../pages/CustomerManagement/CustomerDetail"
import OrderList from "../pages/OrderManagement/OrderList"
import CreateStaff from "../pages/StaffManagement/CreateStaff"
import StaffList from "../pages/StaffManagement/StaffList"
import Role from "../pages/Settings/Role"
import Rights from "../pages/Settings/Rights"
import RoleToRights from "../pages/Settings/RoleToRights"
import OrderDetails from "../pages/OrderManagement/OrderDetails"
import StaffDetails from "../pages/StaffManagement/StaffDetails"
import Login from "../pages/Authentication/Login"
import ChangePassword from "../pages/Authentication/changePassword"
import Logout from "../pages/Authentication/Logout"
import CompanyProfile from "../pages/Authentication/CompanyProfile"
import SubCategoryList from "../pages/CategoryManagement/SubCategoryList"
import UserProfile from "../pages/Authentication/UserProfile"
import Invoice from "../pages/OrderManagement/Invoice"
import PurchaseOrder from "../pages/OrderManagement/PurchaseOrder"
import CreateSubCategory from "../pages/CategoryManagement/CreateSubCategory"
import InnerSubCategory from "../pages/CategoryManagement/InnerSubCategory"
import InnerSubCategoryList from "../pages/CategoryManagement/InnerSubCategoryList"

const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/changePassword", component: <ChangePassword /> },
  { path: "/companyProfile", component: <CompanyProfile /> },
  { path: "/userProfile", component: <UserProfile /> },

  // Slider
  { path: "/sliderGallery", component: <SliderGallery /> },
  // { path: "/sliderList", component: <SliderList /> },

  //Staff
  { path: "/createStaff", component: <CreateStaff /> },
  { path: "/staffList", component: <StaffList /> },
  { path: "/updateStaff/:sid", component: <CreateStaff /> },
  { path: "/staffDetails/:sid", component: <StaffDetails /> },

  //Settings
  { path: "/role", component: <Role /> },
  { path: "/rights", component: <Rights /> },
  { path: "/roletorights", component: <RoleToRights /> },

  // Customer
  { path: "/createCustomer", component: <CreateCustomer /> },
  { path: "/updateCustomer/:id", component: <CreateCustomer /> },
  { path: "/customerList", component: <CustomerList /> },
  { path: "/customerDetails/:cid", component: <CustomerDetail /> },

  // Category
  { path: "/createCategory", component: <CreateCategory /> },
  { path: "/categoryList", component: <CategoryList /> },
  { path: "/updateCategory/:cid", component: <CreateCategory /> },

  { path: "/createSubCategory", component: <CreateSubCategory /> },
  { path: "/updateSubCategory/:scid", component: <CreateSubCategory /> },
  { path: "/subCategoryList/:sid", component: <SubCategoryList /> },

  { path: "/createInnerCategory", component: <InnerSubCategory /> },
  { path: "/updateInnerCategory/:isid", component: <InnerSubCategory /> },
  { path: "/innerSubCategoryList/:isid", component: <InnerSubCategoryList /> },

  // Product
  { path: "/createProduct", component: <CreateProduct /> },
  { path: "/updateProduct/:pid", component: <CreateProduct /> },
  { path: "/productDetails/:pid", component: <ProductDetails /> },
  { path: "/productList", component: <ProductList /> },

  // Cart
  { path: "/addToCard", component: <AddtoCart /> },
  { path: "/cartList", component: <CartList /> },

  // Order
  { path: "/orderList", component: < OrderList /> },
  { path: "/orderDetails/:oid", component: <OrderDetails /> },
  { path: "/invoice", component: <Invoice /> },
  { path: "/purchaseOrder", component: <PurchaseOrder /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
]

const authRoutes = [
  // Login
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
]

export { userRoutes, authRoutes }