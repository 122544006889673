import { generateImagePath } from "./api/apiClient";
import React, { useState } from "react";
import ImageModal from "./ImageModel";

const ImageViewer = ({ path, style }) => {
    const imagePath = generateImagePath(path);
    const [imageModal, setImageModal] = useState(false);
    const [imageData, setImagedata] = useState([]);
    const getData = (img) => {
        setImagedata(img)
        return setImageModal(true)
    }

    return (
        <React.Fragment>
           
                <img crossOrigin="anonymous" src={imagePath} alt={path} style={style} onClick={() => getData(imagePath)} />
                {
                    imageModal === true ? <ImageModal img={imageData} hide={() => setImageModal(false)} /> : ""
                }
               
           
        </React.Fragment>
    )
}

export default ImageViewer;