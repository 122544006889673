import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Label, Input, Button } from "reactstrap"
import { createCustomerAPI, updateCustomerByIdAPI, getCustomerByIdAPI, uploadPanImageAPI, removePanImageAPI, uploadAadharFrontImageAPI, removeAadharFrontImageAPI, uploadAadharBackImageAPI, removeAadharBackImageAPI } from '../../service/service';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import Swal from "sweetalert2"
import { useNavigate, useParams } from "react-router-dom";
import ImageViewer from "../../imageViewer"
import dummy from "../../assets/images/dummy.jpg"

const CreateCustomer = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  document.title = id ? "Update Customer | Shayam Global" : "Create Customer | Shayam Global"

  const breadcrumbItems = [
    { title: "Shayam Global", link: "/dashboard" },
    { title: id ? "Update Customer" : "Create Customer", link: "#" },
  ]

  const [customerId, setcustomerId] = useState("");
  const [fullName, setFullName] = useState();
  const [gst, setGst] = useState();
  const [email, setEmail] = useState();
  const [factoryAddress, setFactoryAddress] = useState();
  const [bankACNo, setBankACNo] = useState();
  const [adharNo, setAdharNo] = useState();
  const [panNo, setPanNo] = useState();
  const [bankName, setBankName] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [contactPersonName, setContactPersonName] = useState();
  const [contactPersonNumber, setContactPersonNumber] = useState();
  const [updateCustomer, setUpdateCustomer] = useState([]);
  const [adharFront, setAdharFront] = useState("")
  const [adharBack, setAdharBack] = useState("")
  const [filepathFront, setFilepathFront] = useState("")
  const [filepathBack, setFilepathBack] = useState("")
  const [panImage, setPanImage] = useState("")
  const [panFilepath, setPanFilepath] = useState("")
  const [status, setStatus] = useState(0)
  const re = /^[A-Za-z\s]+$/;
  const regex = /^[0-9\b]+$/;

  const handleContactPersonNumber = e => {
    const limit = 10
    // const { value } = e.target;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setContactPersonNumber(e.target.value.slice(0, limit))
    }
  }


  const handleSubmit = async e => {
    if (id || customerId) {
      let fullName = document.getElementById('fullName').value;
      let gst = document.getElementById('gst').value;
      let email = document.getElementById('email').value;
      let factoryAddress = document.getElementById('factoryAddress').value;
      let bankACNo = document.getElementById('bankACNo').value;
      let adharNo = document.getElementById('adharNo').value;
      let panNo = document.getElementById('panNo').value;
      let bankName = document.getElementById('bankName').value;
      let ifscCode = document.getElementById('ifscCode').value;
      let contactPersonName = document.getElementById('contactPersonName').value;
      let contactPersonNumber = document.getElementById('contactPersonNumber').value;

      try {
        const res = await updateCustomerByIdAPI(id ? id : customerId, fullName, email, gst, factoryAddress, bankACNo, adharNo, panNo, bankName, ifscCode, contactPersonName, contactPersonNumber);

        if (res.status === '1' || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          navigate("/customerList");
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
            timer: 1500
          })
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    else {
      try {
        const res = await createCustomerAPI(
          fullName, email, gst, factoryAddress, bankACNo, adharNo, panNo, bankName, ifscCode, contactPersonName, contactPersonNumber
        )
        // await localStorage.setItem("CustomerID", res.data.id);
        if (res.status === '1' || res.status === 1) {
          await uploadAadharFrontImageAPI(res.data.id, adharFront)
          await uploadAadharBackImageAPI(res.data.id, adharBack)
          await uploadPanImageAPI(res.data.id, panImage)
          setStatus(res.status);
          Swal.fire({
            title: res.message,
            icon: "success",
          })
          navigate("/customerList")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })

        }
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDisplayCustomerUpdate = async (id) => {
    try {
      const res = await getCustomerByIdAPI(id);
      console.log(res.data);
      setUpdateCustomer(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleValue = () => {
    if (id || customerId) {
      setFullName(updateCustomer.fullName);
      setEmail(updateCustomer.email);
      setGst(updateCustomer.gst)
      setEmail(updateCustomer.email)
      setFactoryAddress(updateCustomer.factoryAddress)
      setBankACNo(updateCustomer.bankACNo)
      setAdharNo(updateCustomer.adharNo)
      setPanNo(updateCustomer.panNo)
      setBankName(updateCustomer.bankName)
      setIfscCode(updateCustomer.ifscCode)
      setContactPersonName(updateCustomer.contactPersonName)
      setContactPersonNumber(updateCustomer.contactPersonNumber)
      setAdharFront(updateCustomer.adharFrontPhoto)
      setAdharBack(updateCustomer.adharBackPhoto)
      setPanImage(updateCustomer.panPhoto)
    }
  }

  const handleClear = async () => {
    setFullName("");
    setGst("")
    setEmail("")
    setFactoryAddress("")
    setBankACNo("")
    setAdharNo("")
    setPanNo("")
    setBankName("")
    setIfscCode("")
    setContactPersonName("")
    setContactPersonNumber("")
    setcustomerId("")
    setAdharFront("")
    setAdharBack("")
    setFilepathFront("")
    setFilepathBack("")
    setPanImage("")
    setPanFilepath("")
  }

  const handleCancle = async () => {
    navigate("/customerList")
  }

  const uploadFrontAadharimg = async e => {
    e.preventDefault()
    setFilepathFront(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const res = await uploadAadharFrontImageAPI(id ? id : customerId, file)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      setAdharFront(res.data.adharFrontPhoto)
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
      setFilepathFront("")
      setAdharFront("")
      document.getElementById('profileImage').value = null
    }
  }

  const uploadBackAadharimg = async e => {
    e.preventDefault()
    setFilepathBack(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const res = await uploadAadharBackImageAPI(id ? id : customerId, file)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      setAdharBack(res.data.adharBackPhoto)
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
      setFilepathBack("")
      setAdharBack("")
      document.getElementById('profileImage').value = null
    }
  }

  const uploadAadharFrontImage = async (e) => {
    setFilepathFront(URL.createObjectURL(e.target.files[0]))
    setAdharFront(e.target.files[0])
    document.getElementById('aadharFrontImage').value = null
  }

  const uploadAadharBackImage = async (e) => {
    setFilepathBack(URL.createObjectURL(e.target.files[0]))
    setAdharBack(e.target.files[0])
    document.getElementById('aadharBackImage').value = null
  }
  
  const removeAadharFrontImage = async (e) => {
    setFilepathFront("")
    setAdharFront("")
  }
  const removeAadharBackImage = async (e) => {
    setFilepathBack("")
    setAdharBack("")
  }

  const uploadPanImage = async (e) => {
    setPanFilepath(URL.createObjectURL(e.target.files[0]))
    setPanImage(e.target.files[0])
    document.getElementById('panImage').value = null
  }

  const removePanImage = async (e) => {
    setPanFilepath("")
    setPanImage("")
  }

  const uploadPanimg = async e => {
    e.preventDefault()
    setPanFilepath(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]

    const res = await uploadPanImageAPI(id ? id : customerId, file)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      setPanImage(res.data.panPhoto)
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
      setPanFilepath("")
      setPanImage("")
      document.getElementById('profileImage').value = null
    }
  }

  const removeAadharFrontimg = async e => {
    try {
      const res = await removeAadharFrontImageAPI(id ? id : customerId, adharFront)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        setAdharFront("")
        setFilepathFront("")
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeAadharBackimg = async e => {
    try {
      const res = await removeAadharBackImageAPI(id ? id : customerId, adharBack)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        setAdharBack("")
        setFilepathBack("")
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const removePanimg = async e => {
    try {
      const res = await removePanImageAPI(id ? id : customerId, panImage)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        setPanImage("")
        setPanFilepath("")
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    props.setBreadcrumbItems(id ? "Update Customer" : "Create Customer", breadcrumbItems);
  }, [window.location.href])

  useEffect(() => {
    if (id || customerId) {
      handleDisplayCustomerUpdate(id ? id : customerId)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleValue();
    //eslint-disable-next-line
  }, [updateCustomer])

  useEffect(() => {
    handleClear()
  }, [window.location.href])
  
  return (
    <div>

      <div className="d-flex justify-content-end mb-3">
        <Button
          type="button"
          className={id ? "btn btn-primary w-md ms-3" : "d-none"}
          onClick={handleCancle}
        >
          Go Back
        </Button>
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col xl={6}>
              <div className="py-1">
                <Label>Name </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Enter Name"
                    value={fullName}
                    onChange={e => { setFullName(e.target.value) }}
                    required

                  />
                </div>
              </div>

              <div className="py-1">
                <Label>
                  Email
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={e => { setEmail(e.target.value) }}
                    required

                  />
                </div>
              </div>

              <div className="py-1">
                <Label>
                  GST Number
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="gst"
                    name="gst"
                    placeholder="Enter GST Number"
                    value={gst}
                    onChange={e => { setGst(e.target.value) }}
                    required

                  />
                </div>
              </div>

              <div className="py-1">
                <Label>
                  Factory Address
                </Label>

                <textarea
                  className="form-control"
                  name='factoryAddress'
                  placeholder="Enter Address"
                  id="factoryAddress"
                  value={factoryAddress}
                  rows="3"
                  onChange={e => { setFactoryAddress(e.target.value) }}
                  required
                ></textarea>

              </div>

              <Row>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      Contact Person Name
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="text"
                        name="contactPersonName"
                        id="contactPersonName"
                        placeholder="Enter Name"
                        value={contactPersonName}
                        onChange={e => { setContactPersonName(e.target.value) }}
                        required

                      />
                    </div>
                  </div></Col>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      Contact Number
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="number"
                        id="contactPersonNumber"
                        name="contactPersonNumber"
                        placeholder="Enter Contact Number"
                        pattern="^[0-9\b]+$"
                        onChange={handleContactPersonNumber}
                        value={contactPersonNumber}
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="py-1">
                <Label>
                  Bank Account Number
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="number"
                    id="bankACNo"
                    name="bankACNo"
                    value={bankACNo}
                    placeholder="Enter Bank Account Number"
                    onChange={e => { setBankACNo(e.target.value) }}
                    onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                    required
                  />
                </div>
              </div>

              <Row>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      Bank Name
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="text"
                        name="bankName"
                        id="bankName"
                        value={bankName}
                        placeholder="Enter Bank Name"
                        onChange={e => { setBankName(e.target.value) }}
                        required

                      />
                    </div>
                  </div></Col>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      IFSC Code
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="text"
                        id="ifscCode"
                        name="ifscCode"
                        value={ifscCode}
                        placeholder="Enter IFSC Code"
                        onChange={e => { setIfscCode(e.target.value) }}
                        required

                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      Aadhar Number
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="number"
                        name="adharNo"
                        id="adharNo"
                        value={adharNo}
                        placeholder="Enter Aadhar Number"
                        onChange={e => { setAdharNo(e.target.value) }}
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        required

                      />
                    </div>
                  </div></Col>
                <Col md={6}>
                  <div className="py-1">
                    <Label>
                      Pan Number
                    </Label>
                    <div >
                      <Input
                        className="form-control"
                        type="text"
                        id="panNo"
                        name="panNo"
                        value={panNo}
                        placeholder="Enter Pan Number"
                        onChange={e => { setPanNo(e.target.value) }}
                        required

                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {
              !id ?
                <Col xl={6}>
                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Front Aadhar Card
                    </Label>
                    <div className="mt-1 ">
                      <div>
                        <Input
                          type="file"
                          name="aadharFrontImage"
                          id="aadharFrontImage"
                          required
                          accept="image/*"
                          onChange={uploadAadharFrontImage}
                        />
                        <img
                          src={filepathFront ? filepathFront : dummy}
                          alt="aadhar-img"
                          style={{ width: "auto", maxHeight: "7rem" }}
                        />
                        {
                          !adharFront ? "" : <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removeAadharFrontImage}
                          ></i>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Back Aadhar Card
                    </Label>
                    <div className="mt-1 ">
                      <div>
                        <Input
                          type="file"
                          name="aadharBackImage"
                          id="aadharBackImage"
                          required
                          accept="image/*"
                          onChange={uploadAadharBackImage}
                        />
                        <img
                          src={filepathBack ? filepathBack : dummy}
                          alt="aadhar-img"
                          style={{ width: "auto", maxHeight: "7rem" }}
                        />
                        {
                          !adharBack ? "" : <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removeAadharBackImage}
                          ></i>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Pan Card
                    </Label>
                    <div className="mt-1 ">
                      <div>
                        <Input
                          type="file"
                          name="panImage"
                          id="panImage"
                          required
                          accept="image/*"
                          onChange={uploadPanImage}
                        />
                        <img
                          src={panFilepath ? panFilepath : dummy}
                          alt="pan-img"
                          style={{ width: "auto", maxHeight: "7rem" }}
                        />
                        {
                          !panImage ? "" : <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removePanImage}
                          ></i>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                :
                <Col lg={6}>
                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Front Aadhar Card
                    </Label>
                    <div className="mt-1 ">
                      {!adharFront ? (
                        <div>
                          <Input
                            type="file"
                            name="aadharFrontImage"
                            id="aadharFrontImage"
                            required
                            accept="image/*"
                            onChange={uploadFrontAadharimg}
                          />
                          <img
                            src={filepathFront ? filepathFront : dummy}
                            alt="aadhar-img"
                            style={{ width: "auto", maxHeight: "7rem" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <ImageViewer
                            path={adharFront}
                            style={{ width: "auto", maxHeight: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removeAadharFrontimg}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Back Aadhar Card
                    </Label>
                    <div className="mt-1 ">
                      {!adharBack ? (
                        <div>
                          <Input
                            type="file"
                            name="aadharBackImage"
                            id="aadharBackImage"
                            required
                            accept="image/*"
                            onChange={uploadBackAadharimg}
                          />
                          <img
                            src={filepathBack ? filepathBack : dummy}
                            alt="aadhar-img"
                            style={{ width: "auto", maxHeight: "7rem" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <ImageViewer
                            path={adharBack}
                            style={{ width: "auto", maxHeight: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removeAadharBackimg}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-3">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Upload Pan Card
                    </Label>
                    <div className="mt-1 ">
                      {!panImage ? (
                        <div>
                          <Input
                            type="file"
                            name="panImage"
                            id="panImage"
                            required
                            accept="image/*"
                            onChange={uploadPanimg}
                          />
                          <img
                            src={panFilepath ? panFilepath : dummy}
                            alt="pancard-img"
                            style={{ width: "auto", maxHeight: "7rem" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <ImageViewer
                            path={panImage}
                            style={{ width: "auto", maxHeight: "5rem", cursor: 'pointer' }}

                          >
                            {" "}
                          </ImageViewer>
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "8px",
                              color: "red",
                              fontSize: "larger"
                            }}
                            onClick={removePanimg}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
            }

          </Row>
          <div className="mt-5" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-primary w-md"
              onClick={handleSubmit}
            >
              {id ? "Update Customer" : "Create Customer"}
            </button>
            <Button
              type="button"
              className="btn btn-danger w-md ms-3"

              onClick={id ? handleCancle : handleClear}
            >
              {id ? "Cancel" : "Clear"}
            </Button>
          </div>
        </CardBody>
      </Card>

    </div >
  )
}

export default connect(null, { setBreadcrumbItems })(CreateCustomer);