import axios from "axios";

let isLoggedIn = localStorage.getItem("isLoggedIn")
let loginUserId = localStorage.getItem("loginUserId")
let accessToken = localStorage.getItem("accessToken")
let refreshToken = localStorage.getItem("refreshToken")
let username = localStorage.getItem("username")
let expiryTime = localStorage.getItem("expiryTime")
let secretKey = localStorage.getItem("secretKey")

const serverURL = "https://app.ssglobal.co.in:443/"
// const serverURL = "http://localhost:3001/"
// const serverURL = "http://143.110.252.47:3001/"
const baseURL = serverURL + "Development/"
const timeout = 999999

const headers = {
  Authorization: `Bearer ${accessToken}`,
  "Content-MD5": secretKey,
  "Content-Type": "application/json",
  // "Content-Type": "multipart/form-data"
}

const formDataHeaders = {
  Authorization: `Bearer ${accessToken}`,
  "Content-MD5": secretKey,
  "Content-Type": "multipart/form-data",
}

const axiosClient = axios.create({
  baseURL,
  headers,
  timeout
});

const axiosClientFormData = axios.create({
  baseURL,
  formDataHeaders,
  timeout
});

const axiosImageViewerClient = axios.create({ baseURL: serverURL })

const generateImagePath = path => {
  // console.log("path", path)

  if (path && (path.includes("http") || path.includes("https"))) {
    return path
  }
  if (
    [
      "Slider",
      "Adhar",
      "Pan",
      "Product",
      "Category",
      "Staff",
      "User",
      "Order"
    ].includes(path?.split("/")[0])
  ) {
    return `${serverURL}${path}`
  }
  return path
}


let dt = Date.now()
let tokenDT = new Date(expiryTime).getTime()
let expiredInMin = (tokenDT - dt) / 1000 / 60
// console.log("expiredInMin", expiredInMin)

const refreshOldToken = async () => {
  refreshToken = localStorage.getItem("refreshToken")
  username = localStorage.getItem("username")
  const payload = {
    username,
    refreshToken,
  }
  // console.log("payload", payload)
  try {
    const result = await axiosClient.post(
      "Admin/getAccessTokenByRefreshToken",
      payload
    )
    // console.log("result.data", result?.data)

    if (result && result.data) {
      const response = result.data
      if (response.status === "1" || response.status === 1) {
        const data = response.data
        // console.log("updated token details", data)
        // Update the Authorization header with the new token
        localStorage.setItem("isLoggedIn", true)
        localStorage.setItem("accessToken", data.accessToken)
        localStorage.setItem("refreshToken", data.refreshToken)
        localStorage.setItem("expiryTime", data.expiryTime)
        localStorage.setItem("secretKey", data.secretKey)
      }
    }
  } catch (error) {
    // Handle token refresh failure (e.g., redirect to login)
    console.error("Token refresh failed: error", error)
    // Redirect to login or handle in a way that makes sense for your application
  }
}

const isTokenExpiring = async () => {
  try {
    expiryTime = localStorage.getItem("expiryTime")
    dt = Date.now()
    tokenDT = new Date(expiryTime).getTime()
    expiredInMin = (tokenDT - dt) / 1000 / 60
    // console.log("expiredInMin", expiredInMin)
    if (expiredInMin < 5) {
      await refreshOldToken()
    }
  } catch (error) {
    // Handle token refresh failure (e.g., redirect to login)
    console.error("Token refresh failed: error", error)
  }
}

axiosClient.interceptors.request.use(
  async config => {
    accessToken = localStorage.getItem("accessToken")
    secretKey = localStorage.getItem("secretKey")
    if (accessToken && secretKey) {
      config.headers.Authorization = `Bearer ${accessToken}`
      config.headers["Content-MD5"] = secretKey
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const interval = 60000 // 1 minute = 60 seconds = 60000 Milli Seconds
setInterval(async () => {
  await isTokenExpiring()
}, interval)


export { axiosClient, generateImagePath, axiosImageViewerClient, axiosClientFormData };