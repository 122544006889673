import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Label, Input, CardTitle } from "reactstrap"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { createStaffAPI, getAllUserRolesAPI, getStaffByIdAPI, removeStaffImageAPI, updateStaffAPI, uploadStaffImageAPI } from '../../service/service';
import ImageViewer from '../../imageViewer';
import user1 from "../../assets/images/user-1.jpg"
import dummy from "../../assets/images/dummy.jpg"
import Swal from "sweetalert2";

const CreateStaff = (props) => {
    const { sid } = useParams();
    const history = useNavigate();

    const re = /^[A-Za-z\s]+$/;
    document.title = sid ? "Update Staff | Shayam Global" : "Create Staff | Shayam Global";

    const breadcrumbItems = [
        { title: "Shyam Global", link: "/dashboard" },
        { title: sid ? "Update Staff" : "Create Staff", link: "#" }
    ]
    const [isRefresh, setIsRefresh] = useState(false)
    const [username, setUsername] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [gender, setGender] = useState(true)
    const [address, setAddress] = useState("")
    const [password, setpassword] = useState("")
    const [roles, setRoles] = useState([])
    const [userRoleId, setUserRoleId] = useState()

    const [filepath, setFilepath] = useState()
    const [image, setImage] = useState("")

    const [imageId, setImageId] = useState("")

    const [status, setStatus] = useState(true)

    const [isVisible, setVisible] = useState(false)
    const toggle = () => {
        setVisible(!isVisible)
    }

    const handleGender = e => {
        setGender(!gender)
    }

    const handleChange = async e => {
        setImage(e.target.files[0])
        setFilepath(URL.createObjectURL(e.target.files[0]))
        document.getElementById("profileImage").value = null
    }

    const removeProfileImage = async e => {
        setImage("")
        setFilepath("")
    }

    const handleDisplayRole = async () => {
        try {
            const res = await getAllUserRolesAPI(500, 1)
            setRoles(res.data)

        } catch (error) {
            console.log(error)
        }
    }

    const uploadStaffimg = async e => {
        e.preventDefault()
        setFilepath(URL.createObjectURL(e.target.files[0]))
        const file = e.target.files[0]

        const res = await uploadStaffImageAPI(imageId, file)
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
            setImage(res.data.image)
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
            setFilepath("")
            setImage("")
            document.getElementById('profileImage').value = null
        }
    }


    const removeStaffProfile = async e => {
        const staffId = imageId
        const imagePath = image
        try {
            const res = await removeStaffImageAPI(staffId, imagePath)

            if (res.status === "1" || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
                setImage("")
                setFilepath("")
                // clear()
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClear = () => {
        setFirstName("");
        setLastName("");
        setUsername("");
        setpassword("");
        setEmail("");
        setMobile("");
        setAddress("");
        setRoles("");
        setGender(true);
    }

    const handleSetValue = async () => {
        if (sid) {
            try {
                const res = await getStaffByIdAPI(sid)
                const data = res.data
                // console.log(res.data);
                setUsername(data.login.username)
                setFirstName(data.firstName)
                setLastName(data.lastName)
                setEmail(data.email)
                setMobile(data.mobile)
                setAddress(data.address)
                setUserRoleId(data.login.userRoleId);
                setGender(data.gender)
                setImage(data.image)

                setImageId(data.id)
                setStatus(data.status)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleSubmit = async () => {
        if (sid) {
            const firstName = document.getElementById("firstName").value
            const lastName = document.getElementById("lastName").value
            const email = document.getElementById("email").value
            const mobile = document.getElementById("mobile").value
            const address = document.getElementById("address").value
            const username = document.getElementById("userName").value
            const password = document.getElementById("password").value

            try {
                const res = await updateStaffAPI(
                    sid,
                    firstName,
                    lastName,
                    email,
                    mobile,
                    image,
                    gender,
                    address,
                    username,
                    password,
                    userRoleId,
                    // status
                )
                // console.log(res.data);

                if (res.status === "1" || res.status === 1) {

                    history("/staffList")
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
                handleClear()
            } catch (error) {
                console.log(error)
            }
        } else {

            try {
                const res = await createStaffAPI(
                    firstName,
                    lastName,
                    email,
                    mobile,
                    gender,
                    address,
                    username,
                    password,
                    userRoleId,
                )

                if (res.status === "1" || res.status === 1) {
                    await uploadStaffImageAPI(res.data._id, image)
                    history("/staffList")
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
                // handleClear()
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleCancle = async () => {
        history("/staffList")
    }

    useEffect(() => {
        handleDisplayRole();
        //eslint-disable-next-line
    }, [userRoleId])

    useEffect(() => {
        sid && handleSetValue()
        //eslint-disable-next-line
    }, [userRoleId])

    useEffect(() => {
        handleClear()
    }, [window.location.href])

    useEffect(() => {
        props.setBreadcrumbItems(sid ? 'Update Staff' : 'Create Staff', breadcrumbItems)
    },)

    return (
        <>
            <div>
                <Row>
                    <Col >
                        {sid && (
                            <div className="d-flex justify-content-end">
                                <div className="goback">
                                    <Link to="/staffList" className="">
                                        Go Back
                                    </Link>
                                </div>
                            </div>
                        )}
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="6">
                                        <CardTitle>
                                            <b>Staff Personal Details</b>{" "}
                                        </CardTitle>
                                        <div className="py-1">
                                            <Label className="col-form-label">First Name </Label>
                                            <div>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    id="firstName"
                                                    name="firstName"
                                                    placeholder="Enter FirstName"
                                                    onChange={e => {
                                                        const { value } = e.target;
                                                        if (value === "" || re.test(value)) {
                                                            setFirstName(value);
                                                        }
                                                    }}
                                                    value={firstName}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="py-1">
                                            <Label className="col-form-label">
                                                Last name
                                            </Label>
                                            <div>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    id="lastName"
                                                    name="lastName"
                                                    placeholder="Enter Last Name"
                                                    onChange={e => {
                                                        const { value } = e.target;
                                                        if (value === "" || re.test(value)) {
                                                            setLastName(value);
                                                        }
                                                    }}
                                                    value={lastName}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <div className="py-1">
                                                    <Label className="col-form-label">
                                                        Email
                                                    </Label>
                                                    <div >
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Enter Email"
                                                            onChange={e => { setEmail(e.target.value) }}
                                                            value={email}
                                                            required

                                                        />
                                                    </div>
                                                </div></Col>
                                            <Col md={6}>
                                                <div className="py-1">
                                                    <Label className="col-form-label">
                                                        Contact Number
                                                    </Label>
                                                    <div >
                                                        <Input
                                                            className="form-control"
                                                            type="number"
                                                            id="mobile"
                                                            name="mobile"
                                                            placeholder="Enter Contact Number"
                                                            min={10}
                                                            onChange={e => {
                                                                setMobile(e.target.value.slice(0, 10))
                                                            }}
                                                            onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                                            value={mobile}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="py-1">
                                            <Label className="col-form-label">
                                                Address
                                            </Label>

                                            <textarea
                                                className="form-control"
                                                name='address'
                                                placeholder="Enter Address"
                                                id="address"
                                                rows="3"
                                                onChange={e => { setAddress(e.target.value) }}
                                                value={address}
                                                required
                                            ></textarea>

                                        </div>

                                        <div className="py-1">
                                            <Label
                                                htmlFor="example-text-input"
                                                className=" col-form-label"
                                            >
                                                Gender
                                            </Label>
                                            <div>
                                                <Input
                                                    type="radio"
                                                    name="gender"
                                                    id="male"
                                                    checked={gender === true}
                                                    value="true"
                                                    onChange={e => { }}
                                                    onClick={() => handleGender()}
                                                    style={{ transform: "scale(1.3)" }}
                                                />
                                                &nbsp;&nbsp;Male&nbsp;&nbsp;
                                                <Input
                                                    type="radio"
                                                    name="gender"
                                                    id="female"
                                                    checked={gender === false}
                                                    value="false"
                                                    onChange={e => { }}
                                                    onClick={() => handleGender()}
                                                    style={{ transform: "scale(1.3)" }}
                                                />
                                                &nbsp;&nbsp;Female
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <CardTitle>
                                            <b>Staff Login</b>
                                        </CardTitle>
                                        <div className='py-1'>
                                            <Label className=" col-form-label" >
                                                Select User Role*
                                            </Label>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    id="roleName"
                                                    value={userRoleId}
                                                    onChange={e => { setUserRoleId(e.target.value) }}
                                                >
                                                    <option>-- SELECT --</option>
                                                    {roles &&
                                                        roles.length > 0 &&
                                                        roles.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            )
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="py-1">
                                            <Label className=" col-form-label" >
                                                Username
                                            </Label>
                                            <div >
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    name="userName"
                                                    id="userName"
                                                    placeholder="Enter Username"
                                                    onChange={e => { setUsername(e.target.value) }}
                                                    value={username}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="py-1">
                                            <Label className="col-form-label">
                                                Password
                                            </Label>
                                            <div className="d-flex position-relative">
                                                <Input
                                                    className="form-control"
                                                    type={!isVisible ? "password" : "text"}
                                                    id="password"
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    onChange={e => { setpassword(e.target.value) }}
                                                    value={password}
                                                    required
                                                />
                                                <span
                                                    className="icon align-self-center"
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={toggle}
                                                >
                                                    {isVisible ? (
                                                        <i className="fas fa-eye" style={{ color: "gray" }}></i>
                                                    ) : (
                                                        <i
                                                            className="fas fa-eye-slash"
                                                            style={{ color: "gray" }}
                                                        ></i>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            !sid ?
                                                <Col xl={6}>
                                                    <div className="mt-3">
                                                        <Label
                                                            htmlFor="example-text-input"
                                                            className=" col-form-label"
                                                        >
                                                            Staff Profile Photo
                                                        </Label>
                                                        <div className="mt-1 ">
                                                            <div>
                                                                <Input
                                                                    type="file"
                                                                    name="profileImage"
                                                                    id="profileImage"
                                                                    required
                                                                    accept='image/*'
                                                                    onChange={handleChange}
                                                                />
                                                                <img
                                                                    src={filepath ? filepath : user1}
                                                                    alt="staff-profile"
                                                                    style={{ width: "8rem", height: "7rem" }}
                                                                />
                                                                {
                                                                    !image ? "" : <i
                                                                        className="fas fa-trash-alt"
                                                                        style={{
                                                                            position: "absolute",
                                                                            cursor: "pointer",
                                                                            marginLeft: "10px",
                                                                            marginTop: "8px",
                                                                            color: "red",
                                                                            fontSize: "larger"
                                                                        }}
                                                                        onClick={removeProfileImage}
                                                                    ></i>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col> :
                                                <Col xl={6}>
                                                    <div className="mt-3">
                                                        <Label
                                                            htmlFor="example-text-input"
                                                            className=" col-form-label"
                                                        >
                                                            Staff Profile Photo
                                                        </Label>
                                                        <div className="mt-1 ">
                                                            {!image ? (
                                                                <div>
                                                                    <Input
                                                                        type="file"
                                                                        name="profileImage"
                                                                        id="profileImage"
                                                                        required
                                                                        accept='image/*'
                                                                        onChange={uploadStaffimg}
                                                                    />
                                                                    <img
                                                                        src={filepath ? filepath : user1}
                                                                        alt="staff-profile"
                                                                        style={{ width: "8rem", height: "7rem" }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <ImageViewer
                                                                        path={image}
                                                                        style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                                                                    >
                                                                        {" "}
                                                                    </ImageViewer>
                                                                    <i
                                                                        className="fas fa-trash-alt"
                                                                        style={{
                                                                            position: "absolute",
                                                                            cursor: "pointer",
                                                                            marginLeft: "10px",
                                                                            marginTop: "8px",
                                                                            color: "red",
                                                                            fontSize: "larger"
                                                                        }}
                                                                        onClick={removeStaffProfile}
                                                                    ></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                        }




                                        {/* {sid && (
                                                <> */}

                                        {/* <div className="mt-3">
                                            <Label
                                                htmlFor="example-text-input"
                                                className=" col-form-label"
                                            >
                                                Staff Profile Photo
                                            </Label>
                                            <div className="mt-1 ">
                                                {!image ? (
                                                    <div>
                                                        <Input
                                                            type="file"
                                                            name="profileImage"
                                                            id="profileImage"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={filepath ? filepath : user1}
                                                            alt="staff-profile"
                                                            style={{ width: "8rem", height: "7rem" }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <ImageViewer
                                                            path={image}
                                                            style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                                                        >
                                                            {" "}
                                                        </ImageViewer>
                                                        <i
                                                            className="fas fa-trash-alt"
                                                            style={{
                                                                position: "absolute",
                                                                cursor: "pointer",
                                                                marginLeft: "10px",
                                                                marginTop: "8px",
                                                                color: "red",
                                                                fontSize: "larger"
                                                            }}
                                                            onClick={removeStaffProfile}
                                                        ></i>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}

                                        {/* </>
                                            )} */}



                                    </Col>
                                </Row>

                                <div className="mt-4" style={{ textAlign: "center" }}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-md"
                                        onClick={handleSubmit}
                                    >
                                        {sid ? "Update Staff" : "Create Staff"}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-danger w-md ms-3"

                                        onClick={sid ? handleCancle : handleClear}
                                    >
                                        {sid ? "Cancel" : "Clear"}
                                    </button>

                                </div>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div >
        </>
    )
}

export default connect(null, { setBreadcrumbItems })(CreateStaff); 
