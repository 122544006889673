import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "../Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
// import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef()
  const userRights = useSelector(state => state.Profile.userRights)
  const location = useLocation();


  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    // activeMenu()
    // }, [activeMenu])
  }, [])

  function tToggle() {
    var body = document.body;
    // body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className={userRights.Dashboard?.display ? "d-block" : "d-none"}>
              <li>

                <Link to="/dashboard" onClick={() => { tToggle() }} className={`${location.pathname === "/dashboard" ? "mm-active" : ""}`}>
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            </li>
            <li className={userRights.Slider_Management?.display ? "d-block" : "d-none"}>
              <li>

                <Link className="has-arrow waves-effect" >
                  <i className="fas fa-image"></i>
                  <span>{props.t("Slider Management")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sliderGallery" onClick={() => { tToggle() }} className={`${location.pathname === "/sliderGallery" ? "waves-effect mm-active" : ""}`}>{props.t("Slider Gallery")}</Link>
                  </li>
                  {/* <li>
                  <Link to="/sliderList" onClick={() => { tToggle() }} className={`${location.pathname === "/sliderList" ? "waves-effect mm-active" : ""}`}>{props.t("Slider List")}</Link>
                </li> */}

                </ul>
              </li>
            </li>

            {/* <li className={userRights.staff?.display ? "d-block" : "d-none"}> */}
            <li className={userRights.Customer_Management?.display ? "d-block" : "d-none"}>
              <li>

                <Link className="has-arrow waves-effect" >
                  <i className="fas fa-users"></i>
                  <span>{props.t("Customer Management")}</span>
                </Link>
                <ul className="sub-menu">
                  {/* {userRights.staff?.write && ( */}
                  <li>
                    <Link to="/createCustomer" onClick={() => { tToggle() }} className={`${location.pathname === "/createCustomer" ? "waves-effect mm-active" : ""}`}>{props.t("Create Customer")}</Link>
                  </li>
                  {/* )} */}
                  {/* {userRights.staff?.read && ( */}
                  <li>
                    <Link to="/customerList" onClick={() => { tToggle() }} className={`${location.pathname === "/customerList" ? "waves-effect mm-active" : ""}`}>{props.t("Customer List")}</Link>
                  </li>
                  {/* )} */}
                  {/* <li>
                  <Link to="/customerOrderList" onClick={() => { tToggle() }} className={`${location.pathname === "/customerOrderList" ? "waves-effect mm-active" : ""}`}>{props.t("Customer Order List")}</Link>
                  </li> */}
                </ul>
              </li>
            </li>

            <li className={userRights.Category_Management?.display ? "d-block" : "d-none"}>
              {/* <li> */}

              <Link className="has-arrow waves-effect" >
                <i className="mdi mdi-server"></i>
                <span>{props.t("Category Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/createCategory" onClick={() => { tToggle() }} className={`${location.pathname === "/createCategory" ? "waves-effect mm-active" : ""}`}>{props.t("Create Category")}</Link>
                </li>
                <li>
                  <Link to="/categoryList" onClick={() => { tToggle() }} className={`${location.pathname === "/categoryList" ? "waves-effect mm-active" : ""}`}>{props.t("Category List")}</Link>
                </li>
              </ul>
            </li>
            {/* </li> */}

            <li className={userRights.Product_Management?.display ? "d-block" : "d-none"}>
              {/* <li> */}

              <Link className="has-arrow waves-effect" >
                <i className="fas fa-shopping-bag"></i>
                <span>{props.t("Product Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/createProduct" onClick={() => { tToggle() }} className={`${location.pathname === "/createProduct" ? "waves-effect mm-active" : ""}`}>{props.t("Create Product")}</Link>
                </li>
                <li>
                  <Link to="/productList" onClick={() => { tToggle() }} className={`${location.pathname === "/productList" ? "waves-effect mm-active" : ""}`}>{props.t("Product List")}</Link>
                </li>
              </ul>
            </li>
            {/* </li> */}

            <li className={userRights.Order_Management?.display ? "d-block" : "d-none"}>
              {/* <li> */}

              <Link className="has-arrow waves-effect" >
                <i className="fas fa-truck"></i>
                <span>{props.t("Order Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/orderList" onClick={() => { tToggle() }} className={`${location.pathname === "/orderList" ? "waves-effect mm-active" : ""}`}>{props.t("Order List")}</Link>
                </li>
                {/* <li>
                  <Link to="/invoice" onClick={() => { tToggle() }} className={`${location.pathname === "/invoice" ? "waves-effect mm-active" : ""}`}>{props.t("Invoice")}</Link>
                  </li>
                  <li>
                  <Link to="/purchaseOrder" onClick={() => { tToggle() }} className={`${location.pathname === "/purchaseOrder" ? "waves-effect mm-active" : ""}`}>{props.t("Purchase Order")}</Link>
                  </li> */}
              </ul>
            </li>
            {/* </li> */}

            <li className={userRights.Staff_Management?.display ? "d-block" : "d-none"}>
              {/* <li> */}
              <Link className="has-arrow waves-effect" >
                {/* <i className="fas fa-shopping-bag"></i> */}
                <i className="fas fa-address-book"></i>
                <span>{props.t("Staff Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/createStaff" onClick={() => { tToggle() }} className={`${location.pathname === "/createStaff" ? "waves-effect mm-active" : ""}`}>{props.t("Create Staff")}</Link>
                </li>
                <li>
                  <Link to="/staffList" onClick={() => { tToggle() }} className={`${location.pathname === "/staffList" ? "waves-effect mm-active" : ""}`}>{props.t("Staff List")}</Link>
                </li>

              </ul>
            </li>
            {/* </li> */}

            <li className={userRights.Role_To_Right?.display ? "d-block" : "d-none"}>
            <li>
              <Link className="has-arrow waves-effect" >
                <i className="fas fa-cogs "></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/role" onClick={() => { tToggle() }} className={`${location.pathname === "/role" ? "waves-effect mm-active" : ""}`}>{props.t("Role")}</Link>
                </li>
                <li>
                  <Link to="/rights" onClick={() => { tToggle() }} className={`${location.pathname === "/rights" ? "waves-effect mm-active" : ""}`}>{props.t("Rights")}</Link>
                </li>
                <li>
                  <Link to="/roletorights" onClick={() => { tToggle() }} className={`${location.pathname === "/roletorights" ? "waves-effect mm-active" : ""}`}>{props.t("Assign Rights to Role")}</Link>
                </li>
              </ul>
            </li>
            </li>

          </ul>

        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
