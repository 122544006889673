import React, { useEffect, useState } from 'react'
import { Table, Card, CardBody, CardTitle } from "reactstrap"
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getOrderListAPI } from '../../service/service';
import moment from 'moment';

const PendingOrderList = () => {

    const [order, setOrder] = useState([]);

    const getCustomerList = async () => {
        let temp = []
        try {
            const response = await getOrderListAPI(5, 1)
            if (response.status === "1" || response.status === 1) {
                response?.data && response?.data.map(data => {
                    let status = data.orderData.orderStatus;
                    if (status === "Pending Order") {
                        temp.push(data)
                    }
                })
                setOrder(temp)
            }
            // setOrder(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCustomerList();
    }, [])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/* <h4 className="card-title mb-4"></h4> */}
                    <CardTitle className="text-center fs-4">
                        <b>Pending Order List</b>{" "}
                    </CardTitle>
                    <hr />

                    <TableContainer>
                        <Table>
                            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                                <TableRow className="fw-bold text-black">
                                    <TableCell>Order Number</TableCell>
                                    <TableCell>PO Number</TableCell>
                                    <TableCell>Order Date</TableCell>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell>Order Total</TableCell>
                                    <TableCell>Order Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order && order.map((order, index) => (

                                    <TableRow key={order._id} className="tablerow text-black" >
                                        <TableCell >{order?.orderData?.orderNumber}</TableCell>
                                        <TableCell >{order?.orderData?.poNumber}</TableCell>
                                        <TableCell >{moment(order?.orderData?.orderTime).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell >{order?.customerData?.fullName}</TableCell>
                                        <TableCell >{order?.orderData?.orders[0]?.qty[0] + " " + order?.orderData?.orders[0]?.qty[1]}</TableCell>
                                        <TableCell >{order?.orderData?.orderStatus}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default PendingOrderList