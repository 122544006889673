import { DISPLAY_RIGHTS, DISPLAY_ROLE, DISPLAY_ROLE_TO_RIGHTS, UPDATE_ROLE_TO_RIGHTS } from "./actionTypes";

export const displayRole = (roles, history) => {
    return {
        type: DISPLAY_ROLE,
        payload: { roles, history },
    };
};

export const displayRights = (roles, history) => {
    return {
        type: DISPLAY_RIGHTS,
        payload: { roles, history },
    };
};

// --------------- ROLE TO RIGHT---------------
export const displayRoleToRights = (userRoletoRight, history) => {
    return {
        type: DISPLAY_ROLE_TO_RIGHTS,
        payload: { userRoletoRight, history },
    };
};

export const updateRoleToRights = (updateRoletoRight, history) => {
    return {
        type: UPDATE_ROLE_TO_RIGHTS,
        payload: { updateRoletoRight, history },
    };
};

